import React, { useContext, useEffect, useState } from "react";
import AWARE_ICON from "assets/icons/AWAREICON.png";
import AWARE_NODE from "assets/aware.png";
import RESCALERT_DEVICE from "assets/Sidebar_Logo_Closed.png";
import { ColorContext } from "App";
import { FaChevronDown, FaChevronUp, FaRoad } from "react-icons/fa";
import { IntegrationItem } from "pages/System/IntegrationItem";
import { deviceImageList } from "utils/iconUtils";

const CurrentIntegrations = ({
  data,
  setInitialData,
  setShowNewConnection,
  setShowNewDevice,
  devices,
}) => {
  const deviceImages = deviceImageList;

  const [deviceCount, setDeviceCount] = useState(0);

  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 57px)",
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 24 }}>Connected Systems</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr .5fr .5fr",

          marginTop: 20,
          color: theme.fontColor,
          fontWeight: "bold",
          borderBottom: "1px solid grey",
          padding: 10,
        }}
      >
        <div>System</div>
        <div>Type</div>
        <div>License Info</div>
        <div>Status</div>
        <div>Date Integrated</div>
        <div></div>
      </div>
      {/* TOP */}
      <div
        style={{
          overflow: "auto",
        }}
      >
        {data &&
          data.length > 0 &&
          data.map((integration, index) => (
            <IntegrationItem
              key={index}
              integration={integration}
              setInitialData={setInitialData}
              setShowNewConnection={setShowNewConnection}
              deviceImages={deviceImages}
              theme={theme}
              index={index}
              devices={devices}
              setShowNewDevice={setShowNewDevice}
              deviceCount={deviceCount}
              setDeviceCount={setDeviceCount}
            />
          ))}
      </div>
    </div>
  );
};

export default CurrentIntegrations;
