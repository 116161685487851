export const getVisibleUsers = (perms) => {
  if (!perms) {
    return [10];
  }
  if (perms.accessLevel === 8) {
    return [8, 10, 12, 13];
  }
  if (perms.accessLevel === 12) {
    return [10, 12];
  } else if (perms.accessLevel === 13) {
    return [10, 12, 13];
  } else {
    return [10];
  }
}