import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import DropDownSpread from "components/DropDownSpread";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import { FaEye } from "react-icons/fa";
import ConditionIcons from "components/ConditionIcons";
import { RiDropFill, RiDropLine, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney, FaTemperatureHalf } from "react-icons/fa6";
import { WiSprinkle } from "react-icons/wi";
import { BsArrowDown, BsArrowUp, BsClock, BsCompass } from "react-icons/bs";
import moment from "moment";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";

let dataCache;
 
export default function BuoyConditionsWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const theme = useContext(ColorContext);
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getBuoyConditions";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          

          if (res?.data?.result) dataCache = [...res.data.result];
          else dataCache = [];

          setData(dataCache);
           
          setFetching(false);
          setError({ state: false, message: "" });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);
  useEffect(() => {
    if (dataCache) {
      setData(dataCache);
      setFetching(false);
      setError({ state: false, message: "" });
      return;
    }
    FusionData.get(ROUTE)
      .then((res) => {
        

        if (res?.data?.result) dataCache = [...res.data.result];
        else dataCache = [];

        setData(dataCache);
         
        setFetching(false);
        setError({ state: false, message: "" });
      })
      .catch((err) => {
        
        setError({ state: true, message: err.message });
      });
  }, [widgetRefresh]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }
  
  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"BuoyCondition"}
        dimensions={dimensions}
      />
    );
  if (data && data.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}  />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate
            route={ROUTE}
             
          />
          <div
            className="scrollbar-hidden"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              // height: "90%",
              // height: 400,
              height: "calc(100% - 30px)",
              overflow: "auto",
            }}
          >
            {data.length == 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // alignItem: "left",
                  // justifyContent: "left",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                No Data for this Location
              </div>
            )}
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <DropDownSpread
                    key={index}
                    style={{
                      backgroundColor: theme.primary,
                    }}
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>{item.buoy_name}</div>
                        <div>{moment(item.utcTime).format("MM/DD/YYYY")}</div>
                      </div>
                    }
                    data={{
                      "Wind SP": `${item.wind_speed} MPH`,
                      "Dew Point": `${item.dew_point}° F`,
                      "Wave Period": item.wave_period,
                      "Wave Height": item.wave_height,
                      "Water Temp": `${item.water_temperature}° F`,
                      "Barometric pressre": `${item.baro_pressure} in`,
                      "Air Temp": `${item.air_temperature}° F`,
                      "Wind Direction": item.wind_short,
                      Visibility: `${item.visibility} mi`,
                    }}
                    dataIcons={[
                      <RiWindyFill />,
                      <WiSprinkle />,
                      <BsClock />,
                      <BsArrowUp />,
                      <RiDropLine />,
                      <BsArrowDown />,
                      <FaTemperatureHalf />,
                      <BsCompass />,
                      <FaEye />,
                    ]}
                  />
                );
              })}
          </div>
        </div>
      );
  }
  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate
            route={ROUTE}
             
          />
          <div
            className="scrollbar-hidden"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              // height: "90%",
              // height: 400,
              height: "calc(100% - 30px)",
              overflow: "auto",
            }}
          >
            {data.length == 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // alignItem: "left",
                  // justifyContent: "left",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                No Data for this Location
              </div>
            )}
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <DropDownSpread
                    key={index}
                    style={{
                      backgroundColor: theme.primary,
                    }}
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>{item.buoy_name}</div>
                        <div>{moment(item.utcTime).format("MM/DD/YYYY")}</div>
                      </div>
                    }
                    data={{
                      "Wind SP": `${item.wind_speed} MPH`,
                      "Dew Point": `${item.dew_point}° F`,
                      "Wave Period": item.wave_period,
                      "Wave Height": item.wave_height,
                      "Water Temp": `${item.water_temperature}° F`,
                      "Barometric pressre": `${item.baro_pressure} in`,
                      "Air Temp": `${item.air_temperature}° F`,
                      "Wind Direction": item.wind_short,
                      Visibility: `${item.visibility} mi`,
                    }}
                    dataIcons={[
                      <RiWindyFill />,
                      <WiSprinkle />,
                      <BsClock />,
                      <BsArrowUp />,
                      <RiDropLine />,
                      <BsArrowDown />,
                      <FaTemperatureHalf />,
                      <BsCompass />,
                      <FaEye />,
                    ]}
                  />
                );
              })}
          </div>
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate
            route={ROUTE}
             
          />
          <div
            className="scrollbar-hidden"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              // height: "90%",
              height: "calc(100% - 30px)",
              overflow: "auto",
            }}
          >
            {data.length == 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // alignItem: "left",
                  // justifyContent: "left",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                No Data for this Location
              </div>
            )}
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <DropDownSpread
                    key={index}
                    style={{
                      backgroundColor: theme.primary,
                    }}
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>{item.buoy_name}</div>
                        <div>{moment(item.utcTime).format("MM/DD/YYYY")}</div>
                      </div>
                    }
                    data={{
                      "Wind SP": `${item.wind_speed} MPH`,
                      "Dew Point": `${item.dew_point}° F`,
                      "Wave Period": item.wave_period,
                      "Wave Height": item.wave_height,
                      "Water Temp": `${item.water_temperature}° F`,
                      "Barometric pressre": `${item.baro_pressure} in`,
                      "Air Temp": `${item.air_temperature}° F`,
                      "Wind Direction": item.wind_short,
                      Visibility: `${item.visibility} mi`,
                    }}
                    dataIcons={[
                      <RiWindyFill />,
                      <WiSprinkle />,
                      <BsClock />,
                      <BsArrowUp />,
                      <RiDropLine />,
                      <BsArrowDown />,
                      <FaTemperatureHalf />,
                      <BsCompass />,
                      <FaEye />,
                    ]}
                  />
                );
              })}
          </div>
        </div>
      );
  }
}
