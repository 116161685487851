import { borders } from '@mui/system'
import { ColorContext } from 'App'
import React, { useContext, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export const CustomInput = ({
  callback, 
  placeholder, 
  value,
  borderDesign = 'linear-gradient(160deg, rgba(103,95,163,1) 10%, rgba(39,49,58,1) 40%, rgba(19,30,43,1) 50%,rgba(39,49,58,1) 60%, rgba(103,95,163,1) 90%',
  height = 50,
  width = 350,
  borderSize = 2,
  borderRadius = 20,
  inputBackground = '#111D2B',
  type = 'text',
  textarea = false,
  password = false,
  maxLength = 1000,
  readonly = false
}) => {

  // const [readOnlyState, setReadOnlyState] = useState(readonly)
  const [reveal, setReveal] = useState(false)
  
  const theme = useContext(ColorContext);

  if (textarea) return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: width,
        height: height,
        borderRadius: 20,
        background: borderDesign,
      }}
    >
      <div
        style={{
          display:'flex',
          height: height - borderSize * 2,
          width: width - borderSize * 2,
          marginLeft: borderSize,
          borderRadius: borderRadius,
          background: inputBackground,
          overflow: 'hidden',
        }}>

      <textarea
        style={{
          value: value,
          padding: 15, 
          width: "100%", 
          height: height, 
          resize:'none',
        }}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={(e) => callback(e.target.value)}
      />
      </div>
    </div>
  )

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: height,
        width: width,
        background: borderDesign,
        borderRadius: borderRadius,
      }}
    >
      <div
        style={{
          display:'flex',
          alignItems: "center",
          height: height - borderSize * 2,
          width: width - borderSize * 2,
          marginLeft: borderSize,
          borderRadius: borderRadius,
          background: inputBackground,
        }}>
        <input
          // readOnly={readOnlyState}
          // onFocus={ () => setReadOnlyState(false) }
          // onBlur={ () => setReadOnlyState(true) }
          value={value}
          style={{paddingLeft: 15, width: "100%"}}

          type={type === "password" ? (reveal ? "text" : "password") : type}

          placeholder={placeholder}
          onChange={(e) => callback(e.target.value)}
        />
        {type === "password" && <div onClick={() => setReveal(!reveal)} style={{color: theme.fontColor, cursor: 'pointer', marginTop: 2, marginRight: 15}}>
      {reveal ? <FaEyeSlash size={20}/> : <FaEye size={20}/>}</div>}
      </div>
    </div>
  )
}
