import CurrentGlobalActions from "components/Connections/CurrentGlobalActions";
import CurrentIntegrations from "components/Connections/CurrentIntegrations";
import IntegrationUsage from "components/Connections/IntegrationUsage";
import NewConnection from "components/Connections/NewConnection";
import NewGlobalAction from "components/Connections/NewGlobalAction";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as api from "apis/FusionAPI";
import { GlobalActionsBuilder } from "./GlobalActionsBuilder";
import Cookies from "js-cookie";
import { usePerms } from "hooks/usePerms";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext } from "App";
// import { perms.testPermission } from "data/routeData";

export default function GlobalActions() {
  const [globalActions, setGlobalActions] = useState([]);
  const [showNewGlobalAction, setShowNewGlobalAction] = useState(false);
  const [deviceData, setDeviceData] = useState([]);

  const [initialData, setInitialData] = useState();
  const perms = usePerms();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    // fetch integrations
    (async () => {
      if (!(await perms.validatePermissions(["ViewGlobalActions"]))) {
        navigate("/404");
        return;
      }

      api.getGlobalActions().then((res) => {
        setGlobalActions(res.data.result);
        setFetching(false);
      });
    })();
  }, []);
  const theme = useContext(ColorContext);
  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      {!showNewGlobalAction &&
        perms.testPermission([8, 10, 11, 12, 13], ["ViewGlobalActions"]) && (
          <div style={{ display: "flex", height: "calc(100% - 80px)" }}>
            <CurrentGlobalActions
              data={globalActions}
              setData={setGlobalActions}
              setInitialData={setInitialData}
              setShowNewGlobalAction={setShowNewGlobalAction}
            />
          </div>
        )}

      {!showNewGlobalAction &&
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["Create-EditGlobalActions"]
        ) && (
          <div
            style={{ height: 70, display: "flex", justifyContent: "flex-end" }}
          >
            <button
              style={{
                marginTop: 20,
                cursor: "pointer",
                fontWeight: "bold",
                backgroundColor: theme.primaryHighlight,
                width: "fit-content",
                padding: "10px 20px",
                borderRadius: 10,
              }}
              onClick={() => {
                setShowNewGlobalAction(true);
              }}
            >
              Create Global Action
            </button>
          </div>
        )}

      <div>
        {showNewGlobalAction &&
          perms.testPermission(
            [8, 10, 11, 12, 13],
            ["Create-EditGlobalActions"]
          ) && (
            <GlobalActionsBuilder
              setShowNewGlobalAction={setShowNewGlobalAction}
              setGlobalActions={setGlobalActions}
              deviceData={deviceData}
              initialData={initialData}
              setInitialData={setInitialData}
              setDeviceData={setDeviceData}
            />
          )}
      </div>
    </div>
  );
}
