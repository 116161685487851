const routeOffsetMinutes = {
  get15DayForecast: 40,
  getAirQualityObservation: 15,
  getAirQualityForecast: 15,
  getAllActiveCWAlerts: 3, // we can get rid of this one whenever, no longer in use
  getCWActiveAlerts: 3,
  getAllActiveAlerts: 3,
  getDetailedMarine7: 10,
  getBuoyConditions: 5,
  getTideTable: 60,
  getEarthQuake: 3,
  getHurricanesLive: 3,
  combinedCharts: 120,
  getExtendedFireDanger: 15,
  getDetailedForecastData: 45,
  getAirportConditionsbyState: 10,
  getHourlyForecast: 60,
  getAllActiveAlerts: 3,
  getHourlyForecast: 60,
};

export default routeOffsetMinutes;
