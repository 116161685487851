import React, { useState, useEffect, useContext } from "react";
import Popup from "reactjs-popup";

import useDebounce from "hooks/useDebounce";
import { stringIsCoordinates } from "utils/geometryUtils";
import { FaChevronDown } from "react-icons/fa";
import { ColorContext } from "App";

const LocationSearchInput = ({
  searchLocation,
  styles,
  alertData,
  handleChange,
  searchValid,
  userLocation,
  setMapCenter,
  searchAutoFill,
  autoCompleteData,
  setAutoCompleteData,
  setAlertData,
  query,
  setQuery,
  widget,
}) => {
  const [clickedLocation, setClickedLocation] = useState("");

  const theme = useContext(ColorContext);
  const debouncedSearchTerm = useDebounce(query, 1000);

  const [autoCompleteNames, setAutoCompleteNames] = useState([]);

  useEffect(() => {
    if (autoCompleteData.length > 0) {
      setAutoCompleteNames(
        autoCompleteData.map((location) => location.description)
      );
    }
  }, [autoCompleteData]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        
        searchAutoFill(debouncedSearchTerm);
        // searchLocation(debouncedSearchTerm);
      })();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm === "") {
      setAutoCompleteData([]);
      setAutoCompleteNames([]);
    }
    if (stringIsCoordinates(debouncedSearchTerm)) {
      setAutoCompleteData([]);
      setAutoCompleteNames([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchAutoFill(query);
        }}
        style={{
          ...styles.input,
          padding: "0",
          margin: "0",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: widget ? "transparent" : theme.primary,
        }}
      >
        <input
          list="autoCompleteNames"
          style={{
            backgroundColor: widget ? theme.primary : theme.base,
            borderRadius: "5px",
            // borderTopRightRadius: widget ? "0px" : "5px",
            // borderBottomRightRadius: widget ? "0px" : "5px",
            padding: "5px",
            width: widget ? "50%" : "100%",
          }}
          name="searchLocation"
          value={clickedLocation ? clickedLocation : query}
          autoComplete="off"
          onChange={(e) => {
            const inputValue = e.target.value;
            const autoCompleteOption = autoCompleteData.find(
              (location) => location.description === inputValue
            );

            if (clickedLocation) {
              setClickedLocation("");
            }

            // If the input value matches an autocomplete option, don't call setQuery
            if (!autoCompleteOption) {
              setQuery(inputValue);
            }

            setAlertData({ ...alertData, searchLocation: autoCompleteOption });
            setClickedLocation(inputValue);
          }}
          placeholder="Search Location"
          // onSubmit={searchLocation}
        />
        <datalist id="autoCompleteNames">
          {autoCompleteNames.map((name, index) => {
            return <option key={index} value={name} />;
          })}
        </datalist>
        {!widget && (
          <button style={{ ...styles.button, backgroundColor: "grey" }}>
            Search
          </button>
        )}
      </form>
    </>
  );
};

export default LocationSearchInput;
