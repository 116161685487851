import { index } from "d3";
import React, { useContext } from "react";
import { IconContext } from "react-icons";

import { ColorContext, LayoutSizeContext } from "App";

export default function ForecastSpread({
  style,
  header,
  data,
  icon,
  gap,
  dataIcons,
  headerStyle,
}) {
  const layout = useContext(LayoutSizeContext);
  
  const theme = useContext(ColorContext);

  const columns = Math.ceil(Math.sqrt(Object.keys(data).length));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "solid grey 2px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 20,
            width: "100%",
            ...headerStyle,
          }}
        >
          {header}
        </div>
        {icon && (
          <IconContext.Provider value={{ color: theme.fontColor, size: 25 }}>
            <div>{icon}</div>
          </IconContext.Provider>
        )}
      </div>

      {data && (
        <div
          style={{
            textAlign: "left",
            overflow: "auto",
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: "10px",
            height: "100%",
          }}
        >
          {Object.keys(data).map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  fontSize: layout.baseFontSize - 5,
                }}
              >
                {dataIcons && dataIcons[index]}{" "}
                {item !== "" ? `${item}: ${data[item]}` : `${data[item]}`}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
