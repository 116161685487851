import React from 'react'

export const ErrorMessage = ({message}) => {
  return (
    <div>
      <div style={{padding: 15, color:'#bb2222'}}>
        Error fetching data, contact your administrator: <div> {"<"}{message}{">"}</div>
      </div>
    </div>
  )
}
