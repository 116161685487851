export function DateToString(date) {
  const month = date.getMonth();
  const day = date.getDate();
  const weekday = date.getDay();
  const year = date.getFullYear();

  let monthStr = "";
  let monthShortStr = "";

  switch (month) {
    case 0:
      monthStr = "January";
      monthShortStr = "Jan";
      break;
    case 1:
      monthStr = "Febuary";
      monthShortStr = "Feb";
      break;
    case 2:
      monthStr = "March";
      monthShortStr = "Mar";
      break;
    case 3:
      monthStr = "April";
      monthShortStr = "Apr";
      break;
    case 4:
      monthStr = "May";
      monthShortStr = "May";
      break;
    case 5:
      monthStr = "June";
      monthShortStr = "Jun";
      break;
    case 6:
      monthStr = "July";
      monthShortStr = "Jul";
      break;
    case 7:
      monthStr = "August";
      monthShortStr = "Aug";
      break;
    case 8:
      monthStr = "September";
      monthShortStr = "Sep";
      break;
    case 9:
      monthStr = "October";
      monthShortStr = "Oct";
      break;
    case 10:
      monthStr = "November";
      monthShortStr = "Nov";
      break;
    case 11:
      monthStr = "December";
      monthShortStr = "Dec";
      break;
    default:
      monthStr = "?";
      break;
  }

  let dayStr = `${day}`;

  if (day >= 11 && day <= 13) {
    dayStr += "th";
  } else if (day % 10 === 1) {
    dayStr += "st";
  } else if (day % 10 === 2) {
    dayStr += "nd";
  } else if (day % 10 === 3) {
    dayStr += "rd";
  } else {
    dayStr += "th";
  }

  let weekdayStr_short = "";
  let weekdayStr_long = "";

  switch (weekday) {
    case 0:
      weekdayStr_short = "Sun";
      weekdayStr_long = "Sunday";
      break;
    case 1:
      weekdayStr_short = "Mon";
      weekdayStr_long = "Monday";
      break;
    case 2:
      weekdayStr_short = "Tue";
      weekdayStr_long = "Tuesday";
      break;
    case 3:
      weekdayStr_short = "Wed";
      weekdayStr_long = "Wednesday";
      break;
    case 4:
      weekdayStr_short = "Thur";
      weekdayStr_long = "Thursday";
      break;
    case 5:
      weekdayStr_short = "Fri";
      weekdayStr_long = "Friday";
      break;
    case 6:
      weekdayStr_short = "Sat";
      weekdayStr_long = "Saturday";
      break;
    default:
      weekdayStr_short = "?";
      weekdayStr_long = "?";
      break;
  }

  let yearStr = `${year}`;

  return {
    month: monthStr,
    month_short: monthShortStr,
    day: dayStr,
    day_short: day,
    weekday_short: weekdayStr_short,
    weekday_long: weekdayStr_long,
    year: yearStr,
  };
}

export function GetDeviceTypeString(device) {
  return device?.system_type + " " + device?.device_type || "";
}

export function capitalizeWord(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function splitByCharacter(string, character) {
  return string.split(character);
}

export function TimeSinceDateTimeString(dateTime) {
  const time = dateTime.getTime();
  const now = new Date().getTime();
  const diff = now - time;
  const diffTime = {
    seconds: Math.floor(diff / 1000) % 60,
    minutes: Math.floor(diff / (1000 * 60)) % 60,
    hours: Math.floor(diff / (1000 * 60 * 60)) % 24,
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
  };

  let str = "";
  let type = "seconds";

  if (diffTime.days > 0) {
    str += diffTime.days + " days ";
  }
  if (diffTime.hours > 0 || type !== "seconds") {
    str +=
      diffTime.hours < 10 ? "0" + diffTime.hours + ":" : diffTime.hours + ":";
    if (type === "seconds") type = "hours";
  }
  if (diffTime.minutes > 0 || type !== "seconds") {
    str +=
      diffTime.minutes < 10
        ? "0" + diffTime.minutes + ":"
        : diffTime.minutes + ":";
    if (type === "seconds") type = "minutes";
  }
  str +=
    diffTime.seconds < 10
      ? "0" + diffTime.seconds + " " + type
      : diffTime.seconds + " " + type;

  return str;
}
