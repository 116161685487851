import React, { useContext } from "react";
import { ColorContext } from "App";
import { awareNodeStatus, getDeviceStatusCode } from "utils/iconUtils";

export default function NodeStatus({ device }) {
  const theme = useContext(ColorContext);

  const RED = "#C73B1D";
  const YELLOW = "#D9AF27";
  const ORANGE = "#FFA500";
  const BLACK = "#000000";
  const WHITE = "#FFFFFF";
  const GREEN = "#01b574";

  const status = getDeviceStatusCode(device);
  const colors = {
    Offline: {
      background: BLACK,
      color: WHITE,
      border: "2px solid #ccc",
    },
    Slow: {
      background: YELLOW,
      color: BLACK,
      border: "",
    },
    Warning: {
      background: ORANGE,
      color: BLACK,
      border: "",
    },
    Flooding: {
      background: RED,
      color: WHITE,
      border: "",
    },
    Online: {
      background: WHITE,
      color: BLACK,
      border: `2px solid ${GREEN}`,
    },
    Closed: {
      background: RED,
      color: WHITE,
      border: "",
    },
    Open: {
      background: WHITE,
      color: BLACK,
      border: `2px solid ${GREEN}`,
    },
  };
  
  const statusColor = () => {
    return colors[status] || colors["Offline"];
  };

  return (
    <div
      style={{
        backgroundColor: statusColor().background,
        color: statusColor().color,
        border: statusColor().border,
        borderRadius: 8,
        padding: 5,
        width: 80,
        textAlign: "center",
        boxSizing: "border-box",
        height: 30,
      }}
    >
      {device.data ? status : "Offline"}
    </div>
  );
}
