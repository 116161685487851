import { ColorContext } from "App";
import { index } from "d3";
import React, { useContext } from "react";
import { IconContext } from "react-icons";

export default function ShortSpread({
  style,
  header,
  headerStyle,
  data,
  icon,
}) {
  
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "solid grey 2px",
          fontWeight: "bold",
          fontSize: 20,
          ...headerStyle,
        }}
      >
        <div>{header}</div>
        {/* {icon && (
          <IconContext.Provider value={{ color: theme.fontColor, size: 25 }}>
            <div>{icon}</div>
          </IconContext.Provider>
        )} */}
      </div>

      {data && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 12,
            marginTop: 5,
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              height: "100%",
            }}
          >
            {Object.keys(data).map((item, index) => {
              return (
                <div key={index} style={{ flex: 1 }}>
                  {item !== "" ? `${item}: ` + data[item] : data[item]}
                </div>
              );
            })}{icon && (
              <IconContext.Provider value={{ color: theme.fontColor, size: 25 }}>
                <div>{icon}</div>
              </IconContext.Provider>
            )}
          </div> 
          
        </div>
      )}
    </div>
  );
}
