import { ColorContext } from "App";
import React, { useContext } from "react";
import { IconContext } from "react-icons";
import { TbCheck, TbLetterX } from "react-icons/tb";

const ToggleButton = ({ isOff, onClick }) => {
  
  const theme = useContext(ColorContext);
  return (<button
    style={{
      display: "flex",
      alignItems: "center",
      borderRadius: 50,
      backgroundColor: theme.primaryShadow,
      marginLeft: 5,
      marginRight: 5,
      marginBottom: 5,
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 100,
        height: 32,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isOff ? theme.primaryShadow : "white",
          width: 50,
          height: 32,
          borderRadius: 50,
        }}
        onClick={() => isOff && onClick()}
      >
        <IconContext.Provider value={{ color: "black", size: 30 }}>
          <TbCheck />
        </IconContext.Provider>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isOff ? theme.background : theme.primaryShadow,
          width: 50,
          height: 32,
          borderRadius: 50,
        }}
        onClick={() => !isOff && onClick()}
      >
        <IconContext.Provider
          value={{ color: isOff ? "white" : "black", size: 25 }}
        >
          <TbLetterX />
        </IconContext.Provider>
      </div>
    </div>
  </button>)
};

export default ToggleButton;
