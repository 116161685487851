export function extractDateTimeFromUrl(url) {
  return extractDateTimeFromPict(url);
  // Regular expression to match the date and time in the URL
  const regex = /_(\d{4}-\d{2}-\d{2})_(\d{6})\.jpg$/;

  // Use the regex to extract the date and time parts
  const match = url.match(regex);

  if (match) {
    const date = match[1]; // Extracted date part (YYYY-MM-DD)
    const time = match[2]; // Extracted time part (HHMMSS)

    // Combine date and time into a single string
    const dateTimeString = `${date}T${time.substring(0, 2)}:${time.substring(
      2,
      4
    )}:${time.substring(4, 6)}Z`;

    // Create a Date object from the combined date and time string
    const dateTime = new Date(dateTimeString);

    // Return the formatted date and time string in UTC
    return dateTime.toUTCString();
  } else {
    // Return an error message if the date and time are not found
    return "Date and time not found in the URL";
  }
}

export function extractDateTimeFromPict(pict) {
  if (!pict) return null;
  if (pict) {
    const regex = /_(\d{4}-\d{2}-\d{2})_(\d{6})\.jpg$/;
    const match = pict.match(regex);
    if (match) {
      const date = match[1];
      const time = match[2];
      const dateTimeString = `${date}T${time.substring(0, 2)}:${time.substring(
        2,
        4
      )}:${time.substring(4, 6)}Z`;
      const dateTime = new Date(dateTimeString);

      return dateTime.toLocaleString();
    }
  }
  return null;
}

export function displayDate(date) {
  if (!date) return "";
  return new Date(date).toLocaleString();
}
