import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as api from "../apis/FusionAPI";
import { validatePassword } from 'utils/passwordUtils';
import PasswordInput from 'components/UI/PasswordInput';
import { ColorContext } from 'App';

export default function ResetPassword({ setShowSmallMenu}) {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [error, setError] = useState("");
	const [errorColor, setErrorColor] = useState('red');
	const [newPassword, setNewPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);

	const [success, setSuccess] = useState(false);


	
  const theme = useContext(ColorContext);
	const code = searchParams.get("code");

	useEffect(() => {
		setShowSmallMenu(false)
		if (!code) {
			navigate('/');
		}
	}, []);

	const handleSubmit = async () => {
		if (newPassword !== confirmPassword) {
			setError("Passwords do not match");
			return
		}

    if(!validatePassword(newPassword).isValid){
      setError(validatePassword(newPassword).message);
      return
    }

		
		setError(null);
		// return
		// make api call to change password
		(async () => {
			try {
				const res = await api.updatePasswordWithCode(newPassword, code);
				
				setError("Password changed successfully");
				setSuccess(true);

			} catch (e) {
        setError("Error changing password: " + e.response.data.message);
				
			}
		})()
	}

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return
    }
    if(!validatePassword(newPassword).isValid){
      setError(validatePassword(newPassword).message);
      return
    }

    setError(null);
    
  }, [newPassword, confirmPassword])
	

	const goToLogin = async () => {
		Cookies.remove("accessToken");
		Cookies.remove("refreshToken");
		if (Cookies.get("rememberMe") === "false") Cookies.remove("email");

		setShowSmallMenu(true)
		navigate('/');
	}

	return (
		<div style={{
			backgroundColor: theme.base,
			width: 'max-content',
			display:'flex',
			flexDirection: 'column',
			alignItems:'flex-start',
			padding: 10
		}}>
			{!success && <div><div style={{color: errorColor}}>{error}</div>
			<div>
				<div style={{padding: 5, display:'flex', flexDirection:'column', justifyContent:'space-between', width: 450}}>
				New Password
				
				<PasswordInput
					callback={setNewPassword}
					value={newPassword}
				/>
				</div>
				<div style={{padding: 5, display:'flex', flexDirection:'column', justifyContent:'space-between', width: 450}}>
				Confirm New Password
				<PasswordInput
					callback={setConfirmPassword}
					value={confirmPassword}
				/>
				</div>
				<div style={{padding: 5, display:'flex', justifyContent:'space-between', alignItems:'center', width: 450}}>
				
				<button
					onClick={handleSubmit}
					style={{
						padding: 10, 
						borderRadius: 10, 
						backgroundColor: theme.primary, 
						color: theme.fontColor, 
						marginTop: 10, 
					
					}}
				>
					Reset Password
				</button>
				</div>
			</div></div>}
			{success && <div>
				<div>Password Reset Successful!</div>
				<button
					onClick={goToLogin}
					style={{
						padding: 10, 
						borderRadius: 10, 
						backgroundColor: theme.primary, 
						color: theme.fontColor, 
						marginTop: 10, 
					
					}}
				>
					Log In
				</button>
			</div>}
		</div>
			
	)
}
