import React, { useContext } from "react";
import ShortSpread from "../ShortSpread";
import { PiWavesBold } from "react-icons/pi";
import * as colorUtils from "utils/colorUtils";
import { abbreviatedCategoryToFull } from "utils/airQualityUtils";
import { ColorContext } from "App";

export default function AirQualityObservations({ data }) {
  
  const theme = useContext(ColorContext);
  if (data)
    return (
      <div
        className="scrollbar-hidden"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.base,
          // height: 700,
          marginTop: 15,
          padding: 15,
          overflow: "scroll",
        }}
      >
        {/* header */}
        <div
          style={{
            flex: 0.01,
            borderBottom: "solid grey 1px",
            fontSize: 20,
            fontWeight: "bold",
            marginBottom: 15,
          }}
        >
          Air Quality Observations Locations
        </div>

        {/* content */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              flex: 1,
              marginRight: 10,
            }}
          >
            {data
              .filter((item, index) => {
                return index % 2 == 0;
              })
              .map((item, index) => {
                return (
                  <div style={{
                    height: 80
                  }}>
                  <ShortSpread
                    key={index}
                    header={item.air_station_name}
                    data={{
                      Concentration: `${item.concentration}ppb`,
                      Category: abbreviatedCategoryToFull(item.category),
                    }}
                    icon={
                      <PiWavesBold
                        size={50}
                        color={colorUtils.GetQualityColor(item.category)}
                      />
                    }
                    style={{
                      height: "15%",
                      marginBottom: 15,
                    }}
                  />
                  </div>
                );
              })}
          </div>

          <div
            style={{
              flex: 1,
              marginLeft: 10,
            }}
          >
            {data
              .filter((item, index) => {
                return index % 2 == 1;
              })
              .map((item, index) => {
                return (
                  <ShortSpread
                    key={index}
                    header={item.air_station_name}
                    data={{
                      Concentration: `${item.concentration}ppb`,
                      Category: abbreviatedCategoryToFull(item.category),
                    }}
                    icon={
                      <PiWavesBold
                        size={50}
                        color={colorUtils.GetQualityColor(item.category)}
                      />
                    }
                    style={{
                      height: "15%",
                      marginBottom: 15,
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
}
