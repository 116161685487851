import React, { useContext, useEffect, useState } from "react";

import secure from "assets/about/secure.png";
import unlimited from "assets/about/unlimited.png";
import seamless from "assets/about/seamless.png";
import direct from "assets/about/direct.png";
import call from "assets/about/call.png";
import email from "assets/about/email.png";
import rescalert_small from "assets/about/rescalert_small.png";
import customweather_logo_wide from "assets/about/customweather_logo_wide.png";
import { ColorContext } from "App";

export default function About() {
  const imgStyle = {
    width: 50,
    height: 50,
    marginRight: 20,
  };
  
  const theme = useContext(ColorContext)

  return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {/*header*/}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <div>
            <h2>About RescAlert</h2>
          </div>

          <img src={rescalert_small} alt="rescalert_small" style={imgStyle} />
        </div>

        <h3>What makes us different from others!</h3>
        <div
          style={{
            width: "80%",
          }}
        >
          Our platform doesn't replace your existing system, it makes it better!
          Each organization continues to use their day-to-day platforms they
          know and trust. When it's time for the organization to respond to an
          emergency, RescAlert's Fusion+ connects them together in real-time
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 7.662,
          }}
        >
          <img src={secure} alt="secure" style={imgStyle} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Secure API</h3>
            <div>
              Our Open API allows for essentially unlimited integrations.
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 7.662,
          }}
        >
          <img src={unlimited} alt="secure" style={imgStyle} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Unlimited Resources</h3>
            <div>
              Fusion+ is designed to integrate with any database and perform
              two-way synchronization.
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 7.662,
          }}
        >
          <img src={seamless} alt="seamless" style={imgStyle} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            <h3>Seamless Integration</h3>
            <div>
              Integrates with each organization's existing day-to-day system.
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 7.662,
            marginBottom: 30,
          }}
        >
          <img src={direct} alt="secure" style={imgStyle} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Direct Communication</h3>
            <div>
              Send alerts and messages to the people in your area and those just
              passing through.
            </div>
          </div>
        </div>
        <div>
          <h2>About Fusion+</h2>

          <div>
            Overwatch Fusion+ is an advanced platform that unifies devices and
            systems into a cohesive interface, leveraging AI to enhance
            emergency management. It integrates data from sensors, cameras,
            communication devices, and monitoring systems, providing real-time
            insights and predictive analytics. By consolidating this
            information, Overwatch Fusion+ improves decision-making and response
            efficiency, eliminating the need to switch between systems and
            ensuring streamlined emergency management.
            <a
              style={{ marginLeft: 5, color: theme.primary, fontWeight: "bold" }}
              href="https://overwatch.ai/our-company/about-us"
              target="_blank"
            >
              https://overwatch.ai/our-company/about-us
            </a>
          </div>
        </div>
        <div>
          <div style={{ marginTop: 20 }}>
            <div>
              <div style={{ fontWeight: "bold" }}>Contact Us Now</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ marginRight: 70 }}>
                    <img
                      src={call}
                      alt="call"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 20,
                      }}
                    />
                    <h3>Call Us</h3>
                    <a
                      style={{ color: theme.fontColor, fontWeight: "bold" }}
                      href="tel:+18508609824"
                    >
                      +1.850.860.9824
                    </a>
                  </div>
                  <div>
                    <img
                      src={email}
                      alt="email"
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 20,
                      }}
                    />{" "}
                    <h3>Email Us</h3>
                    <a
                      style={{ color: theme.fontColor, fontWeight: "bold" }}
                      href="mailto:sales.info@rescalert.com"
                    >
                      sales.info@rescalert.com
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <div>
                    <img
                      src={customweather_logo_wide}
                      alt="customweather_logo_wide"
                      style={{
                        width: 180,
                        height: 40,
                        marginRight: 20,
                      }}
                    />
                  </div>
                  <a
                    style={{ color: theme.primary }}
                    href="https://www.customweather.com/"
                    target="_blank"
                  >
                    https://www.customweather.com/
                  </a>
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Weather Data provided by Custom Weather.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
