import React, { useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import * as api from "../../apis/FusionAPI";
import Cookies from "js-cookie";

import {
  FaArrowCircleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCompass,
  FaEye,
  FaWind,
} from "react-icons/fa";

import ConditionIcons from "../ConditionIcons";
import OvalDisplay from "../OvalDisplay";
import { RiDropLine, RiTempHotFill } from "react-icons/ri";
import { FaHouseChimney } from "react-icons/fa6";
import { WiSprinkle } from "react-icons/wi";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { Range } from "utils/mathUtils";
import { xml } from "d3";

import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";

let dataCache;
 

export default function WeatherForecastWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const layout = useContext(LayoutSizeContext);
  const [selectedItem, setSelectedItem] = useState(0);
  const [forecastHourlyData, setForecastHourlyData] = useState();
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getHourlyForecast";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  const theme = useContext(ColorContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          
          let filteredData = res.data.result.map((item) => {
            return {
              ...item,
              local_time: parseDateTime(item.local_time.toString()),
            };
          });
          dataCache = filteredData;
          setForecastHourlyData(dataCache);
          setFetching(false);
           
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setForecastHourlyData(dataCache);
        setFetching(false);
        return;
      }
      //get hourly forecast data
      FusionData.get(ROUTE)
        .then((res) => {
          
          let filteredData = res.data.result.map((item) => {
            return {
              ...item,
              local_time: parseDateTime(item.local_time.toString()),
            };
          });
          dataCache = filteredData;
          setForecastHourlyData(dataCache);
          setFetching(false);
           
        })
        .catch((err) => {
          
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  const parseDateTime = (datetime) => {
    
    const result = [];
    result.push(datetime[6], datetime[7], datetime[8], datetime[9]);
    result.push("-");
    result.push(datetime[2], datetime[3]);
    result.push("-");
    result.push(datetime[4], datetime[5]);
    result.push("T", datetime[0], datetime[1], ":00:00");

    return new Date(result.toString().replace(/,/g, ""));
  };

  const RightArrowComponent = () => {
    return (
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: 50,
          opacity: selectedItem < forecastHourlyData.length - 1 ? 1 : 0,
          cursor:
            selectedItem < forecastHourlyData.length - 1
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (selectedItem < forecastHourlyData.length - 1)
            setSelectedItem(selectedItem + 1);
        }}
      >
        <IconContext.Provider
          value={{
            color: theme.fontColor,
            size: 20,
          }}
        >
          <FaChevronRight />
        </IconContext.Provider>
      </button>
    );
  };

  const LeftArrowComponent = () => {
    return (
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: 50,
          opacity: selectedItem > 0 ? 1 : 0,
          cursor: selectedItem > 0 ? "pointer" : "default",
        }}
        onClick={() => {
          if (selectedItem > 0) setSelectedItem(selectedItem - 1);
        }}
      >
        <IconContext.Provider
          value={{
            color: theme.fontColor,
            size: 20,
          }}
        >
          <FaChevronLeft />
        </IconContext.Provider>
      </button>
    );
  };

  // if (dimensions) return (
  //   <div>
  //     {dimensions}
  //   </div>
  // )

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"WeatherForecast"}
        dimensions={dimensions}
      />
    );

  if (forecastHourlyData && forecastHourlyData.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}  updateIntervalMinutes={60} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        {forecastHourlyData && forecastHourlyData.length && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "85%",
                  // backgroundColor: "red",
                  alignItems: "center",
                  justifyContent: "space-around",
                  gap: 10,
                  marginTop: 10,
                }}
              >
                <LeftArrowComponent />

                {forecastHourlyData &&
                  forecastHourlyData.map((item, index) => {
                    const length = forecastHourlyData.length;
                    const numDisplayed = 7;
                    const halfNum = numDisplayed / 2;

                    if (
                      !Range(
                        Math.max(
                          Math.min(selectedItem, length - halfNum),
                          halfNum
                        ) - halfNum,
                        Math.min(
                          Math.max(selectedItem, halfNum),
                          length - halfNum
                        ) + halfNum,
                        index
                      )
                    )
                      return;

                    return (
                      <button
                        key={index}
                        onClick={() => setSelectedItem(index)}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <OvalDisplay
                          time={
                            (item.local_time.getHours() <= 12
                              ? item.local_time.getHours() > 0
                                ? item.local_time.getHours()
                                : "12"
                              : item.local_time.getHours() - 12) +
                            " " +
                            (item.local_time.getHours() < 12 ? " AM" : " PM")
                          }
                          temp={item.temperature}
                          selected={index === selectedItem}
                          icon={
                            <ConditionIcons conditionId={parseInt(item.icon)} />
                          }
                        />
                      </button>
                    );
                  })}

                <RightArrowComponent />
              </div>
              {forecastHourlyData && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: 90,
                    // marginTop: 10,
                    // marginLeft: 85,
                    marginLeft: "8%",
                    // borderTop: "solid grey 1px",
                    fontSize: layout.baseFontSize,
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <RiTempHotFill />
                      {` Temperature: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].temperature) ||
                        "?"
                      }°F`}
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <FaHouseChimney />
                      {` Comfort Level: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].comfort) ||
                        "?"
                      }°F`}
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <WiSprinkle />
                      {` Dew Point: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].dew_point) ||
                        "?"
                      }°F`}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <FaWind />
                      {` Wind SP: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].wind_speed) ||
                        "?"
                      } MPH`}
                    </div>
                    {/* <div style={{ marginTop: 10, marginBottom: 10 }}>
                
                <FaArrowCircleDown/>
                {` Barometric Pressure: ${
                  (forecastHourlyData[selectedItem] &&
                    forecastHourlyData[selectedItem].baro) ||
                  "?"
                } MI`}
              </div> */}
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <FaCompass />
                      {` Wind Direction: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].wind_dir) ||
                        "?"
                      }°`}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <RiDropLine />
                      {` Humidity: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].humidity) ||
                        "?"
                      }%`}
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <FaEye />
                      {` Visibility: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].visibility) ||
                        "?"
                      } MI`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        {forecastHourlyData && forecastHourlyData.length && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              paddingTop: 10,
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "50%",
                alignItems: "center",
                justifyContent: "space-between",
                // background: "green"
              }}
            >
              <LeftArrowComponent />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  rowGap: 5,
                  columnGap: 5,
                  width: "100%",
                  height: "100%",
                  // background:'red'
                }}
              >
                {forecastHourlyData &&
                  forecastHourlyData.map((item, index) => {
                    const length = forecastHourlyData.length;
                    const numDisplayed = 8;
                    const halfNum = numDisplayed / 2;

                    if (
                      !Range(
                        Math.max(
                          Math.min(selectedItem, length - halfNum),
                          halfNum
                        ) - halfNum,
                        Math.min(
                          Math.max(selectedItem, halfNum),
                          length - halfNum
                        ) + halfNum,
                        index
                      )
                    )
                      return;

                    return (
                      <button
                        key={index}
                        onClick={() => setSelectedItem(index)}
                        style={{ width: "100%" }}
                      >
                        <OvalDisplay
                          time={
                            (item.local_time.getHours() <= 12
                              ? item.local_time.getHours() > 0
                                ? item.local_time.getHours()
                                : "12"
                              : item.local_time.getHours() - 12) +
                            " " +
                            (item.local_time.getHours() < 12 ? " AM" : " PM")
                          }
                          temp={item.temperature}
                          selected={index === selectedItem}
                          icon={
                            <ConditionIcons conditionId={parseInt(item.icon)} />
                          }
                          // style={{
                          //   width: 60,
                          //   height: 200
                          // }}
                          iconStyle={{
                            width: 50,
                            height: 50,
                          }}
                        />
                      </button>
                    );
                  })}
              </div>

              <RightArrowComponent />
            </div>
            {forecastHourlyData && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: 90,
                  marginTop: 20,
                  marginLeft: 25,
                  // borderTop: "solid grey 1px",
                  fontSize: layout.baseFontSize,
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <RiTempHotFill />
                    {` Temperature: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].temperature) ||
                      "?"
                    }°F`}
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <FaHouseChimney />
                    {` Comfort Level: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].comfort) ||
                      "?"
                    }°F`}
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <WiSprinkle />
                    {` Dew Point: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].dew_point) ||
                      "?"
                    }°F`}
                  </div>

                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <FaWind />
                    {` Wind SP: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].wind_speed) ||
                      "?"
                    } MPH`}
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <FaCompass />
                    {` Wind Direction: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].wind_dir) ||
                      "?"
                    }°`}
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <RiDropLine />
                    {` Humidity: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].humidity) ||
                      "?"
                    }%`}
                  </div>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <FaEye />
                    {` Visibility: ${
                      (forecastHourlyData[selectedItem] &&
                        forecastHourlyData[selectedItem].visibility) ||
                      "?"
                    } MI`}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div
        style={{
          width: "100%",
          // height: "calc(100% - 42px)",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate
          route={ROUTE}
           
        />
        {forecastHourlyData && forecastHourlyData.length && (
          <div
            style={{
              display: "flex",
              // backgroundColor: theme.base,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "85%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "space-around",
                  height: "80%",
                  gap: 10,
                }}
              >
                <LeftArrowComponent />

                {forecastHourlyData &&
                  forecastHourlyData.map((item, index) => {
                    const length = forecastHourlyData.length;
                    const numDisplayed = 3;
                    const halfNum = numDisplayed / 2;

                    if (
                      !Range(
                        Math.max(
                          Math.min(selectedItem, length - halfNum),
                          halfNum
                        ) - halfNum,
                        Math.min(
                          Math.max(selectedItem, halfNum),
                          length - halfNum
                        ) + halfNum,
                        index
                      )
                    )
                      return;

                    return (
                      <button
                        key={index}
                        onClick={() => setSelectedItem(index)}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <OvalDisplay
                          time={
                            (item.local_time.getHours() <= 12
                              ? item.local_time.getHours() > 0
                                ? item.local_time.getHours()
                                : "12"
                              : item.local_time.getHours() - 12) +
                            " " +
                            (item.local_time.getHours() < 12 ? " AM" : " PM")
                          }
                          temp={item.temperature}
                          selected={index === selectedItem}
                          icon={
                            <ConditionIcons conditionId={parseInt(item.icon)} />
                          }
                        />
                      </button>
                    );
                  })}

                <RightArrowComponent />
              </div>
              {forecastHourlyData && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    // height: 90,
                    marginTop: 10,
                    // borderTop: "solid grey 1px",
                    fontSize: layout.baseFontSize,
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 5 * layout.scaleMult,
                        marginBottom: 0,
                      }}
                    >
                      <RiTempHotFill />
                      {` Temperature: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].temperature) ||
                        "?"
                      }°F`}
                    </div>
                    <div
                      style={{
                        marginTop: 5 * layout.scaleMult,
                        marginBottom: 0,
                      }}
                    >
                      <FaHouseChimney />
                      {` Comfort Level: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].comfort) ||
                        "?"
                      }°F`}
                    </div>
                    <div
                      style={{
                        marginTop: 5 * layout.scaleMult,
                        marginBottom: 0,
                      }}
                    >
                      <WiSprinkle />
                      {` Dew Point: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].dew_point) ||
                        "?"
                      }°F`}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 5 * layout.scaleMult,
                        marginBottom: 0,
                      }}
                    >
                      <RiDropLine />
                      {` Humidity: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].humidity) ||
                        "?"
                      }%`}
                    </div>
                    <div
                      style={{
                        marginTop: 5 * layout.scaleMult,
                        marginBottom: 0,
                      }}
                    >
                      <FaEye />
                      {` Visibility: ${
                        (forecastHourlyData[selectedItem] &&
                          forecastHourlyData[selectedItem].visibility) ||
                        "?"
                      } MI`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
}
