import React, { useContext, useEffect, useState } from "react";
import AirQualityObservations from "./AirQualityObservations";
import AirQualityForecast from "./AirQualityForecast";
import { FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast } from "utils/utilsBecauseSQLSucks";
import { useAPIData } from "hooks/useAPIData";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import useDataRefresh from "hooks/useDataRefresh";

export default function AirQuality({
  // data,
  selectedTab,
}) {
  const [data, setData] = useState();
  const [dates, setDates] = useState();

  useEffect(() => {
    const now = new Date();
    const later = new Date();
    later.setDate(later.getDate() + 14);

    
    setDates({ start: now, end: later });
  }, []);

  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAirQualityObservation";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
        //air quality
        FusionData.forceGet("getAirQualityObservation").then((res) => {
          res.data.result.map((item) => {
            item.loctime = new Date(item.iso8601);
          });

          FusionData.forceGet("getAirQualityForecast").then((innerRes) => {
            
            const fixedData =
              FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast(
                innerRes
              );

            fixedData.map((outer) => {
              outer.forecast_report.map((item) => {
                item.loctime = new Date(item.iso8601);
              });
            });

            setData({
              observations: [...res.data.result],
              forecast: [...fixedData],
            });
            setFetching(false);
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        //air quality
        FusionData.get("getAirQualityObservation").then((res) => {
          
          // res.data.result.map((item) => 

          res.data.result.map((item) => {
            item.loctime = new Date(item.iso8601);
          });

          FusionData.get("getAirQualityForecast").then((innerRes) => {
            
            const fixedData =
              FixOutputOfEndpointToTheCorrectFormat_AirQualityForecast(
                innerRes
              );

            fixedData.map((outer) => {
              outer.forecast_report.map((item) => {
                item.loctime = new Date(item.iso8601);
              });
            });

            setData({
              observations: [...res.data.result],
              forecast: [...fixedData],
            });
            setFetching(false);
          });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }
  const RenderTab = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <WidgetTimeUpdate route={ROUTE} dataUpdateOnly />
            {data?.observations && (
              <AirQualityObservations data={data.observations} />
            )}
          </div>
        );
      case 1:
        return (
          <div>
            <WidgetTimeUpdate route={ROUTE} dataUpdateOnly />
            {data?.forecast && <AirQualityForecast data={data.forecast} />}
          </div>
        );
      default:
        return <div />;
    }
  };

  
  if (!data) return <WidgetLoadingAnimation />;
  return RenderTab();
}
