import React, { useEffect, useState } from "react";

let currentSpeech = null;

export default function useTextToSpeech(initVoice = 0) {
  const [voices, setVoices] = useState();
  const [selectedVoice, setSelectedVoice] = useState(initVoice);
  // const [currentSpeech, setCurrentSpeech] = useState(null);

  useEffect(() => {
    window.speechSynthesis.cancel();
    const fetchVoices = () => {
      const voicesList = window.speechSynthesis.getVoices();
      setVoices(voicesList);
    };

    // Fetch voices initially
    fetchVoices();

    // Fetch voices when they change (e.g., on different devices)
    window.speechSynthesis.onvoiceschanged = fetchVoices;
  }, []);


  const isSpeaking = () => {
    return window.speechSynthesis.speaking;
  };

  const isPending = () => {
    return window.speechSynthesis.pending;
  };

  const speak = (text) => {
    const value = new SpeechSynthesisUtterance(text);
    value.addEventListener("error", (e) => {
      console.error("SpeechSynthesisUtterance error:", e);
    });
    if (voices[selectedVoice]) value.voice = voices[selectedVoice];

    //set the current value to itself
    value.onstart = () => {
      // setCurrentSpeech(value);
      currentSpeech = value;
    };

    window.speechSynthesis.speak(value);
  };


  const beginNewQueue = (newQueue, repeat, interrupt) => {
    if (newQueue.length === 0) return;
    if (
      interrupt ||
      (!window.speechSynthesis.speaking && !window.speechSynthesis.pending)
    ) {
      window.speechSynthesis.cancel();

      //add buffer because it's being dumb
      newQueue = ["", ...newQueue];
      
      newQueue.forEach((item, index) => {
        if (repeat && index === newQueue.length - 1) {
          const value = new SpeechSynthesisUtterance(item.text);
          if (voices[selectedVoice]) value.voice = voices[selectedVoice];

          //set the current value to itself
          value.onstart = () => {
            // setCurrentSpeech(value);
            currentSpeech = value;
          };

          value.onend = () => {
            let sliced = newQueue.slice(1);
            // only repeat if the item should repeat
            sliced = sliced.filter((item) => item.repeat)
            if (sliced.length > 1) beginNewQueue(sliced, repeat, interrupt);
          };
          window.speechSynthesis.speak(value);
        } else {
          speak(item.text);
        }
      });
    } else {
      
      currentSpeech.onend = () => {
        window.speechSynthesis.cancel();

        //add buffer because it's being dumb
        newQueue = ["", ...newQueue];
        
        newQueue.forEach((item, index) => {
          if (repeat && index === newQueue.length - 1) {
            const value = new SpeechSynthesisUtterance(item.text);
            if (voices[selectedVoice]) value.voice = voices[selectedVoice];

            //set the current value to itself
            value.onstart = () => {
              // setCurrentSpeech(value);
              currentSpeech = value;
            };

            value.onend = () => {
              let sliced = newQueue.slice(1);
              // only repeat if the item should repeat
              sliced = sliced.filter((item) => item.repeat)
              if (sliced.length > 1) beginNewQueue(sliced, repeat, interrupt);
            };
            window.speechSynthesis.speak(value);
          } else {
            speak(item.text);
          }
        });
      };
    }
  };

  return {
    voices: voices,
    isSpeaking: () => isSpeaking(),
    isPending: () => isPending(),
    setSelectedVoice: (index) => setSelectedVoice(index),
    cancel: () => window.speechSynthesis.cancel(),
    beginNewQueue: (text, repeat = false, interrupt = false) =>
      beginNewQueue(text, repeat, interrupt),
    speak: (text) => speak(text),
  };
}
