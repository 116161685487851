import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import NWSAlertsList from "components/StormCenter/displays/NWSAlertsList";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import Cookies from "js-cookie";
import { UniqueWidgetRefreshContext } from "App";

let dataCache;
 

export default function NWSAlertsWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [alerts, setAlerts] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  // useEffect(() => {
  //   dataCache = null;
  //   setFetching(true);
  // }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAllActiveAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  // useEffect(() => {
  //   dataCache = null;
  //   const fetchData = async () => {
  //     try {
  //       api.getAllActiveAlerts().then((res) => {
  //         const alertsSortedByTime = res.data.alerts.sort((a, b) => {
  //           return new Date(b.sentTime) - new Date(a.sentTime);
  //         });
  //         dataCache = alertsSortedByTime;
  //         setAlerts(dataCache);
  //         // setFetching(false);
  //       });
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setFetching(false);
  //     }
  //   };

  //   fetchData();
  // }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    (async () => {
      // if (dataCache) {
      //   setAlerts(dataCache);
      //   setFetching(false);
      //   return;
      // }

      api
        .getAllActiveAlerts()
        .then((res) => {
          // const alertsSortedByTime = res.data.alerts.sort((a, b) => {
          //   return new Date(b.sentTime) - new Date(a.sentTime);
          // });
          // dataCache = res.data.alerts; // alertsSortedByTime;

          const cwAlertsOnly = res.data.alerts.filter((alert) => {
            return alert.provider === "Custom Weather";
          });
          setAlerts(cwAlertsOnly);
          setFetching(false);
        })
        .catch((err) => {
          
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh, uwf[UNIQUEKEY]]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"NWSAlerts"}
        dimensions={dimensions}
      />
    );

  if (alerts && alerts.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}   realtime />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={"getAllActiveAlerts"}
          realtime
        />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          {" "}
          <NWSAlertsList alerts={alerts} />{" "}
        </div>
      </div>
    );
  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={"getAllActiveAlerts"}
          realtime
        />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          {" "}
          <NWSAlertsList alerts={alerts} />{" "}
        </div>
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate
          route={"getAllActiveAlerts"}
          realtime
        />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          {" "}
          <NWSAlertsList alerts={alerts} />{" "}
        </div>
      </div>
    );
}
