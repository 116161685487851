import { ColorContext, UniqueWidgetRefreshContext } from "App";
import BeaufortIcons from "components/BeaufortIcons";
import ForecastSpread from "components/ForecastSpread";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { useAPIData } from "hooks/useAPIData";
import useDataRefresh from "hooks/useDataRefresh";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { DateToString } from "utils/stringUtils";

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function WaveAndWind({
  tab,
  // data
}) {
  let dateStr = null;

  const [data, setData] = useState();
  // if (
  //   data &&
  //   data.filter((item) => {
  //     return item.weekday === weekdays[tab];
  //   }).length > 0
  // ) {
  //   dateStr = DateToString(
  //     new Date(
  //       data.filter((item) => {
  //         return item.weekday === weekdays[tab];
  //       })[0].iso8601
  //     )
  //   );
  // }

  // if (
  //   data &&
  //   data.filter((item) => {
  //     return item.weekday === weekdays[tab];
  //   }).length > 0
  // )

  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedMarine7";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  
  const theme = useContext(ColorContext);

  useEffect(() => {
    async function fetchData() {
      try {
        //get 15 day forecast
        FusionData.forceGet(ROUTE).then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          // setData(daySorted);
          setData([...res.data.result]);
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          // setData(daySorted);
          setData([...res.data.result]);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (data)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} dataUpdateOnly />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginTop: 15,
            height: "100%",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.base,
              flex: 1,
              height: "100%",
              padding: 15,
              marginRight: 10,
            }}
          >
            {dateStr && (
              <div
                style={{
                  fontSize: 20,
                  borderBottom: "solid grey 1px",
                  flex: 0.1,
                }}
              >
                {`${dateStr.month} ${dateStr.day}, ${dateStr.year} Wave and Wind Conditions`}
              </div>
            )}

            {/* Wave */}
            <div
              style={{
                marginTop: 15,
                fontSize: 24,
                fontWeight: "bold",
                flex: 0.1,
              }}
            >
              Wave
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Morning"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Morning</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wave Height": `${item.wave_height} ft`,
                          "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                          "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                          "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Afternoon"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Afternoon</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wave Height": `${item.wave_height} ft`,
                          "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                          "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                          "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Evening"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Evening</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wave Height": `${item.wave_height} ft`,
                          "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                          "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                          "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Night"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Night</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wave Height": `${item.wave_height} ft`,
                          "Mean Wave Dir./Period": `${item.mean_wave_direction_short} / ${item.mean_wave_period} sec`,
                          "Wind Wave Dir./Period": `${item.wind_wave_direction_short} / ${item.wind_wave_period} sec`,
                          "Peak Wave Dir./Period": `${item.peak_wave_direction_short} / ${item.peak_wave_period} sec`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
          </div>

          {/* Wind */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.base,
              flex: 1,
              height: "100%",
              padding: 15,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
                flex: 0.1,
              }}
            >
              Wind
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Morning"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Morning</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                          "Wind Gusts": `${item.wind_gusts}`,
                          "Beaufort Description": `${item.beaufort_desc}`,
                          "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Afternoon"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Afternoon</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                          "Wind Gusts": `${item.wind_gusts}`,
                          "Beaufort Description": `${item.beaufort_desc}`,
                          "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Evening"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Evening</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                          "Wind Gusts": `${item.wind_gusts}`,
                          "Beaufort Description": `${item.beaufort_desc}`,
                          "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
            <div style={{ flex: 1 }}>
              {data
                .filter((item) => {
                  return (
                    item.weekday === weekdays[tab] &&
                    item.day_segment === "Night"
                  );
                })
                .map((item, index) => {
                  if (index === 0)
                    return (
                      <ForecastSpread
                        key={index}
                        header={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: "bold",
                              fontSize: 20,
                            }}
                          >
                            <div>Night</div>
                            <div style={{ marginRight: 10 }}>
                              {item.beaufort_desc}
                            </div>
                          </div>
                        }
                        data={{
                          "Wind Speed & Direction": `${item.wind_speed} / ${item.wind_short}`,
                          "Wind Gusts": `${item.wind_gusts}`,
                          "Beaufort Description": `${item.beaufort_desc}`,
                          "Sea Surface Temp": `${item.sea_surface_temp}°F`,
                        }}
                        icon={<BeaufortIcons conditionId={item.beaufort} />}
                        style={{ marginTop: 15, height: "100%", fontSize: 14 }}
                      />
                    );
                })}
            </div>
          </div>
          {/* {[0,1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => {
          return <div> 
            {item}: <BeaufortIcons key={index} conditionId={item} />
          </div>;
        })} */}
        </div>
      </div>
    );

  return <WidgetLoadingAnimation />;
}
