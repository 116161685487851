import { ColorContext } from 'App'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

const DateTime = ({setData, data, type = "dateTime", setError}) => {

// const [storedData, setStoredData] = useState({
//   dateTime: type === "dateTime" ? new Date() : "",
//   date: type === "date" ? new Date() : "",
//   time: type === "time" ? new Date() : ""
// })

useEffect(() => {
  if (data?.dateTime === undefined || data?.date === undefined|| data?.time === undefined ) {
    setData({
      dateTime: type === "dateTime" ? new Date() : "",
      date: type === "date" ? new Date() : "",
      time: type === "time" ? new Date() : "",
      error: true
    }) 
    setError({message: "Date/time missing"})
  }
}, [])

const updateData = (item) => {
  
  setData({
    ...data,
    ...item,
    error: verifyData({...data, ...item})
  })
}
const theme = useContext(ColorContext);

const verifyData = (data) => {
  
  let error = false
  if (type === "dateTime" && data.dateTime === "") {
    error = true
  }
  if (type === "date" && data.date === "") {
    error = true
  }
  if (type === "time" && data.time === "") {
    error = true
  }
  if (error) { 
    
    setError({message: "Date/time missing"}) 
  }
  else  {
    setError(null)
  }
  return error
}

// useEffect(() => {
//   setData(storedData)
// }, [storedData])

  return (
    <div key={type}>
      {type === "dateTime"  && <div style={{ display: 'flex', flexDirection: "column",
            padding: 20,
            borderRadius: 10,
            margin: 10,
            background: theme.primary,}}>
        <p>Date Picker</p>
        <input
          type="datetime-local"
          name="dateTime"
          // value={moment(data.dateTime).format(
          //   "YYYY-MM-DDTHH:mm"
          // )}
          value={data.dateTime}
          onChange={(e) => updateData({
            dateTime: e.target.value
          })}
          placeholder="Date & Time Picker"
        />
      </div>}
      {type === "time" && <div style={{ display: 'flex', flexDirection: "column",
            padding: 20,
            borderRadius: 10,
            margin: 10,
            background: theme.primary,}}>
        <p>Time Picker</p>
        <input
          type="time"
          name="time"
          // value={data.time || moment().format("HH:mm")}
          value={data.time}
          onChange={(e) => updateData({
            time: e.target.value
          })}
          placeholder="Time Picker"
        />
      </div>}
      {type === "date" && <div style={{ display: 'flex', flexDirection: "column",
            padding: 20,
            borderRadius: 10,
            margin: 10,
            background: theme.primary, }}>
        <p >Date Picker</p>
        <input
          type="date"
          name="date"
          // value={moment(data.date).format(
          //   "YYYY-MM-DD"
          // )}
          value={data.date}
          onChange={(e) => updateData({
            date: e.target.value
          })}
          placeholder="Date Picker"
        />
      </div>}
    </div>
  )
}

export default DateTime