import LocationSetter from "components/AlertCreator/components/LocationSetter";
import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import * as api from "apis/FusionAPI";
import { Switch, Space } from "antd";
import { Checkbox } from "antd";
import {
  Circle,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";
import useDataRefresh from "hooks/useDataRefresh";

const { BaseLayer } = LayersControl;

let dataCache;

const EarthquakeMap = ({
  widget,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
  preview,
  sidebarOpen,
}) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  const layout = useContext(LayoutSizeContext);
  const ROUTE = "getEarthQuake";

  const [earthquakeData, setEarthquakeData] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: Cookies.get("latitude"),
    lng: Cookies.get("longitude"),
  });

  const [currentItem, setCurrentItem] = useState(null);
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);
  const dataRefresh = useDataRefresh();
  useEffect(() => {
    (async () => {
      if (dataCache) {
        setEarthquakeData(dataCache);
        setFetching(false);
        return;
      }

      //get earthquake data
      FusionData.forceGet(ROUTE)
        .then((res) => {
          
          dataCache = res.data.result;
          setEarthquakeData(dataCache);

          setFetching(false);
        })
        .catch((err) => {
          
          setError({ state: true, message: err.message });
        });
    })();
  }, [dataRefresh.widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await FusionData.forceGet(ROUTE);
        dataCache = res.data.result;
        setEarthquakeData(dataCache);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const capitalizeBetweenKmAndOf = (str) => {
    const start = str.indexOf("Km") + 2;
    const end = str.indexOf("Of");
    const toCapitalize = str.slice(start, end).trim();
    const capitalized = toCapitalize.toUpperCase();
    return str.slice(0, start) + " " + capitalized + " " + str.slice(end);
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching && widget) {
    // if (true && widget) {
    return (
      <WidgetLoadingAnimation
        widgetName={"Earthquake"}
        dimensions={dimensions}
      />
    );
  }

  if (fetching) { 
    return <WidgetLoadingAnimation />;
  }

  if (earthquakeData && earthquakeData.length === 0) {
    return (
      <div>
        {widget && <WidgetTimeUpdate route={ROUTE} realtime />}
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (!widget || !dimensions)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} realtime addMargin/>
        <FullScreenMap mapCenter={mapCenter} setView={mapCenter} zoom={5}>
          <LocationSetter center={mapCenter} zoom={5} />
          {earthquakeData.length &&
            earthquakeData.map((item, index) => {
              return (
                <Circle
                  key={index}
                  center={{ lat: item.latitude, lng: item.longitude }}
                  fillColor="#d1af15"
                  color="red"
                  weight={2}
                  radius={item.magnitude * 10000 * 2.5}
                >
                  <Popup>
                    {item.city} ({item.latitude}, {item.longitude})<br />
                    {`Magnitude: ${item.magnitude} (Depth: ${item.depth} KM)`}
                    <br />
                    {item.occurrence}
                  </Popup>
                </Circle>
              );
            })}
        </FullScreenMap>

        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
            marginBottom: 10,
            marignTop: 20,
          }}
        >
          Recent Earthquakes
        </div>
        <div
          style={{
            padding: 20,
            height: "300px",
            overflowY: "auto",
          }}
        >
          {earthquakeData.length &&
            earthquakeData.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setMapCenter({
                      lat: item.latitude,
                      lng: item.longitude,
                    });
                  }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid grey",
                      margin: 15,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>On {item.occurrence.slice(0, -5)}</div>
                    <div>
                      {capitalizeBetweenKmAndOf(item.city)} ({item.latitude},{" "}
                      {item.longitude})
                    </div>
                    <div>
                      Magnitude: {item.magnitude} (Depth: {item.depth} KM)
                    </div>
                    {/* <div>{item.comments}</div> */}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div>
        <WidgetTimeUpdate realtime route={ROUTE} />
        {earthquakeData.length && (
          <div style={{ fontSize: layout.baseFontSize }}>
            <div>
              <WidgetMap
                sidebarOpen={sidebarOpen}
                center={mapCenter}
                setView={mapCenter}
                zoom={5}
                preview={preview}
                // style={{ height: "100%", width: "100%" }}
              >
                <LocationSetter center={mapCenter} zoom={5} />
                {earthquakeData.length &&
                  earthquakeData.map((item, index) => {
                    return (
                      <Circle
                        key={index}
                        center={{ lat: item.latitude, lng: item.longitude }}
                        fillColor="#d1af15"
                        color="red"
                        weight={2}
                        radius={item.magnitude * 10000 * 2.5}
                        eventHandlers={{
                          click: () => {
                            setCurrentItem(item);
                          },
                        }}
                      >
                        <Popup>
                          {item.city} ({item.latitude}, {item.longitude})
                          <br />
                          {`Magnitude: ${item.magnitude} (Depth: ${item.depth} KM)`}
                          <br />
                          {item.occurrence}
                        </Popup>
                      </Circle>
                    );
                  })}
              </WidgetMap>
            </div>
            {currentItem && (
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>{capitalizeBetweenKmAndOf(currentItem.city)} </div>
                <div>
                  ({currentItem.latitude}, {currentItem.longitude})
                </div>
                <br />
                <div>Time: {currentItem.occurrence.slice(0, -5)}</div>
                <br />
                <div>Magnitude: {currentItem.magnitude}</div>
                <br />
                <div>Depth: {currentItem.depth} KM</div>
              </div>
            )}
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ height: "94%", width: "100%" }}>
        {<WidgetTimeUpdate realtime route={ROUTE} />}
        {earthquakeData.length && (
          <div
            style={{
              height: 425,
              // marginTop: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontSize: layout.baseFontSize,
            }}
          >
            <div style={{ height: "100%", width: "100%" }}>
              {/* <MapContainer
                center={mapCenter}
                setView={mapCenter}
                zoom={5}
                style={{ height: "100%", width: "100%" }}
              > */}
              <WidgetMap
                sidebarOpen={sidebarOpen}
                mapCenter={mapCenter}
                zoom={5}
                setView={mapCenter}
                preview={preview}
              >
                <LocationSetter center={mapCenter} zoom={5} />
                {earthquakeData.length &&
                  earthquakeData.map((item, index) => {
                    return (
                      <Circle
                        key={index}
                        center={{ lat: item.latitude, lng: item.longitude }}
                        fillColor="#d1af15"
                        color="red"
                        weight={2}
                        radius={item.magnitude * 10000 * 2.5}
                        eventHandlers={{
                          click: () => {
                            setCurrentItem(item);
                          },
                        }}
                      >
                        <Popup>
                          {item.city} ({item.latitude}, {item.longitude})<br />
                          {`Magnitude: ${item.magnitude} (Depth: ${item.depth} KM)`}
                          <br />
                          {item.occurrence}
                        </Popup>
                      </Circle>
                    );
                  })}
              </WidgetMap>
              {/* </MapContainer> */}
            </div>
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div style={{ height: "90%", width: "100%" }}>
        <WidgetTimeUpdate realtime route={ROUTE} />
        {earthquakeData.length && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: layout.baseFontSize,
            }}
          >
            <div>
              {currentItem && (
                <div
                  style={{
                    width: "100%",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>{capitalizeBetweenKmAndOf(currentItem.city)} </div>
                  <div>
                    ({currentItem.latitude}, {currentItem.longitude})
                  </div>
                  <br />
                  <div>Time: {currentItem.occurrence.slice(0, -5)}</div>
                  <br />
                  <div>Magnitude: {currentItem.magnitude}</div>
                  <br />
                  <div>Depth: {currentItem.depth} KM</div>
                </div>
              )}
            </div>
            <div>
              <WidgetMap
                sidebarOpen={sidebarOpen}
                center={mapCenter}
                setView={mapCenter}
                zoom={5}
                preview={preview}
              >
                <LocationSetter center={mapCenter} zoom={5} />
                {earthquakeData.length &&
                  earthquakeData.map((item, index) => {
                    return (
                      <Circle
                        key={index}
                        center={{ lat: item.latitude, lng: item.longitude }}
                        fillColor="#d1af15"
                        color="red"
                        weight={2}
                        radius={item.magnitude * 10000 * 2.5}
                        eventHandlers={{
                          click: () => {
                            setCurrentItem(item);
                          },
                        }}
                      >
                        <Popup>
                          {item.city} ({item.latitude}, {item.longitude})<br />
                          {`Magnitude: ${item.magnitude} (Depth: ${item.depth} KM)`}
                          <br />
                          {item.occurrence}
                        </Popup>
                      </Circle>
                    );
                  })}
              </WidgetMap>
            </div>
          </div>
        )}
      </div>
    );
};

export default EarthquakeMap;
