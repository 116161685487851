import { ColorContext } from "App";
import React, { useContext } from "react";

const ModeButton = ({ mode, modeName, keyName, setMode, deviceSettings }) => {
  const theme = useContext(ColorContext);
  
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 50,
        backgroundColor:
          deviceSettings[keyName] == mode ? "white" : theme.primaryShadow,
        color: deviceSettings[keyName] == mode ? "black" : "white",
        width: 100,
        height: 32,
      }}
      onClick={() => setMode(mode)}
    >
      {modeName}
    </button>
  );
};

export default ModeButton;
