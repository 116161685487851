import SevereWeatherAlertsMap from "components/Maps/SevereWeatherAlertsMap/SevereWeatherAlertsMap";
import React from "react";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";

const { BaseLayer } = LayersControl;

export default function AdvisoriesMap({ testData }) {
  const date = new Date().toLocaleDateString();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ margin: 15 }}>
          <div
            style={{
              fontSize: 30,
              fontWeight: "bold",
              width: "100%",
              borderBottom: "solid grey 1px",
            }}
          >
            {date} Advisories Map
          </div>
        </div>
      </div>

      <SevereWeatherAlertsMap fullScreen />
    </div>
  );
}
