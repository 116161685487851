import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ViewAlerts from "../ViewAlerts.js";
import AlertCreator from "../../components/AlertCreator/AlertCreator.js";
import { testPermission } from "data/routeData";
import * as api from "apis/FusionAPI";
import useDebounce from "hooks/useDebounce";
import { AlertListPage } from "components/AlertListPage";
import Cookies from "js-cookie";
import { useAPIData } from "hooks/useAPIData.js";
import { usePerms } from "hooks/usePerms.js";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { UniqueWidgetRefreshContext } from "App.js";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation.js";
import useDataRefresh from "hooks/useDataRefresh.js";

let dataCache;

export default function Alerts({
  dimensions,
  widgetRefresh,
  setWidgetRefresh,
  sidebarOpen,
}) {
  const [alertsList, setAlertsList] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const perms = usePerms();
  const navigate = useNavigate();

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAllActiveAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllActiveAlerts(); // FusionData.get(ROUTE);
        
        const cwAlertsOnly = response.data.alerts.filter((alert) => {
          return alert.provider === "Custom Weather";
        });

        setAlertsList(cwAlertsOnly);
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  
  const dataRefresh = useDataRefresh()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllActiveAlerts(); // FusionData.get(ROUTE);
        
        const cwAlertsOnly = response.data.alerts.filter((alert) => {
          return alert.provider === "Custom Weather";
        });

        setAlertsList(cwAlertsOnly);
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);


  if (fetching) {
    return <WidgetLoadingAnimation widgetName={"WeatherAlerts"} dimensions={dimensions}/>;
  }

  if (perms.testPermission([8, 10, 11, 12, 13], ["WeatherAlerts"]))
    if (dimensions) {
      return (
        <div>
          {fetching ? (
            <div>fetching</div>
          ) : (
            <AlertListPage
              widget
              dimensions={dimensions}
              title={"Active Weather Alerts"}
              alertsList={alertsList}
              setAlertsList={setAlertsList}
              sidebarOpen={sidebarOpen}
              ROUTE={ROUTE}
            />
          )}
        </div>
      );
    } else {
      return (
        <div style={{height: "calc(100% - 20px)"}}>
          <WidgetTimeUpdate route={ROUTE}  realtime dataUpdateOnly addMargin />
          <AlertListPage
            title={"Active Weather Alerts"}
            alertsList={alertsList}
            setAlertsList={setAlertsList}
            sidebarOpen={sidebarOpen}
            ROUTE={ROUTE}
          />
        </div>
      );
    }
}
