import { ColorContext } from "App";
import React, { useContext } from "react";

export const Page404 = () => {
  
  const theme = useContext(ColorContext);
  return (
    <div style={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      width: "100%",
      alignContent: "center",
      color: theme.fontColor,
    }}>
      <h1>404</h1>
      The page you were requesting is not available.
    </div>
  );
};
