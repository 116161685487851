import React from "react";
import icon from "../assets/icons/Group 717.png";

export default function AddWidgetComponent({ openWidgetSelector }) {
  return (
    <button
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={openWidgetSelector}
    >
      <img src={icon} style={{ width: 50 }} />
    </button>
  );
}
