import React from "react";
import {
  Chart as ChartJS,
  PointElement,
  BarElement,
  Title,
  CategoryScale,
  LinearScale,
  Tooltip,
  Filler,
} from "chart.js";
import * as Charts from "react-chartjs-2";

ChartJS.register(
  PointElement,
  BarElement,
  Title,
  CategoryScale,
  LinearScale,
  Tooltip,
  Filler
);

export default function BarChart({
  data,
  options,
  dataOverride,
  optionsOverride,
}) {
  let chartData;

  if (data) {
    chartData = {
      labels: data.map((item) => {
        return item.label;
      }),
      datasets: [
        {
          data: data.map((item) => {
            return item.dataPoint;
          }),
          ...options.datasetOptions,
        },
      ],
    };
  } else if (dataOverride) {
    chartData = dataOverride;
  } else {
    return <div />;
  }

  let chartOptions;

  if (options) {
    chartOptions = {
      responsive: true,
      plugins: { ...options.pluginOptions },
    };
  } else if (optionsOverride) {
    chartOptions = optionsOverride;
  } else {
    return <div />;
  }

  return (
    <div style={{ backgroundColor: "white", width: "100%", height: "100%" }}>
      <Charts.Bar options={chartOptions} data={chartData} />
    </div>
  );
}
