import React, { useContext, useEffect, useState } from "react";

import ConditionIcons from "../../components/ConditionIcons";
import ForecastDetails from "../ForecastSpread";
import { RiDropLine, RiTempHotFill, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { WiSprinkle } from "react-icons/wi";
import { PiWind } from "react-icons/pi";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import { useAPIData } from "hooks/useAPIData";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import useDataRefresh from "hooks/useDataRefresh";

const HourlyForecast = ({
  // forecastHourlyData,
  isNarrowScreen,
}) => {
  const [forecastHourlyData, setForecastHourlyData] = useState();

  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getHourlyForecast";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
        //get hourly forecast data
        FusionData.forceGet("getHourlyForecast").then((res) => {
          
          setForecastHourlyData(
            res.data.result.map((item) => {
              return {
                ...item,
                local_time: parseDateTime(item.iso8601.toString()),
              };
            })
          );
        });
        setFetching(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);


  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {//get hourly forecast data
        FusionData.get("getHourlyForecast").then((res) => {
          
          setForecastHourlyData(
            res.data.result.map((item) => {
              return {
                ...item,
                local_time: parseDateTime(item.iso8601.toString()),
              };
            })
          );
        });
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);



  const parseDateTime = (datetime) => {
    
    const result = [];
    result.push(datetime[6], datetime[7], datetime[8], datetime[9]);
    result.push("-");
    result.push(datetime[2], datetime[3]);
    result.push("-");
    result.push(datetime[4], datetime[5]);
    result.push("T", datetime[0], datetime[1], ":00:00");

    
    

    return new Date(datetime);
  };

  if (fetching) {
    return <WidgetLoadingAnimation/>
  }
  if (forecastHourlyData) return (
    <div
      style={{
        // marginTop: 15,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} updateIntervalBasis={60} dataUpdateOnly/>
      <div
        style={{
          display: "flex",
          flexDirection: isNarrowScreen ? "column" : "row",
          flexWrap: "wrap",
        }}
      >
        {forecastHourlyData &&
          forecastHourlyData.slice(0, 8).map((item, index) => (
            <div
              key={index}
              style={{
                flex: "1 1 45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: "10px",
                boxSizing: "border-box",
              }}
            >
              <ForecastDetails
                header={new Date(item.iso8601).toLocaleTimeString()}
                data={{
                  Temperature: `${item.temperature}° F`,
                  "Wind SP": `${item.wind_speed} MPH`,
                  Humidity: `${item.humidity}%`,
                  "Comfort Level": `${item.comfort}° F`,
                  Visibility: `${item.visibility} mi`,
                  "Dew Point": `${item.dew_point}° F`,
                  "Wind Direction": `${item.wind_short}`,
                }}
                dataIcons={[
                  <RiTempHotFill />,
                  <RiWindyFill />,
                  <RiDropLine />,
                  <FaHouseChimney />,
                  <FaEye />,
                  <WiSprinkle />,
                  <PiWind />,
                ]}
                icon={<ConditionIcons conditionId={parseInt(item.icon)} />}
              />
            </div>
          ))}
      </div>
    </div>
  );
  return <WidgetLoadingAnimation />
};

export default HourlyForecast;
