import { ColorContext, LayoutSizeContext } from "App";
import React, { useContext } from "react";
import { IconContext } from "react-icons";

export default function OvalDisplay({
  time,
  temp,
  icon,
  selected,
  style,
  iconStyle,
}) {
  const layout = useContext(LayoutSizeContext);
  
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primary,
        borderRadius: 500,
        padding: 10,
        width: "100%",
        // maxWidth: 100,
        height: "100%",
        // maxHeight: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        border: selected ? "solid white 2px" : "solid rgba(0,0,0,0) 2px",
        boxSizing: "border-box",
        alignContent: "center",

        ...style,
      }}
    >
      <div
        style={{
          whiteSpace: "nowrap",
          width: 40 * layout.scaleMult,
          fontSize: 12 * layout.scaleMult,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
        }}
      >
        {time}
      </div>
      <div
        style={{
          width: 40 * layout.scaleMult,
          height: 40 * layout.scaleMult,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconContext.Provider
          value={{
            color: theme.fontColor,
            size: "100%",
          }}
        >
          {icon}
        </IconContext.Provider>
      </div>
      <div
        style={{
          fontSize: 16 * layout.scaleMult,
        }}
      >
        {temp}
      </div>
    </div>
  );
}
