export function Lerp(a, b, t) {
  return a * (1 - t) + b * t;
}

export function Range(min, max, value) {
  return value >= min && value < max;
}

export function FlipIndex(index, length) {
  return length - index + 1;
}

export function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function toDegrees(radians) {
  return radians * (180 / Math.PI);
}
