import { Slider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { capitalizeWord, GetDeviceTypeString } from "utils/stringUtils";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import DevicesMap from "pages/System/DevicesMap";
import RadialAlertMap from "./RadialAlertMap";
import AlertIcons from "components/AlertIcons";
import { ColorContext } from "App";
import CreateRadialAlert, {
  VerifyRadialAlert,
} from "./DeviceActions/CreateRadialAlert";
import ToggleFlashingLights from "./DeviceActions/ToggleFlashingLights";

const DeviceAction = ({ deviceList, setData, data, setError }) => {
  useEffect(() => {
    if (
      data?.node_id === undefined ||
      data?.deviceType === undefined ||
      data?.selectedDevice === undefined ||
      data?.selectedFunction === undefined
    ) {
      setData({
        node_id: "",
        deviceType: "",
        selectedDevice: "",
        selectedFunction: "",
        error: true,
      });
    }
  }, []);
  const theme = useContext(ColorContext);

  const updateData = (item) => {
    //create new object
    let newObj = { ...data, ...item };

    //trim unwanted keys
    newObj = Object.keys(newObj).reduce((acc, key) => {
      if (
        deviceFunctions[data.selectedFunction]?.keys.includes(key) ||
        universalKeys.includes(key)
      )
        acc[key] = newObj[key];

      return acc;
    }, {});

    setData({
      ...newObj,
      error: verifyData(newObj),
    });
  };

  const verifyData = (data) => {
    let error = false;
    if (
      data.node_id === "" ||
      data.deviceType === "" ||
      data.selectedFunction === ""
    ) {
      error = true;
    }

    //verify function variables
    error = deviceFunctions[data.selectedFunction]?.verify
      ? !deviceFunctions[data.selectedFunction].verify(data)
      : true;

    if (!error) {
      setError(null);
    }
    return error;
  };

  const deviceTypes = [
    ...new Set(deviceList.map((device) => GetDeviceTypeString(device)).sort()),
  ];

  const functionList = {
    "aware flood": ["Create Radial Alert"],
    "rescalert devices manual road closure": ["Toggle Manual Road Closure"],
    "rescalert devices technology bridge": ["Toggle Flashing Lights"],
  };

  const deviceFunctions = {
    "Create Radial Alert": {
      component: (
        <CreateRadialAlert
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        const verified = VerifyRadialAlert(obj);
        if (!verified)
          setError({ message: "Action incomplete, check for missing fields." });
        return verified;
      },
      keys: ["headline", "description", "type", "radius", "severity"],
    },
    "Toggle Manual Road Closure": {
      component: (
        <ToggleFlashingLights
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        if (obj.status === null || obj.status === undefined) {
          setError({
            message: "Action incomplete, check for missing fields.",
          });
          return false;
        } else return true;
      },
      keys: ["status", "comment"],
    },
    "Toggle Flashing Lights": {
      component: (
        <ToggleFlashingLights
          dataObj={data}
          deviceList={deviceList}
          onUpdate={updateData}
        />
      ),
      verify: (obj) => {
        if (obj.status === null || obj.status === undefined) {
          setError({
            message: "Action incomplete, check for missing fields.",
          });
          return false;
        } else return true;
      },
      keys: ["status", "comment"],
    },
  };

  const universalKeys = [
    "node_id",
    "deviceType",
    "selectedDevice",
    "selectedFunction",
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: 20,
          borderRadius: 10,
          margin: 10,
          background: theme.primary,
          display: "flex",
          flexDirection: "row",
          gap: 20,
          alignItems: "center",
        }}
      >
        Device Type:
        <select
          style={{
            background: theme.base,
            padding: 5,
          }}
          onChange={(e) => {
            if (e.target.value === "") {
              updateData({
                deviceType: "",

                //need to clear sub-options
                selectedFunction: null,
              });
              return;
            } else
              updateData({
                deviceType: e.target.value,

                //need to clear sub-options
                selectedFunction: null,
              });
          }}
          value={data.deviceType}
        >
          <option value="">-Select-</option>
          {deviceTypes.map((type) => {
            return <option value={type}>{type}</option>;
          })}
        </select>
        {data.deviceType && functionList[data.deviceType.toLowerCase()] && (
          <>
            Device Action:
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                updateData({ selectedFunction: e.target.value });
              }}
              value={data.selectedFunction || ""}
            >
              <option value="">-Select-</option>
              {functionList[data.deviceType.toLowerCase()].map((func) => {
                return <option value={func}>{func}</option>;
              })}
            </select>
          </>
        )}
      </div>
      {data.selectedFunction &&
        deviceFunctions[data.selectedFunction]?.component}
    </div>
  );
};

export default DeviceAction;
