import { ColorContext } from 'App';
import React, { useContext, useState } from 'react'

const Toggle = ({
  callback, 
  value = false, 
  size = 20, 
  colorDisabled = "#222a2d",
  colorEnabled = "#436c9a"
}) => {
  // const [enabled, setEnabled] = useState(value)

  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: size,
        width: size * 2,
        borderRadius: size,
        cursor: "pointer",
        alignItems: "center",
        background: value ? colorEnabled : colorDisabled,

      }}
      onClick={() => {
        callback(!value)
        // setEnabled(!enabled)
      }}
    >
      <div
        style={{
          height: size * .7,
          width: size * .7,
          borderRadius: size,
          background:'white',
          marginLeft: value ? size * 1.1 : size * .15,
        }}
      >

      </div>
    </div>
  )
}

export default Toggle