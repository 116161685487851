import React, { useContext } from "react";
import { RoleDropdown } from "./RoleDropdown";
import Popup from "reactjs-popup";
import { ColorContext, LayoutSizeContext } from "App";

const RoleInput = ({
  roleList,
  setFormData,
  formData,
  lockedRolesList,
  editable = true,
}) => {
  const theme = useContext(ColorContext);
  const layout = useContext(LayoutSizeContext);

  return editable ? (
    <Popup
      trigger={
        <div
          style={{
            width: "calc(100% - 20px)",
            height: 31,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: editable ? theme.primary : "#444",
            fontSize: 13,
            borderRadius: 10,
            gap: 20,
            border: `1px solid grey`,
            color: theme.fontColor, // Added to ensure text is visible against the background
          }}
        >
          <div
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {(() => {
              const rolesString = Object.keys(formData.roles)
                .filter((role) => formData.roles[role]) // Filter roles that are true
                .join(", "); // Join with ", "
              return rolesString;
            })()}
          </div>
        </div>
      }
      position="bottom center"
      on="hover"
      closeOnDocumentClick
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          padding: 10,
          backgroundColor: theme.primaryHighlight,
          color: theme.fontColor,
          borderRadius: 10,
          cursor: "pointer",
          overflow: "auto",
          maxHeight: layout.height - 400,
          width: layout.width / 2 - 52,
        }}
      >
        {roleList &&
          roleList
            .filter((filter) => filter.role_Id >= 0)
            .map((item, index) => (
              <RoleDropdown
                key={index}
                item={item}
                setFormData={setFormData}
                formData={formData}
                lockedRolesList={lockedRolesList}
              />
            ))}
      </div>
    </Popup>
  ) : (
    <div
      style={{
        width: "calc(100% - 20px)",
        height: 31,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: editable ? theme.primary : "#444",
        fontSize: 13,
        borderRadius: 10,
        gap: 20,
        border: `1px solid grey`,
        color: theme.fontColor, // Added to ensure text is visible against the background
      }}
    >
      <div
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {(() => {
          const rolesString = Object.keys(formData.roles)
            .filter((role) => formData.roles[role]) // Filter roles that are true
            .join(", "); // Join with ", "
          return rolesString;
        })()}
      </div>
    </div>
  );
};

export default RoleInput;
