import { useMapEvents } from "react-leaflet";

export default function MapEvents({ setZoom }) {
  const map = useMapEvents({
    zoomend: () => {
      setZoom(map.getZoom());
    },
  });
  return null;
}
