import React, { useEffect, useState } from "react";
import { PiWavesBold } from "react-icons/pi";
import * as colorUtils from "utils/colorUtils";
import ShortSpread from "../ShortSpread";
import { FaChevronDown } from "react-icons/fa6";
import { useStepContext } from "@mui/material";
import { DateToString } from "utils/stringUtils";
import { abbreviatedCategoryToFull } from "utils/airQualityUtils";

export default function AirQualityForecast({ data }) {
  const [dropsOpened, setDropsOpened] = useState();
  const [avgQualities, setAvgQualities] = useState();

  useEffect(() => {
    if (!data) return;

    const dropsOpenList = data.map((item) => {
      return false;
    });

    const avgQualitiesList = data.map((item) => {
      return item.forecast_report[0].category;

      //all categories
      // const list = [
      //   { name: "Good", value: 0 },
      //   { name: "Other", value: 0 },
      // ];

      // //loop through and find the category with the greatest number
      // for (const category of item.forecast_report) {
      //   switch (category.category) {
      //     case "Good":
      //       list[0].value++;
      //       break;
      //     default:
      //       list[list.length - 1].value++; //always last
      //       break;
      //   }
      // }

      // list.sort((a, b) => {
      //   return b.value - a.value;
      // });

      // return list[0].name;
    });

    data.map((item) => {
      item.forecast_report.map((dataPoint) => {
        dataPoint.dateStr = DateToString(new Date(dataPoint.iso8601));
      });
    });

    setDropsOpened([...dropsOpenList]);
    setAvgQualities([...avgQualitiesList]);
  }, [data]);

  
  

  if (data && dropsOpened)
    return (
      <div style={{ overflowY: "auto" }}>
        {dropsOpened.map((opened, openedIndex) => {
          //if dropdown is open
          if (opened)
            return (
              <button
                className="scrollbar-hidden"
                key={openedIndex}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#436c98",
                  borderRadius: 5,
                  width: "100%",
                  padding: 15,
                  marginTop: 15,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  const temp = [...dropsOpened];
                  temp[openedIndex] = !temp[openedIndex];
                  setDropsOpened([...temp]);
                }}
              >
                {/* Header */}
                <div
                  style={{
                    width: "100%",
                    borderBottom: "solid grey 1px",
                    fontSize: 20,
                    marginBottom: 15,
                    textAlign: "left",
                  }}
                >
                  {data[openedIndex].air_station_name}
                </div>
                {/* columns */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {/* left column content */}
                  <div style={{ flex: 1, marginRight: 10 }}>
                    {data[openedIndex].forecast_report
                      .filter((item, index) => {
                        return index % 2 == 0;
                      })
                      .map((item, index) => {
                        return (
                          <ShortSpread
                            key={index}
                            header={`${item.dateStr.month} ${item.dateStr.day}, ${item.dateStr.year}`}
                            headerStyle={{ fontSize: 14 }}
                            data={{
                              Concentration: `${item.concentration}ppb`,
                              Category: abbreviatedCategoryToFull(
                                item.category
                              ),
                              "": item.description,
                            }}
                            icon={
                              <PiWavesBold
                                size={30}
                                color={colorUtils.GetQualityColor(
                                  item.category
                                )}
                              />
                            }
                            style={{
                              height: "25%",
                              width: "100%",
                              paddingBottom: 15,
                            }}
                          />
                        );
                      })}
                  </div>
                  {/* right column content */}
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    {data[openedIndex].forecast_report
                      .filter((item, index) => {
                        return index % 2 == 1;
                      })
                      .map((item, index) => {
                        return (
                          <ShortSpread
                            key={index}
                            header={`${item.dateStr.month} ${item.dateStr.day}, ${item.dateStr.year}`}
                            headerStyle={{ fontSize: 14 }}
                            data={{
                              Concentration: `${item.concentration}ppb`,
                              Category: abbreviatedCategoryToFull(
                                item.category
                              ),
                              "": item.description,
                            }}
                            icon={
                              <PiWavesBold
                                size={30}
                                color={colorUtils.GetQualityColor(
                                  item.category
                                )}
                              />
                            }
                            style={{
                              height: "25%",
                              width: "100%",
                              paddingBottom: 15,
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </button>
            );
          //if dropdown is closed
          else
            return (
              <button
                key={openedIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#436c98",
                  borderRadius: 5,
                  width: "100%",
                  padding: 15,
                  marginTop: 15,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  const temp = [...dropsOpened];
                  temp[openedIndex] = !temp[openedIndex];
                  setDropsOpened([...temp]);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 20 }}>
                    {data[openedIndex].air_station_name}
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <PiWavesBold
                      size={25}
                      color={colorUtils.GetQualityColor(
                        avgQualities[openedIndex]
                      )}
                    />
                  </div>
                </div>

                <div>
                  <FaChevronDown />
                </div>
              </button>
            );
        })}
      </div>
    );
}
