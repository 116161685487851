import React, { useContext } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { Slider } from "antd";
import { ColorContext } from "App";

export default function Controls({
  isPlaying,
  setIsPlaying,
  playbackSpeed,
  setPlaybackSpeed,
  frames,
  sliderValue,
  opacity,
  setOpacity,
  preview,
}) {
  
  const theme = useContext(ColorContext);
  const button = (speed) => ({
    marginRight: 5,
    backgroundColor: playbackSpeed === speed ? theme.primaryHighlight : "transparent",
    padding: 5,
    borderRadius: 5,
  });

  return (
    <div style={{ display: "flex", overflow: "auto" }}>
      <button
        style={{ marginRight: 10, alignSelf: "center" }}
        onClick={() => setIsPlaying(!isPlaying)}
      >
        {isPlaying ? <FaPause size={30} /> : <FaPlay size={30} />}
      </button>
      <button style={button(0.25)} onClick={() => setPlaybackSpeed(0.25)}>
        .25x
      </button>
      <button style={button(0.5)} onClick={() => setPlaybackSpeed(0.5)}>
        .5x
      </button>
      <button style={button(1)} onClick={() => setPlaybackSpeed(1)}>
        1x
      </button>
      <button style={button(1.5)} onClick={() => setPlaybackSpeed(1.5)}>
        1.5x
      </button>
      <button style={button(3)} onClick={() => setPlaybackSpeed(3)}>
        3x
      </button>
      <button style={button(5)} onClick={() => setPlaybackSpeed(5)}>
        5x
      </button>
      <button style={button(10)} onClick={() => setPlaybackSpeed(10)}>
        10x
      </button>
      {/* <h1
        style={{
          marginLeft: 10,
          alignSelf: "center",
          fontSize: preview ? 25 : 30,
        }}
      >
        {frames[sliderValue].toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </h1> */}
    </div>
  );
}
