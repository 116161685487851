import { useMap, useMapEvents } from "react-leaflet";

export default function ZoomOnFocusMapEvent({setInteracted}) {
  const map = useMap(); // Access the map instance

  useMapEvents({
    focus: () => {
      setInteracted(true); // Set interacted state to true
      map.scrollWheelZoom.enable(); // Enable zoom on focus
      map.touchZoom.enable(); // Enable touch zoom on focus, if needed
    },
    drag: () => {
      setInteracted(true); // Set interacted state to true
      map.scrollWheelZoom.enable(); // Enable zoom on focus
      map.touchZoom.enable(); // Enable touch zoom on focus, if needed
    },
    blur: () => {
      map.scrollWheelZoom.disable(); // Disable zoom on blur
      map.touchZoom.disable(); // Disable touch zoom on blur, if needed
    },
    mouseout: () => {
      map.scrollWheelZoom.disable(); // Disable zoom on blur
      map.touchZoom.disable(); // Disable touch zoom on blur, if needed
    },
  });

  return null;
}
