import { ColorContext } from "App";
import React, { useContext } from "react";

export default function TabsHeader({
  currentTabs,
  onTabChange,
  options,
  styles = {},
  rootStyle = {},
  // maxWidth = Infinity,
  depth = 0,
}) {
  const theme = useContext(ColorContext);
  const tabChanged = (index) => {
    const tabArr = [...currentTabs];
    tabArr[depth] = index;
    onTabChange(tabArr);
  };

  const rootStyleObj = {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: theme.base,
    color: theme.fontColor,
    alignItems: "center",
    justifyContent: "center",
    // maxWidth: maxWidth,
    minHeight: 32,
    paddingBottom: 12,
    fontSize: 14,
    ...rootStyle,
  };

  const innerStyle = {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: theme.base,
    color: theme.fontColor,
    alignItems: "center",
    justifyContent: "center",
    // maxWidth: maxWidth,
    // height: 56,
    fontSize: 14,
  };

  return (
    <div
      style={{
        ...(depth === 0 ? { ...rootStyleObj } : { ...innerStyle }),
      }}
    >
      <div
        style={{
          display: "flex",
          backgroundColor: theme.primary,
          alignItems: "center",
          // width: 1285,
          width: "100%",
          height: 31,
          ...styles[depth],
        }}
      >
        {options &&
          options.map((item, index) => {
            return (
              <button
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  height: 31,
                }}
                onClick={() => tabChanged(index)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    width: "100%",

                    height: 27,
                    borderBottom:
                      currentTabs[depth] == index
                        ? "rgba(255, 255, 255, 1) solid 3px"
                        : "rgba(255, 255, 255, 0) solid 3px",
                  }}
                >
                  {item.option}
                </div>
              </button>
            );
          })}
      </div>
      <div style={{ width: "100%" }}>
        {options[currentTabs[depth]]?.options && (
          <TabsHeader
            currentTabs={currentTabs}
            onTabChange={onTabChange}
            options={options[currentTabs[depth]].options}
            styles={styles}
            depth={depth + 1}
          />
        )}
      </div>
    </div>
  );
}
