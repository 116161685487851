import { ColorContext } from "App";
import React, { useContext } from "react";

export default function OvalDisplayHorizontal({
  header,
  content,
  style,
  headerStyle,
  contentStyle,
}) {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        backgroundColor: theme.primary,
        padding: 15,
        borderRadius: 15,
        ...style,
      }}
    >
      <div style={{ fontSize: 20, fontWeight: "bold", ...headerStyle }}>
        {header}
      </div>
      <div style={contentStyle}>{content}</div>
    </div>
  );
}
