import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import NWSAlertsList from "components/StormCenter/displays/NWSAlertsList";
import Cookies from "js-cookie";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { UniqueWidgetRefreshContext } from "App";

let dataCache;
 

export default function MyAdvisoriesWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const [localAlerts, setLocalAlerts] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getCWActiveAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    dataCache = null
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          
          const alertsSortedByTime = res.data.result.sort((a, b) => {
            return new Date(b.sentTime) - new Date(a.sentTime);
          });
          dataCache = alertsSortedByTime;
          setLocalAlerts(dataCache);
           
          // setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setLocalAlerts(dataCache);
        setFetching(false);
        return;
      }
      FusionData.get("getCWActiveAlerts")
        .then((res) => {
          
          const alertsSortedByTime = res.data.result.sort((a, b) => {
            return new Date(b.sentTime) - new Date(a.sentTime);
          });
          dataCache = alertsSortedByTime;
          setLocalAlerts(dataCache);
           
          setFetching(false);
        })
        .catch((err) => {
          
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"MyAdvisories"}
        dimensions={dimensions}
      />
    );

  if (localAlerts && localAlerts.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE}   realtime />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE}   realtime />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          <NWSAlertsList alerts={localAlerts} />
        </div>
      </div>
    );
  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE}   realtime />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          <NWSAlertsList alerts={localAlerts} />
        </div>
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE}   realtime />
        <div
          style={{
            height: "calc(100% - 20px)",
            overflowX: "hidden",
          }}
        >
          <NWSAlertsList alerts={localAlerts} />
        </div>
      </div>
    );
}
