let widgetRefresh = ""

export default function useDataRefresh() {

  const setWidgetRefresh = (val) => {
    widgetRefresh = val
  }

  return {
    widgetRefresh,
    setWidgetRefresh
  };
}
