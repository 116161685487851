import { ColorContext } from "App";
import React, { useContext, useEffect, useState } from "react";

export default function DeviceCustomInput({ onChange, value, dataMapItem }) {
  const theme = useContext(ColorContext);

  useEffect(() => {
    if (!value)
      switch (dataMapItem.type) {
        case "float":
          onChange(0.0);
          break;
        case "bool":
          onChange(false);
          break;
        default:
          onChange(null);
          break;
      }
  }, [dataMapItem.type]);

  switch (dataMapItem.type) {
    case "float":
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="number"
            step="any"
            style={{
              padding: 5,
              background: theme.base,
              borderRadius: 5,
              border: "1px solid grey",
              marginLeft: 10,
              width: 100,
            }}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value}
          />
          <div style={{ marginLeft: 5 }}>
            {dataMapItem.units ? dataMapItem.units : ""}
          </div>
        </div>
      );
    case "bool":
      return (
        <select
          style={{
            background: theme.base,
            padding: 5,
            width: 200,
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
        >
          {dataMapItem?.values && (
            <>
              <option value={true}>{dataMapItem?.values["true"]}</option>
              <option value={false}>{dataMapItem?.values["false"]}</option>
            </>
          )}
          {!dataMapItem?.values && (
            <>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </>
          )}
        </select>
      );
    default:
      return null;
  }
}
