import React from "react";
import { FaCheck } from "react-icons/fa";

const CheckboxCircle = ({ value, callback }) => {
  return (
    <div
      onClick={(e) => callback(e)}
      style={{
        // Custom styles for the div to look like a checkbox
        width: "30px",
        height: "30px",
        backgroundColor: value ? "white" : "transparent",
        border: "1px solid white",
        borderRadius: 1000,
        cursor: "pointer",
      }}
    >
      {value && (
        <FaCheck
          size={25}
          style={{
            color: "black",
            margin: "2px",
          }}
        />
      )}
      <input
        type="checkbox"
        checked={value || false}
        onChange={() => {}} // The actual checkbox is now just for formality
        style={{ opacity: 0 }} // Hide the checkbox
      />
    </div>
  );
};

export default CheckboxCircle;
