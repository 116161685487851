import React from "react";

import { IconContext } from "react-icons";
import ConditionIcons from "components/ConditionIcons";
import { temperatureColorScale } from "utils/colorUtils";

export default function CustomIcon({
  city_name,
  temperature,
  icon,
  icon_name,
}) {
  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "10px",
        textAlign: "center",
        color: temperatureColorScale(temperature),
        textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black'
      }}
    >
      {/* <h2 style={{ margin: 0, fontSize: "14px", color: "#333" }}>
        {city_name}
      </h2> */}
      {/* <p style={{ margin: 0, fontSize: "12px", color: "#666" }}>
        Temperature: {temperature}°F
      </p>  */}
      <p style={{ margin: 0, fontSize: "24px", fontWeight:"bold" }}>
        {temperature}°F
      </p>

      <IconContext.Provider value={{ size: 64, style:{
        textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black',
        stroke: 'black',
        strokeWidth: '.5px',
        // color: 'blue'
      }}}>
        <ConditionIcons conditionId={icon} />
      </IconContext.Provider>
    </div>
  );
}
