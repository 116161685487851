import { DateToString } from "./stringUtils";

export function ParseTideTables(data) {
  //map through outer most layer
  const result = data.map((item) => {
    return {
      tide_name: item.tide_name,

      //map through second layer
      forecast: item.forecast.map((forecast) => {
        return {
          dateStr: DateToString(new Date(forecast.iso8601)),

          //map through third layer
          set_one: forecast.tide.filter((item) => {
            return item.time.includes("AM");
          }), //define set 1
          set_two: forecast.tide.filter((item) => {
            return item.time.includes("PM");
          }), //define set 2
        };
      }),
    };
  });
}

export function ArrayToLinkedList(array) {
  let head = null;
  let curr = null;

  for (let i = 0; i < array.length; i++) {
    let newNode = { ...array[i], prev: curr, next: null };
    if (curr) curr.next = newNode;
    curr = newNode;
    if (!head) head = newNode;
  }

  return head;
}

export function LinkedListToArray(listHead) {
  let curr = listHead;
  const array = [];

  while (curr) {
    array.push(curr);
    curr = curr.next;
  }

  

  return array;
}
