import React, { useEffect, useState } from "react";
import SevereWeatherAlertsMap from "../../components/Maps/SevereWeatherAlertsMap/SevereWeatherAlertsMap";
import { useNavigate, useSearchParams } from "react-router-dom";
import MapForecast from "../../components/Maps/MapForecast/MapForecast";
import Doppler from "components/Maps/Doppler/Doppler";
import EarthquakeMap from "components/Maps/Earthquake/EarthquakeMap";
import HurricanesTropicalStorms from "components/StormCenter/HurricanesTropicalStorms";
import TabsHeader from "components/TabsHeader";
import { usePerms } from "hooks/usePerms";

function Tab({ tabId, currentTab, setTab, children }) {
  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: 31,
      }}
      onClick={() => setTab(tabId)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "95%",
          height: 27,
          borderBottom:
            currentTab === tabId
              ? "rgba(255, 255, 255, 1) solid 3px"
              : "rgba(255, 255, 255, 0) solid 3px",
        }}
      >
        {children}
      </div>
    </button>
  );
}

export default function Maps() {
  const [params, setParams] = useSearchParams();
  const [tabs, setTabs] = useState([0]);

  const perms = usePerms();
  const navigate = useNavigate();
  useEffect(() => {
    const initTab = [params.get("tab") ? parseInt(params.get("tab")) : 0];
    setTabs([...initTab]);

    (async () => {
      if (!(await perms.validatePermissions(["MapsEnabled"]))) {
        navigate("/404");
        return;
      }
    })();
  }, []);

  const RenderTabs = () => {
    switch (tabs[0]) {
      case 0:
        return <SevereWeatherAlertsMap fullScreen />;
      case 1:
        return <MapForecast fullScreen />;
      case 2:
        return <Doppler />;
      case 3:
        return <EarthquakeMap />;
      case 4:
        return <HurricanesTropicalStorms />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <TabsHeader
        onTabChange={(newTabArr) => setTabs([...newTabArr])}
        currentTabs={tabs}
        options={[
          { option: "Severe Weather Warnings" },
          { option: "Current Conditions" },
          { option: "Doppler" },
          { option: "Earthquake" },
          { option: "Hurricane" },
        ]}
        styles={[{ fontSize: 12 }]}
      />
      {RenderTabs()}
    </div>
  );
}
