import * as d3 from "d3";

export const alertColorScale = d3
  .scaleLinear()
  .domain([0, 66, 100])
  .range(["#CD2C2E", "#EE8918", "#34A100"]);

export function getSeverityLevel(value) {
  if (value >= 0 && value < 20) {
    return "Extreme";
  } else if (value >= 20 && value < 40) {
    return "Severe";
  } else if (value >= 40 && value < 80) {
    return "Moderate";
  } else if (value >= 80 && value <= 100) {
    return "Minor";
  } else {
    return "Invalid value";
  }
}
