export function validatePassword(password) {
  if (!password) return { isValid: false, message: 'Password is required.' };
  const minLength = 8;
  const hasNumbers = /\d/;
  const hasUpperCase = /[A-Z]/;
  const hasLowerCase = /[a-z]/;
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/;

  if (password.length < minLength) {
    return { isValid: false, message: 'Password must be at least 8 characters long.' };
  }
  if (!hasNumbers.test(password)) {
    return { isValid: false, message: 'Password must contain at least one number.' };
  }
  if (!hasUpperCase.test(password)) {
    return { isValid: false, message: 'Password must contain at least one uppercase letter.' };
  }
  if (!hasLowerCase.test(password)) {
    return { isValid: false, message: 'Password must contain at least one lowercase letter.' };
  }
  if (!hasSpecialChars.test(password)) {
    return { isValid: false, message: 'Password must contain at least one special character.' };
  }

  return { isValid: true, message: 'Password is valid.' };
}