import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

let alarms = []
let silencedAlarms = []
let heardAlarms = []

export default function useAlarms() {

  useEffect(() => {
    silencedAlarms = JSON.parse(Cookies.get('silencedAlarms') || "[]");
    heardAlarms = JSON.parse(Cookies.get('heardAlarms') || "[]");
  }, []);

  //When silenced alarms change, update the alarms with the new silenced status
  useEffect(() => {
    alarms.map((alarm) => {
      if (silencedAlarms.includes(alarm.id)) {
        alarm.silenced = true
      }
    })
  }, [silencedAlarms]);

  //When alarms change, cleanup the silenced and heard alarms
  useEffect(() => {
    cleanup()
  }, [alarms]);
  
  const get = () => {
    return alarms;
  }

  const set = (val) => {
    alarms = val;
  }

  const isSilenced = (id) => {
    return silencedAlarms.includes(id)
  }

  const isHeard = (id) => {
    return heardAlarms.includes(id)
  }

  const toggleSilence = (id) => {
    let silenced = null
    if (isSilenced(id)) {
      
      silencedAlarms = silencedAlarms.filter((item) => item !== id)
      silenced = false
    } else {
      
      silencedAlarms.push(id)
      silenced = true
    }
    let alarm = alarms.find((item) => item.id === id)
    alarm = { ...alarm, silenced: silenced }
    Cookies.set('silencedAlarms', JSON.stringify(silencedAlarms));
    return silenced
  }

  const setHeard = (id) => {
    if (isHeard(id)) {

    } else {
      heardAlarms.push(id)
    }
    let alarm = alarms.find((item) => item.id === id)
    alarm = { ...alarm, heard: true }
    Cookies.set('heardAlarms', JSON.stringify(heardAlarms));
    return true
  }
  const setUnHeard = (id) => {
    if (isHeard(id)) {
      heardAlarms = heardAlarms.filter((item) => item !== id)

    } else {

    }
    let alarm = alarms.find((item) => item.id === id)
    alarm = { ...alarm, heard: false }
    Cookies.set('heardAlarms', JSON.stringify(heardAlarms));
    return true
  }

  const cleanup = () => {
    if (!alarms || alarms.length === 0) return
    

    // if silencedalarms contains an id that is not in alarms, remove it
    silencedAlarms = silencedAlarms.filter((item) => alarms.find((alarm) => alarm.id === item))
    Cookies.set('silencedAlarms', JSON.stringify(silencedAlarms));
    heardAlarms = heardAlarms.filter((item) => alarms.find((alarm) => alarm.id === item))
    Cookies.set('heardAlarms', JSON.stringify(heardAlarms));

    
    
  }
  
  return {
    get: () => get(),
    set: (val) => set(val),
    toggleSilence: (id) => toggleSilence(id),
    isSilenced: (id) => isSilenced(id),
    setHeard: (id) => setHeard(id),
    setUnHeard: (id) => setUnHeard(id),
    isHeard: (id) => isHeard(id),
  };
}
