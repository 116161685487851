import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function Apparatus() {
  
  
  return ("Your Organization's License Agreement does not yet include access to this feature.  Please contact your Administrator to request access."
  
  )
}
