import React, { useContext, useEffect, useState } from "react";
import TabsHeader from "components/TabsHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as api from "apis/FusionAPI";
import LineChart from "components/Charts/LineChart";
import { DateToString } from "utils/stringUtils";
import BarChart from "components/Charts/BarChart";
import MixedChart from "components/Charts/MixedChart";
import * as chartUtils from "utils/ChartUtils";
import { borderColor } from "@mui/system";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import useDataRefresh from "hooks/useDataRefresh";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import Widget from "components/Widget";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";

const daySegmentSort = {
  Morning: 0,
  Afternoon: 1,
  Evening: 2,
  Night: 3,
};

export default function Charts() {
  const [params, setParams] = useSearchParams();
  const [tabs, setTabs] = useState([0, 0]);

  const [detailedData, setDetailedData] = useState();
  const [day7Data, setDay7Data] = useState();
  const [day15Data, setDay15Data] = useState();

  const [precipData, setPrecipData] = useState();
  const [cloudCoverData, setCloudCoverData] = useState();
  const [comfortLevelData, setComfortLevelData] = useState();
  const [dewPointData, setDewPointData] = useState();
  const [humidityData, setHumidityData] = useState();
  const [pressureData, setPressureData] = useState();
  const [precipitationData, setPrecipitationData] = useState();
  const [temperatureData, setTemperatureData] = useState();
  const [windSpeedChart, setWindSpeedChart] = useState();

  const FusionData = useAPIData();

  const navigate = useNavigate();
  const perms = usePerms();
  const dataRefresh = useDataRefresh();

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "Charts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  useEffect(() => {
    const initTab = [
      params.get("tab") ? parseInt(params.get("tab")) : 0,
      params.get("subtab") ? parseInt(params.get("subtab")) : 0,
    ];
    setTabs([...initTab]);
  }, []);
  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["ChartsEnabled"]))) {
        navigate("/404");
        return;
      }
    })();

    //get data
    FusionData.get("get15DayForecast").then((res) => {
      const data = {
        precip: [],
        cloudCover: [],
        comfortLevel: [],
        dewPoint: [],
        humidity: [],
        pressure: [],
        precipitation: [],
        temperature: {
          //temperature is special because it needs 3 lines
          labels: [],
          datasets: [
            {
              label: "High",
              data: [],
              borderColor: "red",
            },
            {
              label: "Mean",
              data: [],
              borderColor: "yellow",
            },
            {
              label: "Low",
              data: [],
              borderColor: "blue",
            },
          ],
        },
        windSpeed: [],
      };

      //missing:
      //  cloud cover

      res.data.result.map((item) => {
        const dateStr = DateToString(new Date(item.iso8601));

        data.precip.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.precip_prob,
        });

        data.comfortLevel.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.comfort,
        });

        data.dewPoint.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.dew_point,
        });

        data.humidity.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.humidity,
        });

        data.pressure.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.baro_pressure,
        });

        data.precipitation.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.rainfall,
        });

        //temperature
        data.temperature.labels.push(
          `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`
        );
        data.temperature.datasets[0].data.push(item.high_temp);
        data.temperature.datasets[1].data.push(
          (parseFloat(item.high_temp) + parseFloat(item.low_temp)) / 2
        );
        data.temperature.datasets[2].data.push(item.low_temp);

        data.windSpeed.push({
          label: `${dateStr.weekday_short}, ${dateStr.day_short} ${dateStr.month_short}`,
          dataPoint: item.wind_speed,
        });
      });

      

      setPrecipData({
        dataSets: {
          labels: data.precip.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Chance of Precip.",
              data: data.precip.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#98ff18",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Chance of Precip. (%)",
          },
        },
      });
      setComfortLevelData({
        dataSets: {
          labels: data.comfortLevel.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Comfort Level",
              data: data.comfortLevel.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#800080",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Comfort Temperature (°F)",
          },
        },
      });
      setDewPointData({
        dataSets: {
          labels: data.dewPoint.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Dew Point",
              data: data.dewPoint.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#07fd08",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Dew Point (°F)",
          },
        },
      });
      setHumidityData({
        dataSets: {
          labels: data.humidity.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Humidity",
              data: data.humidity.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#07fd08",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Relative Humidity (%)",
          },
        },
      });
      setPressureData({
        dataSets: {
          labels: data.pressure.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Pressure",
              data: data.pressure.map((item) => {
                return item.dataPoint;
              }),
              borderColor: "#000000",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: 'Pressure (")',
          },
        },
      });
      setPrecipitationData({
        dataSets: {
          labels: data.precipitation.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "bar",
              label: "Precipitation",
              data: data.precipitation.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#0304e9",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Rainfall (in.)",
          },
        },
      });
      setTemperatureData({
        dataSets: {
          labels: data.temperature.labels.map((item) => {
            return item;
          }),
          datasets: [
            {
              type: "line",
              label: "High",
              data: data.temperature.datasets[0].data.map((item) => {
                return item;
              }),
              borderColor: "red",
            },
            {
              type: "line",
              label: "Average",
              data: data.temperature.datasets[1].data.map((item) => {
                return item;
              }),
              borderColor: "yellow",
            },
            {
              type: "line",
              label: "Low",
              data: data.temperature.datasets[2].data.map((item) => {
                return item;
              }),
              borderColor: "blue",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Temperature (°F)",
          },
        },
      });
      setWindSpeedChart({
        dataSets: {
          labels: data.pressure.map((item) => {
            return item.label;
          }),
          datasets: [
            {
              type: "line",
              label: "Wind Speed",
              borderColor: "#2129d6",
              data: data.pressure.map((item) => {
                return item.dataPoint;
              }),
              backgroundColor: "#2129d680",
              fill: true,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          title: {
            display: true,
            text: "Wind Speed (MPH)",
          },
        },
      });

      //chartjs object for mixed chart
      setDay15Data(chartUtils.Generate15DayChart(res.data.result));
    });

    // DETAILED FORECAST ------------------------------------------------------------------------------
    FusionData.get("getDetailedForecastData").then((res) => {
      if (!res?.data?.result) return;

      res = res.data.result;

      //chartjs object for mixed chart
      setDetailedData(chartUtils.GenerateDetailedChart(res));
    });

    // 7 DAY ------------------------------------------------------------------------------
    FusionData.get("day7HourlyForecast")
      .then((res) => {
        res = res.data.result;

        
        setDay7Data(chartUtils.Generate7DayChart(res, {}, 4, 4));
      })
      .catch((e) => {
        
      });
  }, [dataRefresh.widgetRefresh, uwf[UNIQUEKEY]]);

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        if (!detailedData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={detailedData.dataSets}
            options={detailedData.options}
            plugins={detailedData.plugins}
          />
        );
      case 1:
        if (!day7Data) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={day7Data.dataSets}
            options={day7Data.options}
            plugins={day7Data.plugins}
          />
        );

      case 2:
        if (!day15Data) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={day15Data.dataSets}
            options={day15Data.options}
            plugins={day15Data.plugins}
          />
        );

      //precipitation chance
      case 3:
        if (!precipData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={precipData.dataSets}
            options={precipData.options}
          />
        );

      //cloud cover
      // case 4:
      //   if (!cloudCoverData) break;
      //   return (
      //     <BarChart
      //       data={cloudCoverData}
      //       options={{
      //         datasetOptions: {
      //           label: "Cloud Cover",
      //           backgroundColor: "#808080",
      //         },
      //         pluginOptions: {
      //           title: {
      //             display: true,
      //             text: "Cloud Cover (%)",
      //           },
      //         },
      //       }}
      //     />
      //   );

      //comfort temperature
      case 4:
        if (!comfortLevelData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"line"}
            dataSets={comfortLevelData.dataSets}
            options={comfortLevelData.options}
          />
        );

      //dew point
      case 5:
        if (!dewPointData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"line"}
            dataSets={dewPointData.dataSets}
            options={dewPointData.options}
          />
        );

      //humidity
      case 6:
        if (!humidityData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={humidityData.dataSets}
            options={humidityData.options}
          />
        );

      //pressure
      case 7:
        if (!pressureData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"line"}
            dataSets={pressureData.dataSets}
            options={pressureData.options}
          />
        );

      //precipitation
      case 8:
        if (!precipitationData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"bar"}
            dataSets={precipitationData.dataSets}
            options={precipitationData.options}
          />
        );

      //temperature
      case 9:
        if (!temperatureData) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"line"}
            dataSets={temperatureData.dataSets}
            options={temperatureData.options}
          />
        );

      //wind speed
      case 10:
        if (!windSpeedChart) return <WidgetLoadingAnimation/>;
        return (
          <MixedChart
            type={"line"}
            dataSets={windSpeedChart.dataSets}
            options={windSpeedChart.options}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div
      className="page-root"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // backgroundColor: "blue",
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => setTabs([...newTabArr])}
        currentTabs={tabs}
        options={[
          { option: "6-Day Detailed Forecast" },
          { option: "7-Day Hourly Forecast" },
          { option: "15-Day Forecast" },

          { option: "Chance of Precipitation" },
          // { option: "Cloud Cover" },
          { option: "Comfort Level" },

          { option: "Dew Point" },
          { option: "Humidity" },
          { option: "Pressure" },

          { option: "Precipitation" },
          { option: "Temperature" },
          { option: "Wind Speed" },
        ]}
        styles={[{ fontSize: 12 }]}
      />

      {/* content */}
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // backgroundColor: "green",
          // padding: 15,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} dataUpdateOnly addMargin/>
        {RenderTab()}
      </div>
    </div>
  );
}
