import { updateUserWidgetLayout } from "apis/FusionAPI";
import { ColorContext } from "App";
import React, { useContext } from "react";
import Popup from "reactjs-popup";

const WidgetSelectorThumbnail = ({ widget, i, j, k, setSizeSelector }) => {
  const maxChars = 12;
  const theme = useContext(ColorContext);
  return (
    <div
      key={(i + 1) * 100 + (j + 1) * 10 + (k + 1)}
      style={{
        marginLeft: 7,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 150,
        height: 100,
        borderRadius: 10,
        background: theme.base,
        userSelect: "none",
      }}
      onClick={() => setSizeSelector([i, j, k])}
    >
      <div
        style={{
          height: 30,
          marginBottom: 10,
        }}
      >
        {widget.icon && widget.icon()}
      </div>
      <Popup
        onhover
        trigger={
          <div
            style={{
              background: theme.primaryShadow,
              borderRadius: 10,
              padding: 5,
              width: 120,
              // height: 40,
              textAlign: "center",
              // textWrap: "balance",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {widget.displayName.length > maxChars + 3
              ? widget.displayName.slice(0, maxChars)
              : widget.displayName}
            {widget.displayName.length > maxChars + 3 && "..."}
          </div>
        }
        position="top center"
        on={["hover", "focus"]}
      >
        <div
          style={{
            background: "white",
            color: "black",
            borderRadius: 10,
            padding: 5,
            width: 120,
            // height: 40,
            textAlign: "center",
            // textWrap: "balance",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {widget.displayName}
        </div>
      </Popup>
    </div>
  );
};

export default WidgetSelectorThumbnail;
