import { ColorContext } from "App";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { usePerms } from "hooks/usePerms";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "apis/FusionAPI";
import { capitalizeWord } from "utils/stringUtils";
import TextToggle from "components/UI/TextToggle";
import {
  FaArrowDown,
  FaArrowUp,
  FaSort,
  FaSortDown,
  FaSortUp,
} from "react-icons/fa";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
export default function AIAnalytics() {
  const perms = usePerms();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [analytics, setAnalytics] = useState([]);
  const [lastSortedType, setLastSortedType] = useState("");
  const [sortAmount, setSortAmount] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    (async () => {
      // if (!(await perms.validatePermissions(["AIAnalytics"]))) {
      //   navigate("/404");
      //   return;
      // }

      api.getAIAnalytics().then((res) => {
        setAnalytics(res.data.result);
        setFetching(false);
      });
    })();
  }, []);
  const theme = useContext(ColorContext);
  const sortData = (key) => {
    let newSortAmount = 0;
    if (lastSortedType === key) {
      newSortAmount = sortAmount + 1;
    }

    setLastSortedType(key);
    setSortAmount(newSortAmount);

    const sortedAnalytics = [...analytics].sort((a, b) => {
      if (newSortAmount % 2 === 0) {
        return a[key] < b[key] ? 1 : -1;
      } else {
        return a[key] > b[key] ? 1 : -1;
      }
    });

    setAnalytics(sortedAnalytics);
  };
  if (fetching) {
    return <WidgetLoadingAnimation />;
  }
  if (
    // window.origin.toLowerCase().includes("localhost") ||
    window.origin.toLowerCase().includes("comal") ||
    window.origin.toLowerCase().includes("pittsburgh")
  ) {
    return "This feature is still being optimized for your organization and will be available soon. Please contact your Administrator for any further details or updates.";
  }

  const TitleHeader = ({ sortKey, title }) => {
    return (
      <div
        onClick={() => {
          sortData(sortKey);
        }}
      >
        {title}{" "}
        {lastSortedType !== sortKey ? (
          <FaSort />
        ) : sortAmount % 2 === 0 ? (
          <FaSortDown />
        ) : (
          <FaSortUp />
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div style={{ display: "flex", height: "calc(100% - 80px)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            // backgroundColor: "purple",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: 24 }}>Instances</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "fit-content",
                  padding: 10,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                <input
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                  placeholder="Search"
                />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: ".75fr 1fr .75fr 1.5fr 1fr 1.5fr 1.5fr 1fr",
                marginTop: 20,
                color: theme.fontColor,
                fontWeight: "bold",
                borderBottom: "1px solid grey",
                padding: 10,
              }}
            >
              <TitleHeader sortKey={"alertId"} title="Alert Id" />
              <TitleHeader sortKey={"severity"} title="Severity" />
              <TitleHeader sortKey={"score"} title="Score" />
              <TitleHeader sortKey={"type"} title="Type" />
              <TitleHeader sortKey={"location"} title="Location" />
              <TitleHeader sortKey={"timestamp"} title="Time" />
              <TitleHeader sortKey={"status"} title="Status" />
              <div></div>
            </div>
          </div>
          {/* TOP */}
          <div
            style={{
              overflow: "auto",
            }}
          >
            {analytics.length > 0 &&
              analytics.filter((item) => {
                return (
                  item.alertId.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.severity.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.score.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.type.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.location.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.timestamp.toString().toLowerCase().includes(searchFilter.toLowerCase()) ||
                  item.status.toString().toLowerCase().includes(searchFilter.toLowerCase())
                );
              }).map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "grid",

                    gridTemplateColumns:
                      ".75fr 1fr .75fr 1.5fr 1fr 1.5fr 1.5fr 1fr",
                    marginTop: 10,
                    borderBottom: "1px solid grey",
                    padding: 10,
                  }}
                >
                  <div>{item.alertId}</div>
                  <div
                    style={{
                      width: 150,
                      display: "flex",
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 20,
                        height: 20,
                        width: 20,
                        border: "1px solid white",
                        background: alertColorScale(item.severity),
                      }}
                    ></div>
                    {getSeverityLevel(item.severity)}
                  </div>
                  <div
                    style={{
                      width: 50,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.score}%{" "}
                    {item.scoreDiff === 0 ? (
                      <div></div>
                    ) : item.scoreDiff > 0 ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )}
                  </div>
                  <div>{item.type}</div>
                  <div>{item.location}</div>
                  <div>{new Date(item.timestamp).toLocaleString()}</div>

                  <div>
                    <TextToggle
                      options={["Open", "Close"]}
                      value={item.status}
                      setValue={(value) => {
                        const newAnalytics = [...analytics];
                        newAnalytics[index].status = value;
                        setAnalytics(newAnalytics);
                      }}
                      optionColorOn={theme.primaryHighlight}
                      optionTextColorOn={"white"}
                    />
                  </div>
                  <div style={{
                    cursor:'pointer'
                  }}
                    onClick={(e) => {
                      navigate(`/publicuser/createalert`);
                    }}
                  >Create Alert</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
