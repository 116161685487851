import React, { useEffect,useContext } from "react";
import SevereWeatherAlertsMap from "components/Maps/SevereWeatherAlertsMap/SevereWeatherAlertsMap";

import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { SetUniqueWidgetRefreshContext, UniqueWidgetRefreshContext } from "App";

export default function SevereWeatherAlerts({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
  preview,
  sidebarOpen,
}) {
  const uwf = useContext(UniqueWidgetRefreshContext);
  const setUwf = useContext(SetUniqueWidgetRefreshContext);
  return (
    <div style={{width: "100%", height: "100%"}}>
      {/* <WidgetTimeUpdate realtime /> */}

      <SevereWeatherAlertsMap
        widgetRefresh={widgetRefresh}
        setWidgetRefresh={setWidgetRefresh}
        sidebarOpen={sidebarOpen}
        dimensions={dimensions}
        preview={preview}
      />
    </div>
  );
}
