import React, { useState } from 'react'
import { IconContext } from 'react-icons';

import { IoInformationCircle } from "react-icons/io5";

export default function AccessibilitySettings() {
  const [values, setValues] = useState([
    false,  //monochrome
    false,  //high-contrast
    false,  //highlight-links
    false,  //increase-font-size
  ]);

  const OptionSwitch = ({ text, optionIndex }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 15,
        }}
      >
        <div style={{ marginRight: 30, width: 150 }}>
          {text}
        </div>
        <button
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 50,
            backgroundColor: 'grey',
            borderRadius: 5,
            marginRight: 30
          }}
          onClick={() => {
            const temp = values.map((item, index) => {
              if (index === optionIndex) return !item;
              else return item;
            });
            setValues([...temp]);
          }}
        >
          <div
            style={{
              width: 25,
              height: 25,
              backgroundColor: values[optionIndex] ? 'lightgrey' : 'grey',
              borderRadius: 5
            }}
          />
          <div
            style={{
              width: 25,
              height: 25,
              backgroundColor: values[optionIndex] ? 'grey' : 'lightgrey',
              borderRadius: 5
            }}
          />
        </button>
        <div>
          {values[optionIndex] ? "Yes" : "No"}
        </div>
      </div>
    );
  }

  return (
    <div style={{ userSelect: 'none', display: 'flex', width: "100%", height: "100%" }}>
      <div
        style={{
          // backgroundColor: '#1f2528',
          // width: 1075,
          // height: 575,
          padding: 15,
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#1f2528',
          // width: 1000,
          // height: 500,
          padding: 30,
          border: 'solid white 1px',
        }}>
          {/* overlay text */}
          <div
            style={{
              position: 'absolute',
              backgroundColor: '#1f2528',
              marginTop: -42.5,
              zIndex: 10,
              textWrap: 'nowrap',
            }}
          >
            ACCESSIBILITY SETTINGS
          </div>
          {/* contents */}
          <div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              // width: 1000,
              height: 30,
              backgroundColor: '#dae4f0',
              color: 'black',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
              <div
                style={{
                  marginTop: 5,
                  marginLeft: 15,
                  marginRight: 10,
                }}
              >
                <IconContext.Provider
                  value={{
                    color: 'black',
                    size: 20,
                  }}
                >
                  <IoInformationCircle />
                </IconContext.Provider>
              </div>
              <div>
                These settings apply per user
              </div>
            </div>
            <OptionSwitch text={"Monochrome"} optionIndex={0} />
            <OptionSwitch text={"High Contrast"} optionIndex={1} />
            <OptionSwitch text={"Highlight Links"} optionIndex={2} />
            <OptionSwitch text={"Increase Font Size"} optionIndex={3} />
          </div>
        </div>
      </div>
    </div>
  )
}
