import React, { useState, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
  useMapEvents,
  Polygon,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { latLng } from "leaflet";
import "./MoveablePoints.css"; // Import custom CSS for styling
import { alertColorScale } from "utils/alertUtils";

// Create custom "X" icon for alertData.coordinates
const xIcon = new L.DivIcon({
  className: "custom-x-icon",
  html: '<div style="color: red; font-size: 24px;">X</div>',
  iconSize: [20, 20],
  iconAnchor: [10, 18],
});

function DraggableMarker({ position, onMarkerDragEnd, markerIndex }) {
  const eventHandlers = {
    dragend: (e) => {
      const newPosition = e.target.getLatLng();
      onMarkerDragEnd(markerIndex, newPosition);
    },
  };

  return (
    <Marker
      position={position}
      draggable={true}
      eventHandlers={eventHandlers}
    />
  );
}

// MapEvents Component to handle map interactions
function MapEvents({ addMarker }) {
  useMapEvents({
    click: (e) => {
      addMarker(e.latlng); // Pass the latlng of the click to the addMarker function
    },
  });
  return null;
}

function MoveablePoints({
  mapCenter,
  zoom,
  alertData,
  setAlertData,
  isMobile,
  bounds,
  setGetMapCenter,
  jurisdictions,
  widget,
}) {
  // Function to calculate distance between two points
  const calculateDistance = (latlng1, latlng2) => {
    const latDiff = latlng2.lat - latlng1.lat;
    const lngDiff = latlng2.lng - latlng1.lng;
    return Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
  };

  // Function to add a marker based on proximity to the head or tail of the list
  const addMarker = useCallback(
    (latlng) => {
      if (alertData.coordinates.length === 0) {
        // If there are no alertData.coordinates, just add it as the first marker
        setAlertData((p) => {
          return {
            ...p,
            coordinates: [latlng],
            lastInserted: [latlng],
          };
        });
      } else {
        const head = alertData.coordinates[0];
        const tail = alertData.coordinates[alertData.coordinates.length - 1];

        const distanceToHead = calculateDistance(latlng, head);
        const distanceToTail = calculateDistance(latlng, tail);

        if (distanceToHead < distanceToTail) {
          // Add new marker at the head (start of the list)
          setAlertData((p) => {
            return {
              ...p,
              coordinates: [latlng, ...p.coordinates],
              lastInserted: [...p.lastInserted, latlng],
            };
          });
        } else {
          // Add new marker at the tail (end of the list)
          setAlertData((p) => {
            return {
              ...p,
              coordinates: [...p.coordinates, latlng],
              lastInserted: [...p.lastInserted, latlng],
            };
          });
        }
      }
    },
    [alertData.coordinates]
  );

  // Function to update the marker's position after dragging
  const handleMarkerDragEnd = useCallback((index, newPosition) => {
    setAlertData((p) => {
      return {
        ...p,
        coordinates: p.coordinates.map((marker, i) =>
          i === index ? newPosition : marker
        ),
        lastInserted: p.lastInserted.map((inserted) =>
          inserted === p.coordinates[index] ? newPosition : inserted
        ),
      };
    });
  }, []);

  const styles = {
    mapStyle: {
      height: widget ? "430px" : "100%",
      width: widget ? "480px" : "100%",
      borderRadius: 5,
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  return (
    <MapContainer center={mapCenter} zoom={zoom} style={styles.mapStyle}>
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        noWrap={true}
      />

      {/* Handle map click events */}
      <MapEvents addMarker={addMarker} />

      {/* Polyline connecting all alertData.coordinates in red */}
      {/* {alertData.coordinates.length > 1 && (
        <Polyline positions={[...alertData.coordinates, alertData.coordinates[0]]} color="red" />
      )} */}

      <Polygon
        pathOptions={{ color: alertColorScale(alertData.severity) }}
        positions={alertData.coordinates}
      />

      {/* Render a draggable marker for each point */}
      {alertData?.coordinates &&
        alertData.coordinates.map((position, index) => (
          <DraggableMarker
            key={index}
            position={position}
            markerIndex={index}
            onMarkerDragEnd={handleMarkerDragEnd}
          />
        ))}

      {jurisdictions &&
        jurisdictions.map((jurisdiction, index) => {
          return (
            <Polygon
              key={index}
              positions={JSON.parse(jurisdiction.original_coordinates)[0].map(
                ([longitude, latitude]) => [latitude, longitude]
              )}
              color="purple"
              fillOpacity={0}
            />
          );
        })}
    </MapContainer>
  );
}

export default MoveablePoints;
