import React, { createContext, useContext, useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import ConnectedSystems from "./ConnectedSystems";
import Devices from "./Devices";
import * as api from "apis/FusionAPI";
import { ColorContext } from "App";
import DeviceGroups from "./DeviceGroups";
import { usePerms } from "hooks/usePerms";
import RescAlertDevicesWidget from "./RescAlertDevicesWidget";

export const ConnectionsContext = createContext({});

export default function Connections({ widget = false, widgetParams }) {
  const [tabs, setTabs] = useState([1, 0]);
  const [integrations, setIntegrations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [licenseCounts, setLicenseCounts] = useState({});

  const [fetching, setFetching] = useState(true);

  const theme = useContext(ColorContext);
  const perms = usePerms();

  const getDeviceData = async (startTime, endTime, initList) => {
    const deviceList = initList ? initList : devices;

    let devicesResult = await api.getNodeDataByTimestamps(
      startTime ? startTime : new Date().getTime() - 24 * 60 * 60 * 1000,
      endTime ? endTime : new Date().getTime()
    );
    devicesResult = devicesResult.data.result;
    devicesResult = deviceList.map((device) => {
      return {
        ...device,
        data: devicesResult.filter((f) => f[0].node_id == device.node_id)[0],
      };
    });

    setDevices(devicesResult);
  };

  const getDevices = async () => {
    const res = await api.fusionGetIntegrations(); //get me system with a list of devices on that system
    setIntegrations(res.data.result);
    const res2 = await api.getDeviceGroups(); //get me groups with a list of devices in them
    setDeviceGroups(res2.data.result);
    const res3 = await api.getConnectedDevices();
    // setDevices(res3.data.result);  //re-enable this if you are going to decouple the getDevices and getDeviceDataByTimestamp functionality
    setLicenseCounts(res3.data.subCounts[0]);
    await getDeviceData(null, null, res3.data.result);
    setFetching(false);
  };

  useEffect(() => {
    getDevices();
    // getDeviceData();
  }, []);

  const [visibleDeviceTypes, setVisibleDeviceTypes] = useState({
    "Flood": true,
    "Manual Road Closure": true,
    "Technology Bridge": true,
    "Giant Voice Siren": true,
  });

  const canViewDeviceType = (device) => {
    //REPLACE _ACCESS WITH PERMS
    if (device.device_type === "Flood")
      return (
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["ViewAWAREFloodConnections"]
        ) && visibleDeviceTypes["Flood"]
      );
    else if (device.device_type === "Manual Road Closure")
      return (
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["ViewRescAlertDevicesManualRoadClosureConnections"]
        ) && visibleDeviceTypes["Manual Road Closure"]
      );
    else if (device.device_type === "Technology Bridge")
      return (
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["ViewRescAlertDevicesTechnologyBridgeConnections"]
        ) && visibleDeviceTypes["Technology Bridge"]
      );
    else if (device.device_type === "Giant Voice Siren")
      return (
        perms.testPermission(
          [8, 10, 11, 12, 13],
          ["ViewFederalSignalGiantVoiceSirenConnections"]
        ) && visibleDeviceTypes["Giant Voice Siren"]
      );
    else return true;
  };

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return <ConnectedSystems />;
      case 1:
        return <Devices />;
      case 2:
        return <DeviceGroups />;
      default:
        return <div />;
    }
  };

  if (widget) {
    if (widgetParams.widgetType === "map" || widgetParams.widgetType === "graph") {
      return (
        <ConnectionsContext.Provider
          value={{
            integrations,
            setIntegrations,
            devices,
            setDevices,
            deviceGroups,
            setDeviceGroups,
            fetching,
            setFetching,
            getDeviceData,
            getDevices,
            licenseCounts,
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <Devices
            sidebarOpen={widgetParams.sidebarOpen}
            widgetType={widgetParams.widgetType}
            dimensions={widgetParams.dimensions}
            preview={widgetParams.preview}
            widget
          />
        </ConnectionsContext.Provider>
      );
    }
    if (widgetParams.widgetType === "technologyBridge") {
      return (
        <ConnectionsContext.Provider
          value={{
            integrations,
            setIntegrations,
            devices,
            setDevices,
            deviceGroups,
            setDeviceGroups,
            fetching,
            setFetching,
            getDeviceData,
            getDevices,
            licenseCounts,
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <RescAlertDevicesWidget
            type="technologyBridge"
            sidebarOpen={widgetParams.sidebarOpen}
            dimensions={widgetParams.dimensions}
          />
        </ConnectionsContext.Provider>
      );
    }
    if (widgetParams.widgetType === "manualRoadClosure") {
      return (
        <ConnectionsContext.Provider
          value={{
            integrations,
            setIntegrations,
            devices,
            setDevices,
            deviceGroups,
            setDeviceGroups,
            fetching,
            setFetching,
            getDeviceData,
            getDevices,
            licenseCounts,
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          <RescAlertDevicesWidget
            type="manualRoadClosure"
            sidebarOpen={widgetParams.sidebarOpen}
            dimensions={widgetParams.dimensions}
          />
        </ConnectionsContext.Provider>
      );
    }
  }

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => {
          setTabs([...newTabArr]);
        }}
        currentTabs={tabs}
        options={[
          { option: "Connected Systems" },
          { option: "Connected Devices" },
          { option: "Connected Device Groups" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      <div
        style={{
          height: "calc(100% - 44px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ConnectionsContext.Provider
          value={{
            integrations,
            setIntegrations,
            devices,
            setDevices,
            deviceGroups,
            setDeviceGroups,
            fetching,
            setFetching,
            getDeviceData,
            getDevices,
            licenseCounts,
            canViewDeviceType,
            visibleDeviceTypes,
            setVisibleDeviceTypes,
          }}
        >
          {RenderTab()}
        </ConnectionsContext.Provider>
      </div>
    </div>
  );
}
