import React from "react";

// import MciIcon from "@expo/vector-icons/MaterialCommunityIcons";
// import Fa5Icon from "@expo/vector-icons/FontAwesome5";
// import FaIcon from "@expo/vector-icons/FontAwesome";
// import FoundIcon from "@expo/vector-icons/Foundation";
// import IonIcon from "@expo/vector-icons/Ionicons";

import { FaWater } from "react-icons/fa";
import { FaHurricane } from "react-icons/fa6";
import { FaHouseDamage } from "react-icons/fa";
import { FiWind } from "react-icons/fi";
import { FaFireAlt } from "react-icons/fa";
import { IoThunderstorm } from "react-icons/io5";
import { RiAlertFill } from "react-icons/ri";
import { MdOutlineSevereCold } from "react-icons/md";
import { FaSailboat } from "react-icons/fa6";
import { MdVolcano } from "react-icons/md";
import { FaTornado } from "react-icons/fa6";
import { FaChild } from "react-icons/fa";
import { FaBiohazard } from "react-icons/fa";
import { BsFillCloudFog2Fill } from "react-icons/bs";

const airAndWindWarnings = [
  "Air Quality Alert",
  "Air Stagnation Advisory",
  "Blowing Dust Advisory",
  "Brisk Wind Advisory",
  "Blowing Dust Warning",
  "Dust Advisory",
  "Dust Storm Warning",
  "Extreme Wind Warning",
  "Gale Warning",
  "Gale Watch",
  "High Wind Warning",
  "High Wind Watch",
  "Lake Wind Advisory",
  "Wind Advisory",
];

const floodWarnings = [
  "Arroyo and Small Stream Flood Advisory",
  "Coastal Flood Advisory",
  "Coastal Flood Statement",
  "Coastal Flood Warning",
  "Coastal Flood Watch",
  "Flash Flood Statement",
  "Flash Flood Warning",
  "Flash Flood Watch",
  "Flood Advisory",
  "Flood Statement",
  "Flood Warning",
  "Flood Watch",
  "High Surf Advisory",
  "High Surf Warning",
  "Hydrologic Advisory",
  "Hydrologic Outlook",
  "Lakeshore Flood Advisory",
  "Lakeshore Flood Statement",
  "Lakeshore Flood Warning",
  "Lakeshore Flood Watch",
  "Low Water Advisory",
  "Rip Current Statement",
  "Storm Surge Warning",
  "Storm Surge Watch",
  "Storm Warning",
  "Storm Watch",
  "Small Stream Flood Advisory",
  "Tsunami Advisory",
  "Tsunami Warning",
  "Tsunami Watch",
  "Urban and Small Stream Flood Advisory",
];

const heatAndFireWarnings = [
  "Ashfall Advisory",
  "Ashfall Warning",
  "Excessive Heat Warning",
  "Excessive Heat Watch",
  "Extreme Fire Danger",
  "Fire Warning",
  "Fire Weather Watch",
  "Heat Advisory",
  "Red Flag Warning",
];

const coldWarnings = [
  "Avalanche Advisory",
  "Avalanche Warning",
  "Avalanche Watch",
  "Blizzard Warning",
  "Blizzard Watch",
  "Extreme Cold Warning",
  "Extreme Cold Watch",
  "Freeze Warning",
  "Freeze Watch",
  "Freezing Rain Advisory",
  "Freezing Spray Advisory",
  "Frost Advisory",
  "Hard Freeze Warning",
  "Hard Freeze Watch",
  "Heavy Freezing Spray Warning",
  "Heavy Freezing Spray Watch",
  "Ice Storm Warning",
  "Lake Effect Snow Advisory",
  "Lake Effect Snow Warning",
  "Lake Effect Snow Watch",
  "Snow Squall Warning",
  "Wind Chill Advisory",
  "Wind Chill Warning",
  "Wind Chill Watch",
  "Winter Storm Warning",
  "Winter Storm Watch",
  "Winter Weather Advisory",
];

const fogWarnings = [
  "Dense Fog Advisory",
  "Dense Smoke Advisory",
  "Freezing Fog Advisory",
];

const childAbductionWarnings = ["Child Abduction Emergency"];

const earthquakeWarnings = ["Earthquake Warning"];

const hazardousWarnings = [
  "Hazardous Materials Warning",
  "Hazardous Seas Warning",
  "Hazardous Seas Watch",
  "Hazardous Weather Outlook",
  "Nuclear Power Plant Warning",
  "Radiological Hazard Warning",
];

const hurricaneWarnings = [
  "Hurricane Force Wind Warning",
  "Hurricane Force Wind Watch",
  "Hurricane Local Statement",
  "Hurricane Warning",
  "Hurricane Watch",
  "Tropical Depression Local Statement",
  "Tropical Storm Local Statement",
  "Tropical Storm Warning",
  "Tropical Storm Watch",
];

const thunderstormWarnings = [
  "Severe Thunderstorm Warning",
  "Severe Thunderstorm Watch",
];

const boatWarnings = [
  "Marine Weather Statement",
  "Small Craft Advisory",
  "Small Craft Advisory For Hazardous Seas",
  "Small Craft Advisory For Rough Bar",
  "Small Craft Advisory For Winds",
  "Special Marine Warning",
];

const tornadoWarnings = ["Tornado Warning", "Tornado Watch"];

const volcanoWarnings = ["Volcano Warning"];

export default function IconSelector({ type }) {
  if (airAndWindWarnings.includes(type)) {
    return (
      <div>
        <FiWind size={28} color="white" />
      </div>
    );
  }
  if (floodWarnings.includes(type)) {
    return (
      <div>
        <FaWater size={28} color="white" />
      </div>
    );
  }
  if (heatAndFireWarnings.includes(type)) {
    return (
      <div>
        <FaFireAlt size={28} color="white" />
      </div>
    );
  }
  if (coldWarnings.includes(type)) {
    return (
      <div>
        <MdOutlineSevereCold size={28} color="white" />
      </div>
    );
  }
  if (fogWarnings.includes(type)) {
    return (
      <div>
        <BsFillCloudFog2Fill size={28} color="white" />
      </div>
    );
  }
  if (childAbductionWarnings.includes(type)) {
    return (
      <div>
        <FaChild size={28} color="white" />
      </div>
    );
  }
  if (earthquakeWarnings.includes(type)) {
    return (
      <div>
        <FaHouseDamage size={26} color="white" />
      </div>
    );
  }
  if (hazardousWarnings.includes(type)) {
    return (
      <div>
        <FaBiohazard size={28} color="white" />
      </div>
    );
  }
  if (hurricaneWarnings.includes(type)) {
    return (
      <div>
        <FaHurricane size={30} color="white" />
      </div>
    );
  }
  if (thunderstormWarnings.includes(type)) {
    return (
      <div>
        <IoThunderstorm size={28} color="white" />
      </div>
    );
  }
  if (boatWarnings.includes(type)) {
    return (
      <div>
        <FaSailboat size={28} color="white" />
      </div>
    );
  }
  if (tornadoWarnings.includes(type)) {
    return (
      <div>
        <FaTornado size={28} color="white" />
      </div>
    );
  }
  if (volcanoWarnings.includes(type)) {
    return (
      <div>
        <MdVolcano size={28} color="white" />
      </div>
    );
  }

  // If there are no icons related to the type of alert,
  // show this triangle icon with a "!" inside it
  return (
    <div>
      <RiAlertFill size={30} color="white" />
    </div>
  );
}

const eventTypes = [
  "911 Telephone Outage Emergency",
  "Administrative Message",
  "Air Quality Alert",
  "Air Stagnation Advisory",
  "Arroyo And Small Stream Flood Advisory",
  "Ashfall Advisory",
  "Ashfall Warning",
  "Avalanche Advisory",
  "Avalanche Warning",
  "Avalanche Watch",
  "Beach Hazards Statement",
  "Blizzard Warning",
  "Blizzard Watch",
  "Blowing Dust Advisory",
  "Blowing Dust Warning",
  "Brisk Wind Advisory",
  "Child Abduction Emergency",
  "Civil Danger Warning",
  "Civil Emergency Message",
  "Coastal Flood Advisory",
  "Coastal Flood Statement",
  "Coastal Flood Warning",
  "Coastal Flood Watch",
  "Dense Fog Advisory",
  "Dense Smoke Advisory",
  "Dust Advisory",
  "Dust Storm Warning",
  "Earthquake Warning",
  "Evacuation - Immediate",
  "Excessive Heat Warning",
  "Excessive Heat Watch",
  "Extreme Cold Warning",
  "Extreme Cold Watch",
  "Extreme Fire Danger",
  "Extreme Wind Warning",
  "Fire Warning",
  "Fire Weather Watch",
  "Flash Flood Statement",
  "Flash Flood Warning",
  "Flash Flood Watch",
  "Flood Advisory",
  "Flood Statement",
  "Flood Warning",
  "Flood Watch",
  "Freeze Warning",
  "Freeze Watch",
  "Freezing Fog Advisory",
  "Freezing Rain Advisory",
  "Freezing Spray Advisory",
  "Frost Advisory",
  "Gale Warning",
  "Gale Watch",
  "Hard Freeze Warning",
  "Hard Freeze Watch",
  "Hazardous Materials Warning",
  "Hazardous Seas Warning",
  "Hazardous Seas Watch",
  "Hazardous Weather Outlook",
  "Heat Advisory",
  "Heavy Freezing Spray Warning",
  "Heavy Freezing Spray Watch",
  "High Surf Advisory",
  "High Surf Warning",
  "High Wind Warning",
  "High Wind Watch",
  "Hurricane Force Wind Warning",
  "Hurricane Force Wind Watch",
  "Hurricane Local Statement",
  "Hurricane Warning",
  "Hurricane Watch",
  "Hydrologic Advisory",
  "Hydrologic Outlook",
  "Ice Storm Warning",
  "Lake Effect Snow Advisory",
  "Lake Effect Snow Warning",
  "Lake Effect Snow Watch",
  "Lake Wind Advisory",
  "Lakeshore Flood Advisory",
  "Lakeshore Flood Statement",
  "Lakeshore Flood Warning",
  "Lakeshore Flood Watch",
  "Law Enforcement Warning",
  "Local Area Emergency",
  "Low Water Advisory",
  "Marine Weather Statement",
  "Nuclear Power Plant Warning",
  "Radiological Hazard Warning",
  "Red Flag Warning",
  "Rip Current Statement",
  "Severe Thunderstorm Warning",
  "Severe Thunderstorm Watch",
  "Severe Weather Statement",
  "Shelter In Place Warning",
  "Short Term Forecast",
  "Small Craft Advisory",
  "Small Craft Advisory For Hazardous Seas",
  "Small Craft Advisory For Rough Bar",
  "Small Craft Advisory For Winds",
  "Small Stream Flood Advisory",
  "Snow Squall Warning",
  "Special Marine Warning",
  "Special Weather Statement",
  "Storm Surge Warning",
  "Storm Surge Watch",
  "Storm Warning",
  "Storm Watch",
  "Test",
  "Tornado Warning",
  "Tornado Watch",
  "Tropical Depression Local Statement",
  "Tropical Storm Local Statement",
  "Tropical Storm Warning",
  "Tropical Storm Watch",
  "Tsunami Advisory",
  "Tsunami Warning",
  "Tsunami Watch",
  "Typhoon Local Statement",
  "Typhoon Warning",
  "Typhoon Watch",
  "Urban And Small Stream Flood Advisory",
  "Volcano Warning",
  "Wind Advisory",
  "Wind Chill Advisory",
  "Wind Chill Warning",
  "Wind Chill Watch",
  "Winter Storm Warning",
  "Winter Storm Watch",
  "Winter Weather Advisory",
];
