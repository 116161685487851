import React, { useEffect, useState, useContext } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import ZoomOnFocusMapEvent from "./ZoomOnFocusMapEvent";
import ResetZoomMapEvent from "./ResetZoomMapEvent";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { Switch, Space, Checkbox } from "antd";
import { ColorContext, LayoutSizeContext, SidebarContext } from "App";
import LocationSetter from "./AlertCreator/components/LocationSetter";

export default function WidgetMap({
  children,
  mapCenter,
  zoom,
  maxBounds,
  maxBoundsViscosity,
  minZoom,
  maxZoom,
  setView,
  style,
  satelliteToggleStyle,
  preview = false,
  longMap = false,
  tallMap = false,
  setGetMapCenter,
}) {
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [initPos, setInitPos] = useState(mapCenter);
  const [initZoom, setInitZoom] = useState(zoom);
  const [resetTrigger, setResetTrigger] = useState(false); // State to trigger reset
  const [interacted, setInteracted] = useState(false); // State to track user interaction

  const [satelliteView, setSatelliteView] = useState(false);
  const [labelsEnabled, setLabelsEnabled] = useState(true);
  const sidebarOpen = useContext(SidebarContext);

  const theme = useContext(ColorContext);

  const handleResetClick = () => {
    setInteracted(false); // Reset interacted state
    setResetTrigger(new Date()); // Toggle to trigger useEffect in ResetZoomMapEvent
  };
  
  const layout = useContext(LayoutSizeContext);

  const calcWidth = () => {
    if (longMap) return layout.doubleWidgetWidth - (preview ? 48 : 0) - 59;
    return layout.widgetWidth - (preview ? 24 : 0) - 44;
  };

  const calcHeight = () => {
    if (tallMap) return layout.doubleWidgetHeight - 89;
    return layout.widgetHeight - 90;
  };

  const [mapStyle, setMapStyle] = useState({
    height: preview ? "410px" : calcHeight(),
    width: preview ? "450px" : calcWidth(),
    margin: 10,
    ...style,
  });

  useEffect(() => {
    setMapStyle({
      ...mapStyle,
      height: preview ? "410px" : calcHeight(),
      width: preview ? "450px" : calcWidth(),
    });
    // setMapStyle({
    //   ...mapStyle,
    //   height: preview ? "410px" : calcHeight(),
    //   width: preview ? "450px" : calcWidth(),
    // });
  }, [layout, sidebarOpen]);

  const InvalidateSize = ({ layout, sidebarOpen }) => {
    const map = useMap();
    useEffect(() => {
      if (map != null) {
        map.invalidateSize();
      }
    }, [map, layout.width, sidebarOpen]);
    return null;
  };
  return (
    <div
      style={{
        position: "relative",
        height: calcHeight(),
        width: calcWidth(),
        padding: 15,
      }}
    >
      {interacted && (
        <button
          style={{
            position: "absolute",
            top: "95px",
            left: "27px",
            zIndex: 1000, // Ensure it's above the map layers
            width: 30,
            height: 30,
            background: "white",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
            borderRadius: 2,
          }}
          onClick={handleResetClick}
        >
          <FaLocationCrosshairs
            size={20}
            style={{ color: "#222", marginTop: 4 }}
          />
        </button>
      )}{" "}
      {/* Button to trigger reset */}
      <MapContainer
        // style={mapStyle}
        style={{
          width: "100%",
          height: "100%",
        }}
        center={mapCenter}
        zoom={zoom}
        maxBounds={maxBounds}
        maxBoundsViscosity={maxBoundsViscosity}
        minZoom={minZoom}
        maxZoom={maxZoom}
        setView={setView}
        scrollWheelZoom={zoomEnabled}
        touchZoom={zoomEnabled}
      >
        {satelliteView ? (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        ) : (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        )}
        {labelsEnabled && satelliteView && (
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
        )}
        {children}
        <ZoomOnFocusMapEvent setInteracted={setInteracted} />
        <ResetZoomMapEvent
          triggerReset={resetTrigger}
          initPos={initPos}
          initZoom={initZoom}
          setInitPos={setInitPos}
          setInitZoom={setInitZoom}
        />
        <Space
          style={{
            position: "absolute",
            bottom: "5px",
            left: "5px",
            zIndex: 1000,
            ...satelliteToggleStyle,
          }}
        >
          <Switch
            checkedChildren="Map"
            unCheckedChildren="Satellite"
            checked={!satelliteView}
            onChange={() => setSatelliteView(!satelliteView)}
          />
          {satelliteView && (
            <Checkbox
              onChange={() => setLabelsEnabled(!labelsEnabled)}
              checked={labelsEnabled}
            >
              <p style={{ color: theme.fontColor }}>Labels</p>
            </Checkbox>
          )}
        </Space>
        <InvalidateSize layout={layout} sidebarOpen={sidebarOpen} />
        {setGetMapCenter && <LocationSetter center={mapCenter} zoom={zoom} />}
      </MapContainer>
    </div>
  );
}
