import React, { createElement, useContext, useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { capitalizeWord } from "utils/stringUtils";
import Device from "./GlobalActions/If/Device";
import DateTime from "./GlobalActions/If/DateTime";
import CreateFusionAlert from "./GlobalActions/then/CreateFusionAlert";
import DeviceAction from "./GlobalActions/then/DeviceAction";
import SendEmail from "./GlobalActions/then/SendEmail";
import { PiWarningFill } from "react-icons/pi";
import { ColorContext } from "App";

// dont allow select -select-
// insert node needs default error state

const GlobalActionsDropdown = ({
  itemNode,
  menuItems,
  setRetrigger,
  submitHandler,
  deviceData,
  anyErrors,
  submitting,
}) => {
  const theme = useContext(ColorContext);
  const nextActions = {
    if: ["-Select-", "Or", "And", "Then"],
    then: ["-Select-", "And", "Until"],
    until: ["-Select-", "And", "Done"],
    done: ["-Select-", "Submit"],
  };

  const mapActionsToType = {
    Or: "if",
    And: "if",
    Then: "then",
    Andthen: "then",
    Untilthen: "until",
    Donethen: "done",
    Anduntil: "until",
    Until: "until",
    Doneuntil: "done",
    Done: "done",
  };

  const mapActionsToBaseAction = {
    Or: "Or",
    And: "And",
    Then: "Then",
    Andthen: "And",
    Untilthen: "Until",
    Donethen: "Done",
    Anduntil: "And",
    Until: "Until",
    Doneuntil: "Done",
    Done: "Done",
  };

  const isLastOfType = () => {
    return itemNode.next ? itemNode.next.type !== itemNode.type : true;
  };

  const changeIntegration = (value) => {
    if (value === "-Select-") {
      return;
    }
    itemNode.integrationType = value;

    setRetrigger(Date.now());
  };
  const setDropdown = (value) => {
    itemNode.showDropdown = value;
    setRetrigger(Date.now());
  };

  const changeIntegrationData = (value) => {
    itemNode.integrationData = value;

    setRetrigger(new Date());
  };

  const [error, setError] = useState(null);

  const integrationTypes = {
    //IF/UNTIL
    Device: (
      <Device
        deviceList={deviceData}
        setData={changeIntegrationData}
        data={itemNode.integrationData}
        setError={setError}
      />
    ),
    "Date & Time": (
      <DateTime
        setData={changeIntegrationData}
        type={"dateTime"}
        data={itemNode.integrationData}
        setError={setError}
      />
    ),
    Date: (
      <DateTime
        type={"date"}
        setData={changeIntegrationData}
        data={itemNode.integrationData}
        setError={setError}
      />
    ),
    Time: (
      <DateTime
        type={"time"}
        setData={changeIntegrationData}
        data={itemNode.integrationData}
        setError={setError}
      />
    ),
    "Original Condition Resolved": "",
    "Start Manually": null,
    "Stop Manually": null,
    Places: null,
    Alerts: null,
    //THEN
    // "sendEmail": <div>sendEmail editor goes here</div>,
    // "soundAlarm": <div>soundAlarm editor goes here</div>,
    // "Create Fusion Alert": <CreateFusionAlert setData={changeIntegrationData} data={itemNode.integrationData}/>,

    "Device Action": (
      <DeviceAction
        setData={changeIntegrationData}
        data={itemNode.integrationData}
        deviceList={deviceData}
        setError={setError}
      />
    ),
    "Send Email": (
      <SendEmail
        setData={changeIntegrationData}
        data={itemNode.integrationData}
        setError={setError}
      />
    ),
    "Send SMS": null,
    "Create Custom Alert": null,
    "Log Event(s)": null,
    "Send Commands / Adjust Settings": null,
    "Data Analysis and Reporting": null,
    "Time Limit": null,
  };

  const onNextActionChange = (value) => {
    itemNode.selectedAction = value;

    if (value === "-Select-") {
      setRetrigger(Date.now());
      return;
    }

    //if there is no next node, must be to add a new action
    if (!itemNode.next) {
      addNode(value);
    }

    //if there is a next node
    if (itemNode.next) {
      //if next type is different, insert a new item
      if (itemNode.type !== itemNode.next.type) {
        //if the next action is "Or" or "And", insert a new item
        if (
          value === "Or" ||
          value === "And" ||
          value == "Andthen" ||
          value == "Anduntil"
        ) {
          insertNode(value);
        }
      }

      if (
        itemNode.type === "then" &&
        itemNode.next.type === "done" &&
        value === "Untilthen"
      ) {
        let temp = itemNode.next;
        itemNode.next = {
          type: mapActionsToType[value],
          selectedAction: "Doneuntil",
          integrationData: {
            error: true,
          },
          next: temp,
          prev: itemNode,
        };
        temp.prev = itemNode.next;
      }

      if (
        itemNode.type === "then" &&
        itemNode.next.type === "until" &&
        value === "Donethen"
      ) {
        itemNode.next = {
          type: mapActionsToType[value],
          selectedAction: "Doneuntil",
          integrationData: {
            error: true,
          },
          next: null,
          prev: itemNode,
        };
      }
    }
    itemNode.selectedAction = value;
    setRetrigger(Date.now());
  };

  useEffect(() => {
    if (!itemNode.next && !itemNode.prev) return;

    if (
      isLastOfType() &&
      itemNode.type === "if" &&
      itemNode.selectedAction !== "-Select-"
    ) {
      itemNode.selectedAction =
        nextActions[itemNode.type][nextActions[itemNode.type].length - 1];
    }
    if (
      isLastOfType() &&
      itemNode.type === "then" &&
      itemNode.selectedAction !== "-Select-"
    ) {
      itemNode.selectedAction =
        nextActions[itemNode.type][nextActions[itemNode.type].length - 1];
    }
  }, [itemNode]);

  const insertNode = (value) => {
    let temp = itemNode.next;
    let newAction = itemNode.selectedAction;

    if (itemNode.type === "if") {
      newAction = "Then";
    }
    if (itemNode.type === "then") {
      newAction = "Until";
    }
    if (itemNode.type === "until") {
      newAction = "Done";
    }

    itemNode.next = {
      type: mapActionsToType[value],
      selectedAction: newAction,
      integrationType: "-Select-",
      integrationData: {
        error: true,
      },
      component: null,
      next: temp,
      prev: itemNode,
    };
    temp.prev = itemNode.next;
    setRetrigger(Date.now());
  };

  const addNode = (value) => {
    itemNode.next = {
      type: mapActionsToType[value],
      selectedAction: "-Select-",
      integrationType: "-Select-",
      integrationData: {
        error: true,
      },
      next: null,
      prev: itemNode,
    };
    // setError({message: "Item not selected"})
    setRetrigger(Date.now());
  };

  const removeNode = () => {
    if (itemNode.type === "then") {
      // itemNode.next = null;
      itemNode.prev.selectedAction = "-Select-";
      if (itemNode.prev.type === "if") {
        itemNode.next = null;
      }
      if (
        itemNode.prev.type === "then" &&
        itemNode.next &&
        itemNode.next.type === "until"
      ) {
        itemNode.prev.selectedAction = "Until";
      }
    }
    if (itemNode.type === "if") {
      itemNode.prev.selectedAction = "Then";
    }
    if (itemNode.type === "until") {
      itemNode.prev.selectedAction = "-Select-";
      itemNode.prev.next = null;
      itemNode.next = null;
    }

    if (itemNode.prev) {
      itemNode.prev.next = itemNode.next;
    }
    if (itemNode.next) {
      itemNode.next.prev = itemNode.prev;
    }

    setRetrigger(Date.now());
  };
  if (itemNode.type === "done")
    return (
      <div
        style={{
          display: "flex",
          gap: 20,
          marginTop: 20,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: 20,
              width: "10%",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            {capitalizeWord(
              (itemNode.prev &&
                itemNode.prev.type === itemNode.type &&
                mapActionsToBaseAction[itemNode.prev.selectedAction]) ||
                itemNode.type
            )}
          </div>
          <div
            style={{
              cursor: "pointer",
              background:
                anyErrors || submitting ? theme.base : theme.primaryHighlight,
              padding: 30,
              width: 200,
              marginTop: 20,
              // borderRadius: 20,
              textAlign: "center",
            }}
            onClick={() => {
              submitHandler();
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        marginTop: 20,
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {itemNode.prev &&
          mapActionsToBaseAction[itemNode.prev.selectedAction] === "And" && (
            <div style={{ width: "12%" }}> </div>
          )}
        <div
          style={{
            padding: 20,
            width: "10%",
            fontSize: 24,
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {capitalizeWord(
              (itemNode.prev &&
                itemNode.prev.type === itemNode.type &&
                mapActionsToBaseAction[itemNode.prev.selectedAction]) ||
                itemNode.type
            )}
            {itemNode.integrationData &&
              itemNode.integrationData.error &&
              itemNode.integrationType !== "Original Condition Resolved" && (
                <div style={{ color: "red" }}>
                  <PiWarningFill />
                </div>
              )}
          </div>
          <div>
            {error && error.message && (
              <div
                style={{
                  color: "red",
                  marginTop: 20,
                  fontWeight: "normal",
                  fontSize: 18,
                }}
              >
                {error.message}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.primaryShadow,
            borderRadius: 5,
          }}
        >
          {/* {itemNode.integrationData && itemNode.integrationData.error && (<div style={{color: 'red'}}>Error in this item</div>)} */}
          <div
            style={{
              padding: 20,
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: theme.primaryShadow,
              borderRadius: 5,
            }}
            onClick={() => {
              setDropdown(!itemNode.showDropdown);
            }}
          >
            <select
              style={{
                padding: 5,
                padding: 20,
                width: 600,
                backgroundColor: theme.primaryShadow,
                borderRadius: 5,
                border: "1px solid lightgrey",
              }}
              value={itemNode.integrationType}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                if (integrationTypes[e.target.value] === null) return;
                changeIntegration(e.target.value);
                const props = {
                  setData: "changeIntegrationData",
                };

                //   "integrationTypes: ",
                //   integrationTypes,
                //   e.target.value
                // );
                // itemNode.component = integrationTypes[e.target.value];
                // itemNode.component = createElement(integrationTypes[e.target.value], {...props})
                itemNode.showDropdown = true;
              }}
            >
              {menuItems.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            {/* {itemNode.integrationType} */}
            <div style={{ display: "flex", gap: 20 }}>
              {isLastOfType() && itemNode.prev && (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    // e.stopPropagation()
                    removeNode();
                  }}
                >
                  <FaTrash />
                </div>
              )}
              {itemNode.integrationType !== "-Select-" && (
                <div style={{ cursor: "pointer" }}>
                  {itemNode.showDropdown ? <BsChevronUp /> : <BsChevronDown />}
                </div>
              )}
            </div>
          </div>
          <div>
            {
              <div style={{ display: itemNode.showDropdown ? "flex" : "none" }}>
                {/* {itemNode.component} */}
                {integrationTypes[itemNode.integrationType]}
              </div>
            }
            {/* {<div style={{display: itemNode.showDropdown ? 'flex' : 'none' }}>{integrationTypes[itemNode.integrationType]}</div>} */}
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              padding: 20,
              width: "10%",
              fontSize: 24,
              fontWeight: "bold",
            }}
          ></div>
          {nextActions[itemNode.type] && (
            <div style={{ gap: 20, width: "100%", display: "flex" }}>
              {nextActions[itemNode.type].map((item, index) => {
                if (item === "-Select-") return null;
                if (
                  index >= nextActions[itemNode.type].length - 2 &&
                  !isLastOfType() &&
                  itemNode.type === "then"
                )
                  return null;
                if (
                  index === nextActions[itemNode.type].length - 1 &&
                  !isLastOfType()
                )
                  return null;
                if (itemNode.type !== "then" && itemNode.type !== "until")
                  return (
                    <div
                      style={{
                        cursor: "pointer",
                        background:
                          itemNode.selectedAction === item ||
                          itemNode.selectedAction === item + itemNode.type
                            ? theme.primaryHighlight
                            : theme.primaryShadow,
                        padding: 30,
                        width: "25%",
                        // width: "100%"
                      }}
                      onClick={() => {
                        onNextActionChange(item);
                      }}
                    >
                      {item}
                    </div>
                  );
                else
                  return (
                    <div
                      style={{
                        cursor: "pointer",
                        background:
                          itemNode.selectedAction === item ||
                          itemNode.selectedAction === item + itemNode.type
                            ? theme.primaryHighlight
                            : theme.primaryShadow,
                        padding: 30,
                        width: "25%",
                        // width: "100%"
                      }}
                      onClick={() => {
                        onNextActionChange(item + itemNode.type);
                      }}
                    >
                      {item}
                    </div>
                  );
              })}
            </div>
          )}
        </div>
      </div>
      {isLastOfType() && (
        <div
          style={{
            width: "100%",
            height: 40,
            borderBottom: "1px solid grey",
          }}
        ></div>
      )}
    </div>
  );
};

export default GlobalActionsDropdown;
