import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as api from "../../../apis/FusionAPI";
import ConfirmPopup from "components/UI/ConfirmPopup";
import ContentRow from "./ContentRow";
import PopupMenu from "./PopupMenu";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ColorContext, LayoutSizeContext, MicrosoftContext } from "App";
import { Client } from "@microsoft/microsoft-graph-client";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Popup from "reactjs-popup";
import { FaX } from "react-icons/fa6";
import { group, link } from "d3";
import { RoleDropdown } from "../RoleDropdown";
import microsoftlogo from "assets/microsoftlogo.png";
import Cookies from "js-cookie";
import { FaChevronDown, FaChevronUp, FaLock, FaEye } from "react-icons/fa";
import ImportMSRoles from "./ImportMSRoles";
import RequestMSClientId from "./RequestMSClientId";
import { GetPermListForRole } from "data/permData";

export default function RolesTab({
  list,
  mousePosition,
  setMousePosition,
  perms,
  setRoleList,
}) {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();

  const layout = useContext(LayoutSizeContext);
  const microsfotContext = useContext(MicrosoftContext);

  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [showConfirmDeleteRole, setShowConfirmDeleteRole] = useState(false);
  const [showConfirmLinkRoles, setShowConfirmLinkRoles] = useState(false);
  const [newRoles, setNewRoles] = useState([]);
  const [showCantRemoveRole, setShowCantRemoveRole] = useState(false);
  const [showImportRoles, setShowImportRoles] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [links, setLinks] = useState({});
  const [isMsalAuthenticated, setIsMsalAuthenticated] = useState(false);
  const [hierarchy, setHierarchy] = useState({});
  const [originalHierarchy, setOriginalHierarchy] = useState({});
  const [linkMulti, setLinkMulti] = useState({});

  const [yourHighestRoleHierarchyId, setYourHighestRoleHierarchyId] =
    useState(null);
  const [msGroups, setMsGroups] = useState([]);
  const [hierarchyChanged, setHierarchyChanged] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    setRoleList(
      list.sort((a, b) => {
        if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

        if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
        if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
        if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

        return hierarchy[a.role_Id] - hierarchy[b.role_Id];
      })
    );
  }, [list]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const msalauth = await api.tryMsAuthSilent(instance);

        setIsMsalAuthenticated(msalauth !== null);
        const res = await api.getAllRoles(instance);

        setRoleList([...res.data.result]);
        buildHierarchy([...res.data.result]);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const buildHierarchy = (roles) => {
    const rolesToHierarch = [...roles].filter((item) => {
      return parseInt(item.role_Id) >= 0;
    });
    let hierarchy = {};
    rolesToHierarch.forEach((role) => {
      hierarchy[role.role_Id] = role.hierarchy_level;
    });
    let max = 999;

    let l = [...roles];
    const myRoles = l
      .filter((item) => {
        if (item.users?.includes(Cookies.get("username"))) {
          return item.role_Id;
        }
      })
      .map((item) => {
        return item.role_Id;
      });

    rolesToHierarch.forEach((role) => {
      if (myRoles.includes(role.role_Id)) {
        if (role.hierarchy_level <= max) {
          max = role.hierarchy_level;
        }
      } else {
      }
    });
    setYourHighestRoleHierarchyId(max);

    setHierarchy(hierarchy);
    setOriginalHierarchy(hierarchy);
  };

  useEffect(() => {
    if (JSON.stringify(hierarchy) === JSON.stringify(originalHierarchy)) {
      setHierarchyChanged(false);
    }
  }, [hierarchy]);

  const theme = useContext(ColorContext);

  const handleDeleteRole = async (roleId) => {
    const res = await api.deleteRole(roleId);
    setShowConfirmDeleteRole(false);

    //remove roleid entry from hierarchy
    const newHierarchy = { ...hierarchy };
    const deleted = newHierarchy[roleId];
    delete newHierarchy[roleId];

    Object.keys(newHierarchy).forEach((role) => {
      if (newHierarchy[role] > deleted) newHierarchy[role] -= 1;
    });

    setHierarchy(newHierarchy);

    await api.getAllRoles(instance).then((res) => {
      setRoleList([...res.data.result]);
      setShowConfirmDeleteRole(false);
    });
  };

  const onCreateRole = async () => {
    navigate("/system/createrole");
  };

  const getMsInfo = async () => {
    //MS Graph API
    const scopes = ["Group.Read.All"];
    const msRes = api.tryMsAuthSilent(instance, scopes);

    if (msRes) {
      const client = Client.init({
        authProvider: (done) => {
          done(null, msRes.accessToken);
        },
      });

      let groups = await api.ms_getSecurityGroups(instance);

      setMsGroups(groups.data.result);

      // get links
      const res = await api.ms_getRoleLinks(instance);
      const links = res.data.result;

      const linkMultis = {};
      Object.keys(links).forEach((link) => {
        linkMultis[link] = links[link];
      });

      setLinkMulti(linkMultis);
    }
  };

  const onImportRoles = async () => {
    setShowImportRoles(true);
    getMsInfo();
  };

  const isPrimaryRole = (role) => {
    return role.role_Id === -1;
  };

  useEffect(() => {}, [linkMulti]);

  const sendImportRoles = async () => {
    let linkObj = { ...linkMulti };

    if (newRoles.length > 0) {
      let permData;

      let deviceTypes = await api.getDeviceTypes();
      deviceTypes = deviceTypes.data.result;
      const deviceTypeArr = Object.keys(deviceTypes).flatMap((deviceType) => {
        return deviceTypes[deviceType].map(
          (subType) => deviceType + " " + subType
        );
      });

      permData = GetPermListForRole(deviceTypeArr)
        .map((perm) => {
          return {
            [perm.name
              .replace(/\s/g, "")
              .replace(/\//g, "-")
              .replace(/:/g, "_")]: false,
          };
        })
        .reduce((acc, current) => {
          return { ...acc, ...current };
        });

      //create new roles
      for (const newRole of newRoles) {
        const newRoleId = await api.createRole(
          newRole.name,
          [],
          JSON.stringify(permData),
          "[]"
        );

        linkObj = { ...linkObj, [newRole.id]: [newRoleId.data.result] };
      }
    }

    const res = await api.ms_updateRoleLinks(instance, linkObj);
    setShowImportRoles(false);
    setShowConfirmLinkRoles(false);

    //get roles
    const res2 = await api.getAllRoles(instance);
    setRoleList([...res2.data.result]);
    buildHierarchy([...res2.data.result]);

    setNewRoles([]);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Popup
        open={showImportRoles}
        onClose={() => setShowImportRoles(false)}
        modal
        closeOnDocumentClick={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: theme.base,
            border: "solid white 1px",
            borderRadius: 10,
            color: theme.fontColor,
            padding: 10,
            width: "50vw",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 20,
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={microsoftlogo}
                style={{ height: 20, marginRight: 10 }}
              />
              <h3> Link Security Groups</h3>
            </div>
            <button onClick={() => setShowImportRoles(false)}>
              <FaX />
            </button>
          </div>

          {/* Without setting MS Client ID */}
          <ImportMSRoles
            list={list}
            msGroups={msGroups}
            linkMulti={linkMulti}
            setLinkMulti={setLinkMulti}
            setShowImportRoles={setShowImportRoles}
            setShowConfirmLinkRoles={setShowConfirmLinkRoles}
            newRoles={newRoles}
            setNewRoles={setNewRoles}
          />
        </div>
      </Popup>
      {showConfirmLinkRoles && (
        <ConfirmPopup
          open={showConfirmLinkRoles}
          setOpen={setShowConfirmLinkRoles}
          style={{
            width: 500,
            whiteSpace: "pre-line",
          }}
          message={`Important Notice:\n

You are about to link a Microsoft Security Group to a Role within Overwatch Fusion+. This is a critical action that requires careful consideration.\n

Once linked, all users in the selected security group will be imported into Overwatch Fusion+ and assigned the corresponding role. This role determines their access to specific permissions, widgets, and dashboard layouts within the app.\n

Although this action can be reversed, we strongly recommend ensuring the security group is appropriate for the intended role and permissions before proceeding.\n

By continuing, you acknowledge the significance of this action and confirm your intent to proceed with the import and role assignment.`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => setShowConfirmLinkRoles(false),
            () => sendImportRoles(),
          ]}
        />
      )}
      {showConfirmDeleteRole && (
        <ConfirmPopup
          open={showConfirmDeleteRole}
          setOpen={setShowConfirmDeleteRole}
          message={`Are you sure you want to remove this role?`}
          options={["Cancel", "Yes"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: theme.primaryHighlight, color: theme.fontColor },
          ]}
          callbacks={[
            () => setShowConfirmDeleteRole(false),
            () => handleDeleteRole(itemToDelete.role_Id),
          ]}
        />
      )}
      {showCantRemoveRole && (
        <ConfirmPopup
          open={showCantRemoveRole}
          setOpen={setShowCantRemoveRole}
          message={`The ${itemToDelete.role_name} Role cannot be removed with ${
            itemToDelete.role_count
          } assigned User${
            itemToDelete.role_count > 1 ? "s" : ""
          }. Remove all users from this role and try again.`}
          options={["Ok"]}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
          ]}
          callbacks={[() => setShowCantRemoveRole(false)]}
        />
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          // background: 'red',
          justifyContent: "space-between",
        }}
      >
        <div>
          {perms.accessLevel >= 12 && hierarchyChanged && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: 176,
                  padding: 10,
                  // height: 65,
                  backgroundColor: theme.primary,
                  borderRadius: 5,
                  marginRight: 20,
                }}
                onClick={async () => {
                  const res = await api.updateRoleHierarchy(hierarchy);
                  setHierarchyChanged(false);
                }}
              >
                <div>Save</div>
              </button>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: 176,
                  padding: 10,
                  // height: 65,
                  // backgroundColor: theme.primary,
                  border: "1px solid white",
                  borderRadius: 5,
                  marginRight: 20,
                }}
                onClick={async () => {
                  setHierarchy(originalHierarchy);
                  setHierarchyChanged(false);
                }}
              >
                <div>Discard</div>
              </button>
              <div>
                Careful! You have unsaved changes to the Organization's Role
                Hierarchy, this controls the highest-ranked role of all Client
                Users.
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <div
            style={{
              height: 39,
              backgroundColor: theme.primary,
              borderRadius: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <input
              placeholder="Search..."
              style={{
                width: "100%",
                height: 39,
              }}
              onChange={(value) => setSearch(value.target.value)}
              value={search}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr .5fr 2fr .25fr",
          padding: 20,
          textAlign: "left",
          borderBottom: "1px solid gray",
          textAlign: "left",
          fontSize: 20,
        }}
      >
        <div style={{ marginLeft: 50 }}>Roles</div>
        <div>Users</div>
        <div>Created By</div>
        <div />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {list.length === 0 && <WidgetLoadingAnimation />}
        {list &&
          list
            .filter((item) => {
              return true;
              return item.role_Id >= 0;
            })
            .sort((a, b) => {
              if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

              if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
              if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
              if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

              return hierarchy[a.role_Id] - hierarchy[b.role_Id];
            })
            .map((item, index) => {
              return (
                <div
                  key={index}
                  style={
                    {
                      // background:'yellow',
                      // width: "100%"
                    }
                  }
                >
                  {item.role_name
                    .toLowerCase()
                    .replace(/ /g, "")
                    .includes(search.toLowerCase().replace(/ /g, "")) && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr .5fr 2fr .25fr",
                        padding: 20,
                        gap: 10,
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                          justifyItems: "flex-start",
                          // background:'red',
                          alignItems: "center",
                        }}
                      >
                        {(item.role_Id >= 0 && perms.accessLevel >= 12) ||
                        perms.accessLevel == 8 ? (
                          <div
                            style={{
                              width: 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: 17,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {hierarchy[item.role_Id] > 0 && (
                                <div
                                  onClick={() => {
                                    const useList = list.sort((a, b) => {
                                      if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

                                      if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
                                      if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

                                      return (
                                        hierarchy[a.role_Id] -
                                        hierarchy[b.role_Id]
                                      );
                                    });
                                    setHierarchyChanged(true);
                                    //move this up one and the one above down one
                                    const newHierarchy = { ...hierarchy };
                                    const newRole = { ...useList[index] };
                                    const oldRole = { ...useList[index - 1] };
                                    newHierarchy[newRole.role_Id] -= 1;
                                    newHierarchy[oldRole.role_Id] += 1;
                                    setHierarchy(newHierarchy);
                                  }}
                                >
                                  <FaChevronUp />
                                </div>
                              )}
                              {hierarchy[item.role_Id] >= 0 ? (
                                <div>{hierarchy[item.role_Id] + 1}</div>
                              ) : (
                                <div></div>
                              )}
                              {hierarchy[item.role_Id] <
                                Object.keys(hierarchy).length - 1 && (
                                <div
                                  onClick={() => {
                                    const useList = list.sort((a, b) => {
                                      if (parseInt(a.role_Id) === -2) return 1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -2) return -1; // Move 'b' to the bottom

                                      if (parseInt(a.role_Id) === -1) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) === -1) return 1; // Move 'b' to the bottom
                                      if (parseInt(a.role_Id) < 0) return -1; // Move 'a' to the bottom
                                      if (parseInt(b.role_Id) < 0) return 1; // Move 'b' to the bottom

                                      return (
                                        hierarchy[a.role_Id] -
                                        hierarchy[b.role_Id]
                                      );
                                    });
                                    setHierarchyChanged(true);
                                    //move this down one and the one below up one
                                    const newHierarchy = { ...hierarchy };
                                    const newRole = { ...useList[index] };
                                    const oldRole = { ...useList[index + 1] };
                                    newHierarchy[newRole.role_Id] += 1;
                                    newHierarchy[oldRole.role_Id] -= 1;
                                    setHierarchy(newHierarchy);
                                  }}
                                >
                                  <FaChevronDown />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 50,
                              opacity: 0.5,
                              display: "flex",
                            }}
                          >
                            {perms.accessLevel === 10 &&
                            item.role_Id !== -2 &&
                            perms.accessLevel === 10 &&
                            item.hierarchy_level >
                              yourHighestRoleHierarchyId ? (
                              <div></div>
                            ) : (
                              <FaLock />
                            )}
                          </div>
                        )}
                        {item.role_name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Popup
                          trigger={<div>{item.role_count}</div>}
                          position="top center"
                          on="hover"
                          closeOnDocumentClick
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              fontSize: 16,
                              fontWeight: "normal",
                              color: theme.fontColor,
                              backgroundColor: theme.primary,
                              borderRadius: 5,
                            }}
                          >
                            {(item.users || ["No Users"]).map((user, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    backgroundColor:
                                      i % 2 === 0 ? "" : "#2c373b",
                                  }}
                                >
                                  <div>{user}</div>
                                </div>
                              );
                            })}
                          </div>
                        </Popup>
                      </div>
                      <div>{item.created_by_email}</div>
                      {(perms.testPermission(
                        [8, 10, 11, 12, 13],
                        ["EditRole"]
                      ) ||
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["EditRole"]
                        ) ||
                        perms.testPermission(
                          [8, 10, 11, 12, 13],
                          ["DeleteRole"]
                        )) &&
                      //or user is 12 or 13
                      item.role_Id >= 0 &&
                      (perms.accessLevel === 12 ||
                        perms.accessLevel === 13 ||
                        item.hierarchy_level > yourHighestRoleHierarchyId) ? (
                        <Popup
                          position="top right"
                          trigger={
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // width: 1266 / 4,
                                // height: 71,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#2a3e52",
                                  borderRadius: 100,
                                  width: 39,
                                  height: 39,
                                }}
                              >
                                ...
                              </div>
                            </button>
                          }
                        >
                          <div>
                            {perms.testPermission(
                              [8, 10, 11, 12, 13],
                              ["EditRole"]
                            ) && (
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 200,
                                  height: 50,
                                  borderRadius: 15,
                                  backgroundColor: theme.primary,
                                  border: "solid black 1px",
                                  color: theme.fontColor,
                                }}
                                onClick={() =>
                                  navigate(
                                    `/system/editrole?roleid=${item.role_Id}`
                                  )
                                }
                              >
                                EDIT
                              </button>
                            )}
                            {perms.testPermission(
                              [8, 10, 11, 12, 13],
                              ["DeleteRole"]
                            ) && (
                              <button
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 200,
                                  height: 50,
                                  borderRadius: 15,
                                  backgroundColor: "red",
                                  border: "solid black 1px",
                                  color: theme.fontColor,
                                }}
                                onClick={() => {
                                  item?.role_count > 0
                                    ? setShowCantRemoveRole(true)
                                    : setShowConfirmDeleteRole(true);
                                  setItemToDelete(item);
                                }}
                              >
                                REMOVE
                              </button>
                            )}
                          </div>
                        </Popup>
                      ) : (
                        <Popup
                          trigger={
                            <button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // width: 1266 / 4,
                                // height: 71,
                              }}
                              onClick={() => {
                                // if (item.role_Id <= 0) return;
                                navigate(
                                  `/system/viewrole?roleid=${item.role_Id}`
                                );
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#2a3e52",
                                  borderRadius: 100,
                                  width: 39,
                                  height: 39,
                                }}
                              >
                                <FaEye />
                              </div>
                            </button>
                          }
                          position="top center"
                          on="hover"
                          closeOnDocumentClick
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              fontSize: 16,
                              fontWeight: "normal",
                              color: theme.fontColor,
                              backgroundColor: theme.primary,
                              borderRadius: 5,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                `/system/viewrole?roleid=${item.role_Id}`
                              );
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                                backgroundColor: "#2c373b",
                              }}
                            >
                              <div>View Role</div>
                            </div>
                          </div>
                        </Popup>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {perms.testPermission([8, 11, 12, 13]) && isMsalAuthenticated && (
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: 176,
              padding: 5,
              height: 65,
              backgroundColor: theme.primary,
              borderRadius: 5,
            }}
            onClick={onImportRoles}
          >
            <img src={microsoftlogo} style={{ height: 20, marginRight: 10 }} />
            <div>Link Security Groups</div>
          </button>
        )}
        {perms.testPermission([8, 10, 11, 12, 13], ["CreateRole"]) && (
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 176,
              height: 65,
              backgroundColor: theme.primary,
              borderRadius: 5,
            }}
            onClick={onCreateRole}
          >
            <div>Create Role</div>
          </button>
        )}
      </div>
    </div>
  );
}
