import React, { useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import RenderAlerts from "components/RenderAlerts";
import { alertColorScale } from "utils/alertUtils";
import AlertIcons from "components/AlertIcons";
import { IconContext } from "react-icons";
import { Alert, Icon } from "@mui/material";

export default function InfoPanel({
  selectedAlert,
  setSelectedAlert,
  selectedZone,
}) {
  useEffect(() => {
    if (selectedZone?.alerts.length === 1) {
      setSelectedAlert(selectedZone.alerts[0]);
    }
  }, [selectedZone, setSelectedAlert]);

  return (
    <div
      style={{
        paddingTop: 10,
        height: "100%",
      }}
    >
      {selectedAlert ? (
        <div
          style={{
            overflow: "hidden",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <div
            style={{
              borderBottom: "1px solid grey",
              paddingBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button onClick={() => setSelectedAlert(null)}>
              <IoMdArrowRoundBack size={24} style={{ marginTop: 3 }} />
            </button>
            <div
              style={{
                borderRight: "1px solid grey",
                height: 25,
                marginRight: 7,
                marginLeft: 4,
              }}
            />
            <div
              style={{
                backgroundColor: alertColorScale(selectedAlert.severity),
                padding: 5,
                borderRadius: 5,
                display: "flex",
                marginLeft: 5,
              }}
            >
              <h2 style={{ marginRight: 10 }}>{selectedAlert.type}</h2>
              <IconContext.Provider value={{ size: 30 }}>
                <AlertIcons type={selectedAlert.type} />
              </IconContext.Provider>
            </div>
          </div>
          {/* <div style={{ height: 350, overflow: "auto" }}> */}
          <div>
            <h4
              style={{
                borderBottom: "1px solid grey",
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              Headline: {selectedAlert.headline}
            </h4>
            <p
              style={{
                borderBottom: "1px solid grey",
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              Description: {selectedAlert.description}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 10,
                paddingTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginRight: 5 }}>
                <p>Start Time: {selectedAlert.startTime}</p>
                <p style={{ marginTop: 5 }}>
                  End Time: {selectedAlert.endTime}
                </p>
              </div>
              <div style={{ borderRight: "1px solid grey", marginRight: 5 }} />
              <div>
                <p>Sender: {selectedAlert.sender}</p>
                {selectedZone && (
                  <p style={{ marginTop: 5 }}>Zone: {selectedZone.zoneCode}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : selectedZone?.alerts.length > 0 ? (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <h1>Zone {selectedZone.zoneCode}</h1>
          <RenderAlerts
            arrayOfData={selectedZone.alerts}
            setSelectedAlert={setSelectedAlert}
          />
        </div>
      ) : (
        <h1>No zone selected</h1>
      )}
    </div>
  );
}
