import React, { useState, useContext } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaEllipsis } from "react-icons/fa6";
import { displayDate } from "utils/dateUtils";
import NodeStatus from "components/NodeStatus";
import { ConnectionsContext } from "./Connections";
import { getDynamicDeviceIcon } from "utils/iconUtils";

export const IntegrationItem = ({
  integration,
  setInitialData,
  initialData,
  setShowNewConnection,
  deviceImages,
  theme,
  index,
  devices,
  setShowNewDevice,
  deviceCount,
  setDeviceCount,
}) => {
  const connectionsContext = useContext(ConnectionsContext);
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        key={index}
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr .5fr .5fr",

          borderBottom: "1px solid grey",
          padding: 10,
          gap: 10,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <div
          style={{
            display: "flex",

            alignItems: "center",
          }}
        >
          {/* TODO: replace with system icon */}
          {deviceImages[integration.system_type] ? (
            <img
              src={deviceImages[integration.system_type]}
              style={{
                height: 50,
                width: 50,
                borderRadius: 10,
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                background: theme.primary,
                height: 50,
                width: 50,
                borderRadius: 10,
              }}
            ></div>
          )}

          <div style={{ marginLeft: 20 }}>
            <div>{integration.system_name}</div>
            <div style={{ color: "#aaa" }}>{integration.user_email}</div>
          </div>
        </div>
        <div>{integration.system_type}</div>
        <div>
          <div>
            {index + 1}/
            {integration.licensesTotal ||
              connectionsContext.licenseCounts.advanced_system}{" "}
            (System)
          </div>
        </div>

        <div>
          <div
            style={{
              backgroundColor: integration.status ? "#01B574" : "",
              border: integration.status ? "0px" : "1px solid #ccc",
              borderRadius: 8,
              padding: 5,
              width: 80,
              textAlign: "center",
              boxSizing: "border-box",
            }}
          >
            {integration.status ? "Online" : "Offline"}
          </div>
        </div>
        <div>{displayDate(integration.date_integration)}</div>
        <div>
          <div
            style={{
              cursor: "pointer",
              background: theme.primary,
              padding: 5,
              borderRadius: 20,
              textAlign: "center",
              width: 20,
              height: 20,
              alignContent: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setInitialData(integration);
              setShowNewConnection(true);
            }}
          >
            <FaEllipsis />
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {expanded && (
        <div
          onClick={() => {
            setExpanded(false);
          }}
        >
          {devices
            // .filter((device) => {
            //   return device.system_type === integration.system_type;
            // })
            .map((device, index) => {
              if (device.system_type === integration.system_type)
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr .5fr .5fr",
                      backgroundColor: expanded ? "#2c363a" : "",
                      borderBottom: "1px solid grey",
                      padding: 10,
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",

                        marginLeft: 20,
                        alignItems: "center",
                      }}
                    >
                      {/* TODO: replace with system icon */}
                      {deviceImages[device.device_type] ? (
                        <img
                          src={getDynamicDeviceIcon(device, "img")}
                          // src={deviceImages[device.device_type]}

                          alt=""
                          style={{
                            height: 50,
                            width: 50,
                            // borderRadius: 10,
                            marginRight: 10,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            background: theme.primary,
                            height: 35,
                            width: 45,
                            borderRadius: 10,
                            marginRight: 10,
                          }}
                        ></div>
                      )}

                      {device.alias}
                    </div>
                    <div>{device.device_type}</div>
                    <div>
                      {index + 1}/
                      {connectionsContext.licenseCounts.connected_devices}{" "}
                      (Device)
                    </div>
                    <div>
                      <NodeStatus device={device} />
                    </div>
                    <div>{displayDate(device.date_created)}</div>
                    <div>
                      <div
                        style={{
                          cursor: "pointer",
                          background: theme.primary,
                          padding: 5,
                          borderRadius: 20,
                          textAlign: "center",
                          width: 20,
                          height: 20,
                          alignContent: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setInitialData(device);
                          setShowNewDevice(true);
                        }}
                      >
                        <FaEllipsis />
                      </div>
                    </div>
                  </div>
                );
            })}
        </div>
      )}
    </div>
  );
};
