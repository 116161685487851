import { LayoutSizeContext } from "App";
import ConditionIcons from "components/ConditionIcons";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { IconContext } from "react-icons";
import { temperatureColorScale } from "utils/colorUtils";

export default function ClosestCurrentConditions({ closestLocation }) {
  useEffect(() => {
    
  }, [closestLocation]);

  const layout = useContext(LayoutSizeContext);

  const replaceChars = (str, chars1, chars2) => {
    return str.replace(new RegExp(chars1, "g"), chars2);
  };
  return (
    <div style={{
      
      marginTop: 10 * layout.scaleMult - 5,
    }}>
      {/* <h2 style={{ marginBottom: 5 }}>Current Conditions</h2> */}
      <h2>{replaceChars(closestLocation.city_name, "  ", " - ")}</h2>
      {/* <div>Last update: {closestLocation.utc_time}</div> */}
      <div>
        Last update:{" "}
        {moment(closestLocation.utc_time).format("MM/DD/YYYY HH:mm:ss")}
      </div>
      <div
        style={{
          border: "1px solid grey",
          // marginBottom: 10,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            // background:'red'
          }}
        >
          <IconContext.Provider value={{ size: 80 * layout.scaleMult }}>
            <ConditionIcons conditionId={closestLocation.icon} />
          </IconContext.Provider>
        </div>
        <div style={{ fontSize: 18 * layout.scaleMult, fontWeight: "bold" }}>
          <p>{closestLocation.sky}</p>
          <p>{closestLocation.temperature}° F</p>
        </div>
      </div>
      <div
        style={{
          border: "1px solid grey",
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: 40 * layout.scaleMult - 40,
          fontSize: layout.baseFontSize,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20 * layout.scaleMult - 20,
          }}
        >
          <p style={{ paddingTop: 5 }}>Humidity: {closestLocation.humidity}%</p>
          <p style={{ paddingTop: 5 }}>
            Feels Like: {closestLocation.comfort}° F
          </p>
          <p style={{ paddingTop: 5 }}>
            Dew Point: {closestLocation.dew_point}° F
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20 * layout.scaleMult - 20,
            marginBottom: 40 * layout.scaleMult - 30,
          }}
        >
          <p style={{ paddingTop: 5 }}>
            Visibility: {closestLocation.visibility} mi
          </p>
          <p style={{ paddingTop: 5 }}>
            Pressure: {closestLocation.baro_pressure} in
          </p>
          <p style={{ paddingTop: 5 }}>
            Precipitation: {closestLocation.precip || 0}%
          </p>
        </div>
      </div>
    </div>
  );
}
