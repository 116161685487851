import React, { useEffect, useState, useContext } from "react";
import { HistoricalClimateMonth } from "./components/HistoricalClimateMonth";

import * as api from "apis/FusionAPI";
import moment from "moment";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache = { airportDelays: null };
 

export const AirportDelay = ({
  widget,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) => {
  const layout = useContext(LayoutSizeContext);

  const theme = useContext(ColorContext);
  const [error, setError] = useState({ state: false, message: "" });
  const [fetching, setFetching] = useState(true);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  const [airportDelays, setAirportDelays] = useState([]);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getAirportDelays";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet(ROUTE).then((res) => {
          
          dataCache.airportDelays = res.data.result;
          setAirportDelays(dataCache.airportDelays);
           
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);
  const dataRefresh = useDataRefresh();
  useEffect(() => {
    dataCache = { airportDelays: null };
    setFetching(true);
  }, [dataRefresh.widgetRefresh]);

  useEffect(() => {
    (async () => {
      //get airportDelays data
      if (!dataCache.airportDelays) {
        FusionData.get(ROUTE)
          .then((res) => {
            
            dataCache.airportDelays = res.data.result;
            setAirportDelays(dataCache.airportDelays);
             
            setFetching(false);
          })
          .catch((err) => {
            
            setError({ state: true, message: err.message });
          });
      } else {
        setAirportDelays(dataCache.airportDelays);
        setFetching(false);
      }
    })();
  }, [dataRefresh.widgetRefresh]);

  const groupSegmentsByDay = (segments) => {
    let groupedSegments = {};
    segments.forEach((segment) => {
      if (segment.iso8601.split("T")[1].includes("00:00:00")) {
        let date = new Date(segment.iso8601.split("T")[0]);
        date.setDate(date.getDate() - 1);
        segment.iso8601 = date.toISOString();
      }
      let day = segment.iso8601.split("T")[0];
      if (!groupedSegments[day]) {
        groupedSegments[day] = [];
      }
      groupedSegments[day].push(segment);
    });
    return groupedSegments;
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching && widget) {
    // if (true && widget) {
    return (
      <WidgetLoadingAnimation
        widgetName={"AirportDelay"}
        dimensions={dimensions}
      />
    );
  }

  if (fetching) {
    return <WidgetLoadingAnimation/>
  }
  
  if (airportDelays && airportDelays.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (!dimensions)
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        <div
          style={{
            overflowY: "scroll",
            height: "100%",
          }}
        >
          <div
            style={{
              padding: 15,
              backgroundColor: widget ? theme.primary : theme.base,
              marginBottom: 15,
            }}
          >
            <div
              style={{
                borderBottom: "solid grey 1px",
                fontSize: layout.baseFontSize + 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {(airportDelays.length && airportDelays[0].location) ||
                  "None found"}
              </div>
              {moment(new Date()).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {airportDelays.length > 0 &&
              airportDelays[0].segments &&
              Object.entries(
                groupSegmentsByDay(JSON.parse(airportDelays[0].segments))
              ).map(([day, segments], index) => {
                return (
                  // map each days segments to morning, afternoon, evening, and night
                  <div
                    key={index}
                    style={{
                      fontSize: layout.baseFontSize + 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: 20,
                      padding: 20,
                      backgroundColor: widget ? theme.primary : theme.base,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: layout.baseFontSize + 5,
                        marginBottom: 10,
                        borderBottom: "solid grey 1px",
                      }}
                    >
                      {new Date(
                        new Date(segments[0].iso8601).getTime() - 60000
                      ).toLocaleDateString("en-US")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {segments.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50%",
                              marginBottom: 30,
                            }}
                          >
                            <h2
                              style={{
                                borderBottom: "solid grey 1px",
                                width: "80%",
                                marginBottom: 10,
                              }}
                            >
                              {item.day_segment}{" "}
                            </h2>
                            {item.delay_time !== "0" ? (
                              <div
                                style={{
                                  gap: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {/* {item.delay_time && <div>Delay Time: {item.delay_time}</div>} */}
                                {item.delay && <div>Delay: {item.delay}</div>}
                                {/* {item.primary_delay_reason && <div>Primary Delay Reason: {item.primary_delay_reason}</div>} */}
                                {item.primary_reason && (
                                  <div>
                                    Primary Reason: {item.primary_reason}
                                  </div>
                                )}
                                {/* {item.secondary_delay_reason && <div>Secondary Delay Reason: {item.secondary_delay_reason}</div>} */}
                                {item.secondary_reason && (
                                  <div>
                                    Secondary Reason: {item.secondary_reason}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div> No delays</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );

  if (dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div>
        <WidgetTimeUpdate
          route={ROUTE}
           
          updateIntervalMinutes={60}
        />

        {/* Upper */}
        <div
          style={{
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            overflowY: "scroll",
            height: layout.widgetHeight - 90,
          }}
        >
          <div
            style={{
              padding: 15,
              backgroundColor: widget ? theme.primary : theme.base,
              marginBottom: 15,
            }}
          >
            <div
              style={{
                borderBottom: "solid grey 1px",
                fontSize: layout.baseFontSize + 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {(airportDelays.length && airportDelays[0].location) ||
                  "None found"}
              </div>
              {moment(new Date()).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {airportDelays.length > 0 &&
              airportDelays[0].segments &&
              Object.entries(
                groupSegmentsByDay(JSON.parse(airportDelays[0].segments))
              ).map(([day, segments], index) => {
                return (
                  // map each days segments to morning, afternoon, evening, and night
                  <div
                    key={index}
                    style={{
                      fontSize: layout.baseFontSize + 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: 20,
                      padding: 20,
                      backgroundColor: widget ? theme.primary : theme.base,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: layout.baseFontSize + 5,
                        marginBottom: 10,
                        borderBottom: "solid grey 1px",
                      }}
                    >
                      {new Date(
                        new Date(segments[0].iso8601).getTime() - 60000
                      ).toLocaleDateString("en-US")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {segments.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50%",
                              marginBottom: 30,
                            }}
                          >
                            <h2
                              style={{
                                borderBottom: "solid grey 1px",
                                width: "80%",
                                marginBottom: 10,
                              }}
                            >
                              {item.day_segment}{" "}
                            </h2>
                            {item.delay_time !== "0" ? (
                              <div
                                style={{
                                  gap: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {/* {item.delay_time && <div>Delay Time: {item.delay_time}</div>} */}
                                {item.delay && <div>Delay: {item.delay}</div>}
                                {/* {item.primary_delay_reason && <div>Primary Delay Reason: {item.primary_delay_reason}</div>} */}
                                {item.primary_reason && (
                                  <div>
                                    Primary Reason: {item.primary_reason}
                                  </div>
                                )}
                                {/* {item.secondary_delay_reason && <div>Secondary Delay Reason: {item.secondary_delay_reason}</div>} */}
                                {item.secondary_reason && (
                                  <div>
                                    Secondary Reason: {item.secondary_reason}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div> No delays</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{}}>
        {widget && (
          <WidgetTimeUpdate
            route={ROUTE}
             
            updateIntervalMinutes={60}
          />
        )}
        {/* Upper */}
        <div
          style={{
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            overflowY: "scroll",
            height: layout.widgetHeight - 90,
          }}
        >
          <div
            style={{
              padding: 15,
              backgroundColor: widget ? theme.primary : theme.base,
              marginBottom: 15,
            }}
          >
            <div
              style={{
                borderBottom: "solid grey 1px",
                fontSize: layout.baseFontSize + 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {(airportDelays.length && airportDelays[0].location) ||
                  "None found"}
              </div>
              {moment(new Date()).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {airportDelays.length > 0 &&
              airportDelays[0].segments &&
              Object.entries(
                groupSegmentsByDay(JSON.parse(airportDelays[0].segments))
              ).map(([day, segments], index) => {
                return (
                  // map each days segments to morning, afternoon, evening, and night
                  <div
                    key={index}
                    style={{
                      fontSize: layout.baseFontSize + 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: 20,
                      padding: 20,
                      backgroundColor: widget ? theme.primary : theme.base,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: layout.baseFontSize + 5,
                        marginBottom: 10,
                        borderBottom: "solid grey 1px",
                      }}
                    >
                      {new Date(
                        new Date(segments[0].iso8601).getTime() - 60000
                      ).toLocaleDateString("en-US")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {segments.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50%",
                              marginBottom: 30,
                            }}
                          >
                            <h2
                              style={{
                                borderBottom: "solid grey 1px",
                                width: "80%",
                                marginBottom: 10,
                              }}
                            >
                              {item.day_segment}{" "}
                            </h2>
                            {item.delay_time !== "0" ? (
                              <div
                                style={{
                                  gap: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {/* {item.delay_time && <div>Delay Time: {item.delay_time}</div>} */}
                                {item.delay && <div>Delay: {item.delay}</div>}
                                {/* {item.primary_delay_reason && <div>Primary Delay Reason: {item.primary_delay_reason}</div>} */}
                                {item.primary_reason && (
                                  <div>
                                    Primary Reason: {item.primary_reason}
                                  </div>
                                )}
                                {/* {item.secondary_delay_reason && <div>Secondary Delay Reason: {item.secondary_delay_reason}</div>} */}
                                {item.secondary_reason && (
                                  <div>
                                    Secondary Reason: {item.secondary_reason}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div> No delays</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{}}>
        {widget && (
          <WidgetTimeUpdate
            route={ROUTE}
             
            updateIntervalMinutes={60}
          />
        )}
        {/* Upper */}
        <div
          style={{
            marginTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            overflowY: "scroll",
            height: layout.doubleWidgetHeight - 90,
          }}
        >
          <div
            style={{
              padding: 15,
              backgroundColor: widget ? theme.primary : theme.base,
              marginBottom: 15,
            }}
          >
            <div
              style={{
                borderBottom: "solid grey 1px",
                fontSize: layout.baseFontSize + 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {(airportDelays.length && airportDelays[0].location) ||
                  "None found"}
              </div>
              {moment(new Date()).format("MM/DD/YYYY")}
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {airportDelays.length > 0 &&
              airportDelays[0].segments &&
              Object.entries(
                groupSegmentsByDay(JSON.parse(airportDelays[0].segments))
              ).map(([day, segments], index) => {
                return (
                  // map each days segments to morning, afternoon, evening, and night
                  <div
                    key={index}
                    style={{
                      fontSize: layout.baseFontSize + 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: 20,
                      padding: 20,
                      backgroundColor: widget ? theme.primary : theme.base,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: layout.baseFontSize + 5,
                        marginBottom: 10,
                        borderBottom: "solid grey 1px",
                      }}
                    >
                      {new Date(
                        new Date(segments[0].iso8601).getTime() - 60000
                      ).toLocaleDateString("en-US")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {segments.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50%",
                              marginBottom: 30,
                            }}
                          >
                            <h2
                              style={{
                                borderBottom: "solid grey 1px",
                                width: "80%",
                                marginBottom: 10,
                              }}
                            >
                              {item.day_segment}{" "}
                            </h2>
                            {item.delay_time !== "0" ? (
                              <div
                                style={{
                                  gap: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {/* {item.delay_time && <div>Delay Time: {item.delay_time}</div>} */}
                                {item.delay && <div>Delay: {item.delay}</div>}
                                {/* {item.primary_delay_reason && <div>Primary Delay Reason: {item.primary_delay_reason}</div>} */}
                                {item.primary_reason && (
                                  <div>
                                    Primary Reason: {item.primary_reason}
                                  </div>
                                )}
                                {/* {item.secondary_delay_reason && <div>Secondary Delay Reason: {item.secondary_delay_reason}</div>} */}
                                {item.secondary_reason && (
                                  <div>
                                    Secondary Reason: {item.secondary_reason}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div> No delays</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
};
