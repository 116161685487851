import { ColorContext } from "App";
import TextToggle from "components/UI/TextToggle";
import React, { useContext, useEffect } from "react";
import { GetDeviceTypeString } from "utils/stringUtils";

export function VerifyToggleFlashingLights(dataObj) {
  if (!dataObj) return false;

  return !(dataObj?.status === "" || dataObj?.comment === "");
}

export default function ToggleFlashingLights({
  onUpdate,
  deviceList,
  dataObj,
}) {
  const theme = useContext(ColorContext);

  console.log("DataObj:", dataObj);

  useEffect(() => {
    onUpdate({
      node_id: "",
      headline: "",
      description: "",
      type: "",
      radius: 250,
      severity: 100,
      status: true,
      comment: "",
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        borderRadius: 10,
        margin: 10,
        background: theme.primary,
        display: "flex",
        gap: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Device Alias:</div>
        <select
          style={{ flex: 1, background: theme.base, padding: 5 }}
          onChange={(e) => {
            const device = deviceList.find((device) => {
              return device.node_id.toString() === e.target.value;
            });
            if (device)
              onUpdate({
                node_id: e.target.value,
                selectedDevice: device,
              });
          }}
          value={dataObj.node_id}
        >
          <option value="">-Select-</option>
          {deviceList &&
            deviceList.map((device) => {
              if (GetDeviceTypeString(device) === dataObj.deviceType)
                return <option value={device.node_id}>{device.alias}</option>;
            })}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Status</div>
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <TextToggle
            value={dataObj?.status}
            setValue={(value) => {
              onUpdate({ status: value });
            }}
            options={["Open", "Closed"]}
            optionColorOn={!dataObj?.status ? "white" : "#C73B1D"}
            optionTextColorOn={!dataObj?.status ? "black" : "white"}
            optionBorder1="2px solid #01b574"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Comments</div>
        <textarea
          rows={4}
          type="text"
          value={dataObj.comment}
          style={{
            border: "1px solid white",
            width: "75%",
            padding: 5,
          }}
          onChange={(e) => onUpdate({ comment: e.target.value })}
          placeholder="(Optional)"
        />
      </div>
    </div>
  );
}
