import { ColorContext } from "App";
import React, { useContext, useState } from "react";
import microsoftlogo from "assets/microsoftlogo.png";

export const RoleDropdown = ({
  item,
  setFormData,
  formData,
  lockedRolesList,
}) => {
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        background: (lockedRolesList || []).includes(item.role_Id)
          ? "grey"
          : formData.roles[item.role_name]
          ? theme.primaryShadow
          : "",
        padding: 5,
        pointerEvents: (lockedRolesList || []).includes(item.role_Id) ? "none" : "auto",
        // borderRadius: 5,
      }}
      onClick={() => {
        setFormData({
          ...formData,
          roles: {
            ...formData.roles,
            [item.role_name]: !formData.roles[item.role_name],
          },
        });
      }}
    >
      {(lockedRolesList || []).includes(item.role_Id) ? (
        <img src={microsoftlogo} style={{ height: 12, marginLeft: 1 }} />
      ) : (
        <input
          type="checkbox"
          checked={
            (lockedRolesList || []).includes(item.role_Id) ||
            formData.roles[item.role_name]
          }
          readOnly
        />
      )}{" "}
      {item.role_name}
    </div>
  );
};
