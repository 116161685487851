import React, { useContext, useEffect, useState } from 'react'
import * as api from "apis/FusionAPI";
import { UpdatePassword } from './UpdatePassword';
import { ColorContext } from 'App';

export const UserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
      
  const [inputUserInfo, setInputUserInfo] = useState({
    name: '',
    email: '',
    employeeId: '',
    phoneNumber: '',
    dob: '',
    gender: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipcode: ''
    },
    emergencyContact: {
      name: '',
      phone: ''
    }
  });
  
  const theme = useContext(ColorContext);
  
  useEffect(() => {
    (async () => {
      try {
        // api.getFusionUserInfo().then((res) => {
          api.getUserProfileInfo().then((res) => {
          const fixedData = {
            ...res.data.result,
            emergencyContact: {
              name: JSON.parse(res.data.result.emergencyContact).name || "",
              phone: JSON.parse(res.data.result.emergencyContact).phone || ""
            },
            address: {
              street: JSON.parse(res.data.result.address).street || "",
              city: JSON.parse(res.data.result.address).city || "",
              state: JSON.parse(res.data.result.address).state || "",
              zipcode: JSON.parse(res.data.result.address).zipcode || ""
            }
            
          }
          setInputUserInfo(fixedData);
          
        })
        // make api call to get user info
        // setInputUserInfo(data);
      } catch (e) {
        
      }
    }
    )()
  }
  , []);

  const handleSubmit = () => {
    // make api call to update user info
    (async () => {
      try {
        // make api call to set user info
        api.updateUserProfile(
          // inputUserInfo.userId,
          // inputUserInfo.role,
          // inputUserInfo.base,
          // inputUserInfo.department,
          // inputUserInfo.rank,
          // inputUserInfo.name,
          // inputUserInfo.employeeId,
          inputUserInfo.phoneNumber,
          inputUserInfo.gender,
          inputUserInfo.dob,
          inputUserInfo.address,
          inputUserInfo.emergencyContact,
        ).then((res) => {
          
          setMessage("Account information updated successfully")
          setError(null)
        }).catch((e) => {
          setError("Error updating user info: " + e.response.data.message)
          setMessage(null)
          
        })
      } catch (e) {
  
        setError("Error updating user info: " + e.response.data.message)
        setMessage(null)
        
      }
    }
    )()
  }

  return (
    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', width: "100%", height:"100%", overflow:'auto'}}>
      <div>
        {!showChangePassword && <div><div style={{fontSize: 24, fontWeight:'bold'}}>Information</div>
          <div style={{display:'flex', flexDirection:'row', gap:50, marginTop: 20}}>
            <InputItem
              editable={false}
              title="Name"
              value={inputUserInfo.name}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, name: value})
              }}
            />
            <InputItem
              editable={false}
              title="Email"
              value={inputUserInfo.email}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, email: value})
              }}
            />
            <InputItem
              editable={false}
              title="Employee ID"
              value={inputUserInfo.employeeId}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, employeeId: value})
              }}
            />
          </div>
          <div style={{display:'flex', flexDirection:'row', gap:50, marginTop: 20}}>
            <InputItem
              editable={true}
              title="Phone Number"
              type="tel"
              value={inputUserInfo.phoneNumber}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, phoneNumber: value})
              }}
            />
            <InputItem
              editable={true}
              title="Birthday"
              type="date"
              value={inputUserInfo.dob}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, dob: value})
              }}
            />
            <InputItem
              editable={true}
              title="Gender"
              inputType='select'
              options={[
                "Female",
                "Male",
                "Not Specified"
              ]}
              value={inputUserInfo.gender}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, gender: value})
              }}
            />


          </div>
          <div style={{fontSize: 24, fontWeight:'bold', marginTop: 40}}>Home Address</div>
          <div style={{display:'flex', flexDirection:'row', gap:50, marginTop: 20}}>
            <InputItem
              editable={true}
              title="Street"
              value={inputUserInfo.address.street}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, address: {...inputUserInfo.address, street: value}})
              }}
            />
            <InputItem
              editable={true}
              title="City / County"
              value={inputUserInfo.address.city}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, address: {...inputUserInfo.address, city: value}})
              }}
            />
            <InputItem
              editable={true}
              title="State"
              value={inputUserInfo.address.state}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, address: {...inputUserInfo.address, state: value}})
              }}
            />
            <InputItem
              editable={true}
              title="Zipcode"
              value={inputUserInfo.address.zipcode}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, address: {...inputUserInfo.address, zipcode: value}})
              }}
            />
          </div>
          <div style={{fontSize: 24, fontWeight:'bold', marginTop: 40}}>Emergency Contact</div>
          <div style={{display:'flex', flexDirection:'row', marginTop: 20, justifyContent:'space-between', alignItems: 'flex-end'}}>
            <div style={{display:'flex', flexDirection:'row', gap:50, paddingBottom: 10}}>
            <InputItem
              editable={true}
              title="Name"
              value={inputUserInfo.emergencyContact.name}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, emergencyContact: {...inputUserInfo.emergencyContact, name: value}})
              }}
            />
            <InputItem
              editable={true}
              title="Phone"
              type="tel"
              value={inputUserInfo.emergencyContact.phone}
              setValue={(value) => {
                setInputUserInfo({...inputUserInfo, emergencyContact: {...inputUserInfo.emergencyContact, phone: value}})
              }}
            />
            </div>
            
          </div>
        </div>}
      </div>
      <div>
      {showChangePassword && <UpdatePassword
        setShowChangePassword={setShowChangePassword}
      />}
      {!showChangePassword && <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginTop: 50}}>
          
          <div>
            <button onClick={() => setShowChangePassword(true)} style={{padding: 10, background: theme.primary, borderRadius:20}}>Change Password</button> 
          </div>
          <div>{error && <div style={{color:'red'}}>{error}</div>}
          {message && <div style={{color:'white'}}>{message}</div>}
          <button onClick={handleSubmit} style={{padding: "20px 65px", background: theme.primaryHighlight, borderRadius:10}}>Save</button> 
          </div>
      </div>}
      </div>
    </div>
  )
}

export const InputItem = ({editable, title, value, setValue, type = "text", width, inputType = "input", options}) => {
  
  const theme = useContext(ColorContext);
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: "100%",
    }}>
      <div>
        {title}
      </div>
      {inputType === "input" && <input style={{
        // width: 200,
        minWidth: 0,
        padding: 5,
        borderRadius: 5,
        marginTop: 5,
        background: editable ? theme.primary : theme.disabled,
      }}
        value={value}
        type={type}
        disabled= {!editable}
        onChange={(e) => setValue(e.target.value)}
      >
        
      </input>}
      {inputType === "select" && <select style={{
        // width: 200,
        minWidth: 0,
        padding: 5,
        borderRadius: 5,
        marginTop: 5,
        background: editable ? theme.primary : theme.disabled,
      }}
        value={value}
        type={type}
        disabled= {!editable}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((option) => {
          return (
            <option value={option}>{option}</option>
          )})
        }
      </select>}
    </div>
  )
}