import React, { useState, useEffect, useContext } from "react";

import InfoPanel from "./components/InfoPanel";
import MapPanel from "./components/MapPanel";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import * as api from "apis/FusionAPI";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { UniqueWidgetRefreshContext, LayoutSizeContext } from "App";
import Cookies from "js-cookie";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache;

export default function SevereWeatherAlertsMap({
  fullScreen = false,
  widgetRefresh,
  setWidgetRefresh,
  preview,
  dimensions = [0, 0],
  sidebarOpen,
}) {
  const layoutSize = useContext(LayoutSizeContext);

  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [error, setError] = useState({ state: false, message: "" });
  const [fetching, setFetching] = useState(true);
  const [alerts, setAlerts] = useState([]);

  const ROUTE = "getAllActiveAlerts";
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  useEffect(() => {
    
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    (async () => {
      try {
        const response = await api.getAllActiveAlerts(); //await FusionData.get("getAllActiveAlerts");
        // const response = await FusionData.get(ROUTE);
        dataCache = response.data.alerts;
        setAlerts(dataCache);
        setFetching(false);
      } catch (err) {
        
        setError({ state: true, message: err.message });
      }
    })();
  }, [dataRefresh.widgetRefresh]);


  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(
    //   () => {
    //     return;
    //   },
    //   (error) => {
    //     if (error.code === error.PERMISSION_DENIED) {
    //       setError(
    //         "Location permission was denied. Please enable it in your browser settings.",
    //       );
    //     }
    //   },
    // );
  }, []);

  const uwf = useContext(UniqueWidgetRefreshContext);
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;
  // const [oldRouteData, setOldRouteData] = useState(uwf[ROUTE]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.getAllActiveAlerts(); //await FusionData.get("getAllActiveAlerts");
        
        // const res = await FusionData.forceGet(ROUTE);
        dataCache = res.data.alerts;
        setAlerts(dataCache);
        // setFetching(false);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"SevereWeatherAlerts"}
        dimensions={dimensions}
      />
    );

  if (fullScreen)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WidgetTimeUpdate realtime route={"getAllActiveAlerts"} addMargin />
        <div style={{ flex: 1, overflow: "hidden" }}>
          <MapPanel
            fullScreen={fullScreen}
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
            selectedZone={selectedZone}
            setSelectedZone={setSelectedZone}
            setFetching={setFetching}
            alerts={alerts}
            setAlerts={setAlerts}
            sidebarOpen={sidebarOpen}
          />
        </div>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <InfoPanel
            fullScreen={fullScreen}
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
            selectedZone={selectedZone}
          />
        </div>
      </div>
    );

  if (dimensions && dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate realtime route={"getAllActiveAlerts"} />
        <MapPanel
          fullScreen={fullScreen}
          selectedAlert={selectedAlert}
          setSelectedAlert={setSelectedAlert}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          alerts={alerts}
          setAlerts={setAlerts}
          preview={preview}
          sidebarOpen={sidebarOpen}
        />
        <div
          style={{
            marginLeft: 10,
            height: "50%",
            overflow: "auto",
          }}
        >
          <InfoPanel
            fullScreen={fullScreen}
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
            selectedZone={selectedZone}
          />
        </div>
      </div>
    );

  if (dimensions && dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate realtime route={"getAllActiveAlerts"} />
        <MapPanel
          fullScreen={fullScreen}
          selectedAlert={selectedAlert}
          setSelectedAlert={setSelectedAlert}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          alerts={alerts}
          setAlerts={setAlerts}
          includePopup
          preview={preview}
          sidebarOpen={sidebarOpen}
        />
      </div>
    );

  if (dimensions && dimensions[0] == 2 && dimensions[1] == 1)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate realtime route={"getAllActiveAlerts"} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginLeft: 10,
              height: "calc(100% - 30px)",
              overflow: "auto",
              width: "100%",
            }}
          >
            <InfoPanel
              fullScreen={fullScreen}
              selectedAlert={selectedAlert}
              setSelectedAlert={setSelectedAlert}
              selectedZone={selectedZone}
            />
          </div>
          <MapPanel
            fullScreen={fullScreen}
            selectedAlert={selectedAlert}
            setSelectedAlert={setSelectedAlert}
            selectedZone={selectedZone}
            setSelectedZone={setSelectedZone}
            alerts={alerts}
            setAlerts={setAlerts}
            preview={preview}
            sidebarOpen={sidebarOpen}
          />
        </div>
      </div>
    );
}
