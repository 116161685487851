import { ColorContext } from 'App'
import React, { useContext, useState } from 'react'
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri'
import { splitByCharacter } from 'utils/stringUtils'

const NWSAlert = ({alert}) => {
  const [maximized, setMaximized] = useState(false)

  
  const theme = useContext(ColorContext);
  
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: theme.primary,
        margin: 10,
        marginTop: 15,
        borderRadius: 5,
      }}
    >
      <div style={{ margin: 15, width: "100%" }}>
        <div
        onClick={() => {setMaximized(!maximized)}}
          style={{
            cursor: "pointer",
            fontSize: 20,
            fontWeight: "bold",
            borderBottom: maximized ? "solid grey 1px" : "",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {`${alert.type} - ${alert.sender} `}
          <div>{alert.sentTime} 
          {maximized ? (
                <RiArrowDownSLine size={24} />
              ) : (
                <RiArrowRightSLine size={24} />
              )}
          </div>
        </div>
      { maximized && <div>
        
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
            <div style={{ marginTop: 15}}>Issued by: {alert.sender}</div>
            <div style={{ marginTop: 15}}>Broadcast Time: {alert.sentTime}</div>
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <div style={{ marginTop: 15}}>Valid Until: {alert.endTime}</div>
          <div style={{ marginTop: 15}}>Issuing Time: {alert.startTime}</div>
        </div>
            
          <div style={{ marginTop: 35, marginBottom: 15, fontWeight: "bold",}}>
            {/* {alert.description} */}
            {splitByCharacter(alert.description, "*").map((line, index) => {
              if (line.trim() !== "") return (
                <li key={index} style={{ marginBottom: 10 }}>
                  {line}
                </li>
              );
            })}
          </div>
        </div> }
      </div>
    </div>
  )
}

export default NWSAlert