import { ColorContext, UniqueWidgetRefreshContext } from "App";
import DropDownSpread from "components/DropDownSpread";
import ForecastSpread from "components/ForecastSpread";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { useAPIData } from "hooks/useAPIData";
import useDataRefresh from "hooks/useDataRefresh";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { DateToString } from "utils/stringUtils";
import { FixOutputOfEndpointToTheCorrectFormat_TideData } from "utils/utilsBecauseSQLSucks";

export default function TideTable(
  {
    // data
  }
) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getTideTable";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  const theme = useContext(ColorContext);
  useEffect(() => {
    async function fetchData() {
      try {
        //get 15 day forecast
        FusionData.forceGet(ROUTE).then((res) => {
          

          res.data.result.map((item) => {
            if (typeof item.tide === "string") {
              item.tide = JSON.parse(item.tide);
            }
          });

          const fixedData = FixOutputOfEndpointToTheCorrectFormat_TideData(res);

          setData([...fixedData]);
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.get(ROUTE).then((res) => {
          

          res.data.result.map((item) => {
            if (typeof item.tide === "string") {
              item.tide = JSON.parse(item.tide);
            }
          });

          const fixedData = FixOutputOfEndpointToTheCorrectFormat_TideData(res);

          setData([...fixedData]);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  return (
    <div
      className="scrollbar-hidden"
      style={{
        ddisplay: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
      }}
    >
      <WidgetTimeUpdate route={ROUTE} dataUpdateOnly />
      {/* No data */}
      {(!data || data.length == 0) && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: theme.base,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Data for this Location
        </div>
      )}

      {/* Data */}
      {data &&
        data?.length > 0 &&
        data.map((item, index) => {
          return (
            <DropDownSpread
              key={index}
              title={item.location}
              style={{}}
              content={
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "solid grey 2px",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {item.location}
                    <div>
                      {DateToString(new Date()).month}{" "}
                      {DateToString(new Date()).day}{" "}
                      {DateToString(new Date()).year}
                    </div>
                  </div>
                  <div
                    className="scrollbar-hidden"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      // width: "50%",
                      gap: 10,
                      maxHeight: 250,
                      overflow: "auto",
                      marginTop: 15,
                    }}
                  >
                    {item.forecast.map((forecast, findex) => {
                      const dateStr = DateToString(new Date(forecast.iso8601));
                      const sortedTides = forecast.tide.sort((a, b) => {
                        return timeSortValue(a, b);
                      });

                      

                      return (
                        <div
                          key={findex}
                          style={{
                            width: "50%",
                            // backgroundColor: "red"
                            marginBottom: 20,
                            // borderBottom: "solid grey 2px",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              // alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              // fontWeight: "bold",
                              fontSize: 20,
                              marginBottom: 10,
                            }}
                          >
                            {`${dateStr.month} ${dateStr.day} ${dateStr.year}`}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              // alignItems: "center",
                            }}
                          >
                            <div>
                              <div style={{ marginBottom: 5 }}>Set One</div>
                              <div>{`${sortedTides[0].time} ${sortedTides[0].height} ft HIGH`}</div>
                              <div>{`${sortedTides[2].time} ${sortedTides[2].height} ft LOW`}</div>
                            </div>
                            <div>
                              <div style={{ marginBottom: 5 }}>Set Two</div>
                              <div>{`${sortedTides[1].time} ${sortedTides[1].height} ft HIGH`}</div>
                              <div>{`${sortedTides[3].time} ${sortedTides[3].height} ft LOW`}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
            />
          );
        })}
    </div>
  );
}

export function timeSortValue(a, b) {
  let scoreA = 0;
  let scoreB = 0;

  if (a.time.includes("PM")) scoreA += 1;
  if (a.time.includes("AM")) scoreA += 2;
  if (a.type.includes("HIGH")) scoreA += 10;
  if (a.time.includes("LOW")) scoreA += 20;

  if (b.time.includes("PM")) scoreB += 1;
  if (b.time.includes("AM")) scoreB += 2;
  if (b.type.includes("HIGH")) scoreB += 10;
  if (b.time.includes("LOW")) scoreB += 20;

  return scoreB - scoreA;
}
