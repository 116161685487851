import React from "react";
import { Polygon } from "react-leaflet";
import * as d3 from "d3";
import { alertColorScale } from "utils/alertUtils";

export default function CustomAlert({
  alert,
  setSelectedAlert,
  setSelectedZone,
  setMapCenter,
  setSelectedPosition,
}) {
  return (
    <Polygon
      positions={JSON.parse(alert.polygon)}
      pathOptions={{
        fillColor: alertColorScale(alert.severity),
        fillOpacity: 0.5,
      }}
      eventHandlers={{
        click: () => {
          setSelectedZone(null);
          setSelectedAlert(alert);
          const positions = JSON.parse(alert.polygon).map(([lat, lng]) => [
            lng,
            lat,
          ]);
          let xSum = 0;
          let ySum = 0;
          positions.forEach(([x, y]) => {
            xSum += x;
            ySum += y;
          });
          const centroid = [xSum / positions.length, ySum / positions.length];
          setMapCenter(centroid.reverse());
          setSelectedPosition(centroid.reverse());
        },
      }}
    />
  );
}
