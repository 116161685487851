import React, { useContext, useEffect, useState } from "react";

import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import * as api from "../apis/FusionAPI";

//icons
import {
  FaRegUser,
  FaLocationDot,
  FaSignsPost,
  FaChevronDown,
  FaUser,
} from "react-icons/fa6";
import { IoInformationCircle } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { PiPersonArmsSpreadFill } from "react-icons/pi";
import { FiExternalLink, FiLogOut } from "react-icons/fi";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";

import { GetLocationData } from "../data/routes";
import { width } from "@mui/system";
import PlacesSelector from "./PlacesSelector";
import WidgetRefreshButton from "./Widgets/WidgetRefreshButton";
import { GetRouteData } from "data/routeData";
import { usePerms } from "hooks/usePerms";
import AlarmHistory from "pages/Overwatch/AlarmHistory";
import useAlarms from "hooks/useAlarms";
import useTextToSpeech from "hooks/useTextToSpeech";
import { clearCookies } from "utils/cookieUtils";
import { ColorContext } from "App";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

//EXTERNAL LINK TO RESCALERT
const externUrl = "https://www.rescalert.com";

export default function Header({
  sidebarOpen,
  widgetRefresh,
  setWidgetRefresh,
  setIsAuthenticated,
  setShowFull,
  setNewTermsFullyAgreed,
  setTermsFullyViewed,
  isAuthenticated,
  setHasSignedIn
}) {
  const navigate = useNavigate();
  const [userLocation, setUserLocation] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const perms = usePerms();
  const alarms = useAlarms();
  const tts = useTextToSpeech();
  const theme = useContext(ColorContext);

  useEffect(() => {
    let data;

    if (window.location.pathname === "/dashboard") {
      data = { icon: () => <FaHome />, displayName: "Dashboard" };
    } else {
      const sideBarData = GetRouteData(perms);

      sideBarData.forEach((section) => {
        section.options.forEach((option) => {
          if (option.href === window.location.pathname)
            data = { icon: option.icon, displayName: option.name };
        });
      });
    }

    setRouteData(data);
  }, [window.location.pathname]);

  useEffect(() => {
    
  }, []);

  const onSignOut = () => {
    //clear auth cookie

    navigate("/");
  };

  const HeaderButton = ({ text, onClick, icon, styleOverride }) => {
    return (
      <button onClick={onClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: theme.primaryHighlight,
            borderRadius: 50,
            width: 200,
            height: 32,
            fontSize: 15,
            ...styleOverride,
          }}
        >
          <div>
            <div
              style={{
                marginLeft: 3,
                display: "flex",
                backgroundColor: theme.background,
                width: 27,
                height: 27,
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </div>
          </div>
          <div style={{ marginLeft: 5 }}>{text}</div>
        </div>
      </button>
    );
  };

  return (
    <div
      style={{
        userSelect: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.primaryShadow,
        height: 107,
        width: "100%",
        color: theme.fontColor,
      }}
    >
      {routeData && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: 25,
            alignItems: "center",
          }}
        >
          <IconContext.Provider value={{ color: theme.fontColor, size: 45 }}>
            <div>{routeData.icon()}</div>
          </IconContext.Provider>
          <div style={{ marginLeft: 15, fontSize: 35 }}>
            {routeData.displayName}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: 25,
          alignItems: "center",
        }}
      >
        {/* {userLocation && ( */}
        <div
          style={{
            marginRight: 15,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 24,
          }}
        >
          {!window.location.pathname.includes("usermenu") && (
            <PlacesSelector
              widgetRefresh={widgetRefresh}
              setWidgetRefresh={setWidgetRefresh}
            />
          )}
        </div>

        <div style={{ marginRight: 15 }}>
          <HeaderButton
            text={"Overwatch Community"}
            icon={
              <IconContext.Provider
                value={{
                  color: theme.fontColor,
                  size: 12,
                }}
              >
                <FiExternalLink />
              </IconContext.Provider>
            }
            onClick={() =>
              window.open("http://www.overwatch.ai/", "_blank", "noreferrer")
            }
          />
        </div>
        <div style={{ marginRight: 15 }}>
          <Popup
            on="hover"
            mouseLeaveDelay={300}
            trigger={
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 50,
                    width: 175,
                    height: 32,
                    fontSize: 15,
                  }}
                >
                  <div>
                    <div
                      style={{
                        marginLeft: 3,
                        display: "flex",
                        backgroundColor: theme.background,
                        width: 27,
                        height: 27,
                        borderRadius: 50,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconContext.Provider
                        value={{
                          color: theme.fontColor,
                          size: 12,
                        }}
                      >
                        <FaRegUser />
                      </IconContext.Provider>
                    </div>
                  </div>
                  <div style={{ marginLeft: 5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>User Menu</div>
                      <div style={{ marginLeft: 40, marginTop: 5 }}>
                        <IconContext.Provider
                          value={{
                            color: theme.fontColor,
                            size: 15,
                          }}
                        >
                          <FaChevronDown />
                        </IconContext.Provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            position="bottom right"
            closeOnDocumentClick
            closeOnEscape
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#2c486d",
                color: theme.fontColor,
                // width: 300,
                height: 165,
                alignItems: "center",
                justifyContent: "center",
                border: "solid black 1px",
              }}
            >
              <div
                style={{
                  // height: 165 / 4,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "solid grey 1px",
                  backgroundColor: "#1b3047",
                  paddingRight: 5,
                }}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    justifySelf: "flex-start",
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: theme.fontColor,
                      size: 20,
                    }}
                  >
                    <FaRegUserCircle />
                  </IconContext.Provider>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Signed in as:</div>
                  <div> {Cookies.get("username")}</div>
                </div>
              </div>
              <a
                style={{
                  // height: 165 / 4,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "solid grey 1px",
                  color: theme.fontColor,
                }}
                href={"/usermenu?tab=0"}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    justifySelf: "flex-start",
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: theme.fontColor,
                      size: 20,
                    }}
                  >
                    <FaUser />
                  </IconContext.Provider>
                </div>
                <div>Edit Account</div>
              </a>
              <a
                style={{
                  // height: 165 / 4,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "solid grey 1px",
                  color: theme.fontColor,
                }}
                href={"/usermenu?tab=2"}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    justifySelf: "flex-start",
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: theme.fontColor,
                      size: 20,
                    }}
                  >
                    <PiPersonArmsSpreadFill />
                  </IconContext.Provider>
                </div>
                <div>Accessibility Settings</div>
              </a>
              <a
                style={{
                  // height: 165 / 4,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "solid grey 1px",
                  color: theme.fontColor,
                }}
                href={"/usermenu?tab=3"}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    justifySelf: "flex-start",
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: theme.fontColor,
                      size: 20,
                    }}
                  >
                    <IoInformationCircle />
                  </IconContext.Provider>
                </div>
                <div>About</div>
              </a>
              <button
                style={{
                  // height: 165 / 4,
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  
                  // setShowFull(Cookies.get("rememberMe") !== "true");
                  if (isMsalAuthenticated) {
                    // instance.removeAccount(accounts[0]);
                    instance.logoutRedirect({
                      onRedirectNavigate: (url) => {
                        return false
                      }
                    })
                  }
                  setShowFull(true);
                  if (isAuthenticated) api.logout();
                  let keepCookies = [];
                  if (Cookies.get("rememberMe") === "true") {
                    keepCookies = ["rememberMe", "email"];
                  }
                  clearCookies(keepCookies);
                  perms.reset();
                  tts.cancel();
                  setIsAuthenticated(false);
                  setNewTermsFullyAgreed(false);
                  setTermsFullyViewed(false);
                  setHasSignedIn(false);
                  navigate("/");
                }}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    justifySelf: "flex-start",
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: theme.fontColor,
                      size: 20,
                    }}
                  >
                    <FiLogOut />
                  </IconContext.Provider>
                </div>
                <div>Logout</div>
              </button>
            </div>
          </Popup>
        </div>
        {/* <div style={{ marginRight: 15 }}>
          <HeaderButton
            text={"Logout"}
            icon={
              <IconContext.Provider
                value={{
                  color: theme.fontColor,
                  size: 12,
                }}
              >
                <FiLogOut />
              </IconContext.Provider>
            }
            onClick={() => {
              
              Cookies.remove("accessToken");
              Cookies.remove("refreshToken");
              Cookies.remove("email");

              navigate("/");
            }}
          />
        </div> */}
      </div>
    </div>
  );
}
