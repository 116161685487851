import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import * as api from "../../apis/FusionAPI";
import Popup from "reactjs-popup";

import { FaCopy } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { RoleDropdown } from "./RoleDropdown";
import RoleInput from "./RoleInput";
import UserEditMenu from "./UserEditMenu";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";

export default function EditUser() {
  const navigate = useNavigate();

  const theme = useContext(ColorContext);

  const [params, setParams] = useSearchParams();

  const [checks, setChecks] = useState({
    role: true,
    base: true,
    department: true,
    rank: true,
    name: true,
    email: true,
    roles: true,
  });

  const [formData, setFormData] = useState({
    role: -1,
    base: "",
    department: "",
    rank: "",
    name: "",
    email: "",
    employeeId: "",
    phoneNumber: "",
    gender: "",
    birthday: "",
    address: {
      street: "",
      city: "",
      state: "",
      zipcode: "",
    },
    emergencyContact: {
      name: "",
      phone: "",
    },
    roles: {},
    isAdmin: false,
  });

  const [apiResonse, setApiResponse] = useState({ status: -1 });
  const [roleList, setRoleList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const [lockedRolesList, setLockedRolesList] = useState([]);

  const perms = usePerms();
  
  

  useEffect(() => {
    (async () => {
      const roleData = await api.getAllRoles().then((result) => {
        setRoleList(result.data.result);
        
        return result.data.result;
      });

      //get user's information and set formData to that information
      await api.getUserProfileInfo(params.get("userid")).then((res) => {
        
        let roleIndex = -1;
        setLockedRolesList(JSON.parse(res.data.result.ms_roles));

        

        for (let i = 0; i < roleData.length; i++) {
          if (roleData[i].role_Id === parseInt(res.data.result.role)) {
            roleIndex = i;
            break;
          }
        }

        let roleNames = [];
        let roles = {};
        if (res.data.result.roles) {
          JSON.parse(res.data.result.roles).forEach((role) => {
            roleNames.push(
              roleData.find((item) => item.role_Id === role).role_name
            );
          });
          roleNames?.forEach((role, index) => {
            roles[role] = true;
          });
        }

        

        setFormData({
          role: roleIndex,
          base: res.data.result.homebase,
          department: res.data.result.department,
          rank: res.data.result.rank,
          name: res.data.result.name,
          email: res.data.result.email,
          employeeId: res.data.result.employeeId,
          phoneNumber: res.data.result.phoneNumber,
          gender: res.data.result.gender,
          birthday: res.data.result.dob,
          address: JSON.parse(res.data.result.address),
          emergencyContact: JSON.parse(res.data.result.emergencyContact),
          roles: roles,
          isAdmin: res.data.result.permission_level === 12,
        });
      });
    })();
  }, []);

  const onSubmit = async () => {
    const newChecks = { ...checks };
    newChecks.role = formData.role > -1;
    newChecks.base = formData.base !== "";
    newChecks.department = formData.department !== "";
    newChecks.rank = formData.rank !== "";
    newChecks.name = formData.name !== "";
    newChecks.email = formData.email !== "";

    let roleCheck = false;
    for (let role in formData.roles) {
      if (formData.roles[role] == true) {
        roleCheck = true;
        break;
      }
    }
    newChecks.roles = roleCheck;

    setChecks({ ...newChecks });

    if (
      // newChecks.role &&
      newChecks.base &&
      newChecks.department &&
      newChecks.rank &&
      newChecks.name &&
      newChecks.email &&
      newChecks.roles
    ) {
      //make api call
      
      const temp = {
        ...formData,
        address: JSON.stringify(formData.address),
        emergencyContact: JSON.stringify(formData.emergencyContact),
      };
      let roleids = [];
      for (let role in temp.roles) {
        if (temp.roles[role]) {
          roleids.push(
            roleList.find((item) => item.role_name === role).role_Id
          );
        }
      }
      temp.roles = roleids;
      
      api
        .updateUserProfileByAdmin(
          params.get("userid"),
          temp.roles,
          temp.base,
          temp.department,
          temp.rank,
          temp.name,
          temp.employeeId,
          temp.phoneNumber,
          temp.gender,
          temp.birthday,
          temp.address,
          temp.emergencyContact,
          temp.isAdmin
        )
        .then((result) => {
          
          if (result.data.message === "Success") {
            setApiResponse({ status: 201 });
            navigate("/system/rolemanager?tab=1");
          }
        })
        .catch((e) => {
          
          setApiResponse({ status: 500, message: e.response.data.message });
        });
    }
  };

  const onDiscard = () => {
    navigate("/system/rolemanager?tab=1");
  };

  return (
    <UserEditMenu
      checks={checks}
      setChecks={setChecks}
      formData={formData}
      setFormData={setFormData}
      roleList={roleList}
      apiResonse={apiResonse}
      setApiResponse={setApiResponse}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      copiedText={copiedText}
      setCopiedText={setCopiedText}
      perms={perms}
      mode="edit"
      lockedRolesList={lockedRolesList}
      editable={true}
    />
  );
}
