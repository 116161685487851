import { ColorContext } from 'App';
import React, { useContext } from 'react'

export const FactOfTheDay = ( { data } ) => {
  
  const theme = useContext(ColorContext);
  return (
    <div>
      {/* Upper */}
      <div
        style={{
          backgroundColor: theme.base,
          marginTop: 15,
        }}
      >
        <div
          style={{
            padding: 15,
          }}
        >
          <div
              style={{
                marginBottom: 15,
                borderBottom: "solid grey 1px",
                fontSize: 30,
              }}
            >
              Fact Of The Day
            </div>
        </div>
      
        <div
          style={{
            padding: 15,
          }}
        >
          {data.map((fact, index) => {
            return (
              <div
                key={index}
                style={{
                  marginBottom: 30,
                }}
              >
                <div
                  style={{
                    fontSize: 25,
                    borderBottom: "solid grey 1px",
                  }}
                >
                {fact.date}
                </div>
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                {fact.fact}
                </div>
              </div>
            )
          })}
          </div>
      </div>
    </div>
  )
}
