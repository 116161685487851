import React from "react";
import { IconContext } from "react-icons";

/**
 * Vertical Forecast spread
 * @param {string} header Text that will be displayed on top of content
 * @param {Array} items Content that will be mapped through and rendered. First element is always the large one. Style is optional property of each object. Example: [{icon: *, content: *, style: *}]
 * @returns
 */
export default function VerticalForecastSpread({
  header,
  headerStyle,
  items,
  style,
}) {
  const largeStyle = {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
    alignItems: "center",
  };

  const normalStyle = {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    marginLeft: "25%",
    alignItems: "center",
    justifyContent: "left",
  };

  const largeIconValue = {
    size: 150,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      {/* Header */}
      <div style={{ fontSize: 20, fontWeight: "bold", ...headerStyle }}>
        {header}
      </div>

      {/* Content */}
      {items && (
        <div>
          <div style={{ ...largeStyle, ...items[0].style }}>
            <IconContext.Provider value={largeIconValue}>
              {items[0].icon}
            </IconContext.Provider>
            {items[0].content}
          </div>
          <div>
            {items
              .filter((item, index) => index !== 0)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    style={
                      { ...normalStyle, ...item.style } //only use largeStyle for the first element
                    }
                  >
                    <div style={{ marginRight: 15 }}>{item.icon}</div>
                    <div>{item.content}</div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
