import React, { useEffect, useState } from "react";
import NWSAlert from "./NWSAlert";
import { AlertCard } from "components/AlertCard";
import NWSAlertsList from "./displays/NWSAlertsList";

export default function NWSAlerts({ alerts, title }) {
  const date = new Date().toLocaleDateString();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ margin: 15 }}>
          <div
            style={{
              fontSize: 30,
              fontWeight: "bold",
              width: "100%",
              borderBottom: "solid grey 1px",
            }}
          >
            {date} {title}
          </div>
        </div>
      </div>
      <NWSAlertsList alerts={alerts} />
    </div>
  );
}
