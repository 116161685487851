import React, { useEffect, useState } from 'react'
import NWSAlert from '../NWSAlert'

const NWSAlertsList = ( { alerts }) => {

  return (
    <div style={{overflowY:"auto", overflowX:'hidden', height:"100%",}}>
    { 
      alerts.length > 0 && alerts.map((alert, index) => {
       return (
        <div key={index}>
          <NWSAlert alert={alert} />
          {/* <AlertCard alert={alert} /> */}
        </div>
       )
      }) 
    }
    {
      alerts.length === 0 && <div style={{ margin: 15 }}>No alerts</div>
    }</div>
  )
}

export default NWSAlertsList