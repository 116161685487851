import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { DateToString } from "utils/stringUtils";
import ForecastSpread from "components/ForecastSpread";
import WidgetTimeUpdate from "./WidgetTimeUpdate";
import WidgetLoadingAnimation from "./WidgetLoadingAnimation";
import { ErrorMessage } from "./ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { WiDaySunnyOvercast } from "react-icons/wi";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";

const sortOrder = {
  Morning: 0,
  Afternoon: 1,
  Evening: 2,
  Night: 3,
};

let dataCache;
 

export default function SurfAlertsWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) {
  const dateTime = new Date();

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState({ state: false, message: "" });
  const [data, setData] = useState();
  useEffect(() => {
    dataCache = null;
    setFetching(true);
  }, [widgetRefresh]);

  const theme = useContext(ColorContext);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  }); 
  
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedMarine7"
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      dataCache = null;
      try { FusionData.forceGet(ROUTE)
        .then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          dataCache = { ...daySorted };
          setData(dataCache);
        })
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  useEffect(() => {
    (async () => {
      if (dataCache) {
        setData(dataCache);
        setFetching(false);
        return;
      }
      FusionData.get(ROUTE)
        .then((res) => {
          let daySorted = {};

          res.data.result.forEach((item) => {
            let rawDate = new Date(item.iso8601);
            let adjDate = new Date(
              rawDate.setHours(rawDate.getHours() - 2)
            ).setHours(0);
            let date = new Date(adjDate);

            if (
              Object.keys(daySorted)
                .map((key) => {
                  return key == date;
                })
                .includes(true)
            ) {
              daySorted[date] = {
                ...daySorted[date],
                [item.day_segment]: item,
              };
            } else {
              daySorted = {
                ...daySorted,
                [date]: {
                  [item.day_segment]: item,
                },
              };
            }
          });

          dataCache = { ...daySorted };
          setData(dataCache);
           
          setFetching(false);
        })
        .catch((err) => {
          
          setError({ state: true, message: err.message });
        });
    })();
  }, [widgetRefresh]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true)
  if (fetching)
    return (
      <WidgetLoadingAnimation
        widgetName={"SurfAlert"}
        dimensions={dimensions}
      />
    );

  if (data && Object.keys(data).length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (dimensions[0] == 2 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}    />
          {Object.keys(data)?.length > 0 && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                // height: "90%",
                // height: 400,
                height: "calc(100% - 40px)",
                overflow: "auto",
              }}
            >
              {/* Content */}
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  marginTop: 5,
                  backgroundColor: theme.primary,
                }}
              >
                {Object.keys(data).map((day, i) => {
                  const dayItem = data[day];
                  const date = new Date(day);

                  const segmentStr = dayItem["Morning"]
                    ? "Morning"
                    : dayItem["Afternoon"]
                    ? "Afternoon"
                    : dayItem["Evening"]
                    ? "Evening"
                    : "Night";

                  return (
                    <div
                      style={{
                        marginTop: i > 0 ? 50 : 0,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          textJustify: "middle",
                          fontWeight: "bold",
                          fontSize: 25,
                        }}
                      >
                        {dayItem[segmentStr].weekday +
                          " " +
                          date.toLocaleDateString()}
                      </div>
                      <div>
                        {Object.keys(dayItem).map((segment, j) => {
                          const segmentItem = data[day][segment];
                          const dateStr = DateToString(date);
                          return (
                            <ForecastSpread
                              key={i + "-" + j}
                              style={{ marginTop: j > 0 ? 20 : 0 }}
                              header={`${segment}`}
                              headerStyle={{
                                fontWeight: "normal",
                                fontSize: 15,
                              }}
                              data={{
                                Wave: segmentItem.wave,
                                Alert: segmentItem.surf,
                                Wind: segmentItem.wind,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 1) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate  route={ROUTE}   />
          {Object.keys(data)?.length > 0 && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                // height: "90%",
                height: "calc(100% - 40px)",
                overflow: "auto",
              }}
            >
              {/* Content */}
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  marginTop: 5,
                  backgroundColor: theme.primary,
                }}
              >
                {Object.keys(data).map((day, i) => {
                  const dayItem = data[day];
                  const date = new Date(day);

                  const segmentStr = dayItem["Morning"]
                    ? "Morning"
                    : dayItem["Afternoon"]
                    ? "Afternoon"
                    : dayItem["Evening"]
                    ? "Evening"
                    : "Night";

                  return (
                    <div
                    key={i}
                      style={{
                        marginTop: i > 0 ? 50 : 0,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          textJustify: "middle",
                          fontWeight: "bold",
                          fontSize: 25,
                        }}
                      >
                        {dayItem[segmentStr].weekday +
                          " " +
                          date.toLocaleDateString()}
                      </div>
                      <div>
                        {Object.keys(dayItem).map((segment, j) => {
                          const segmentItem = data[day][segment];
                          const dateStr = DateToString(date);
                          return (
                            <ForecastSpread
                              key={i + "-" + j}
                              style={{ marginTop: j > 0 ? 20 : 0 }}
                              header={`${segment}`}
                              headerStyle={{
                                fontWeight: "normal",
                                fontSize: 15,
                              }}
                              data={{
                                Wave: segmentItem.wave,
                                Alert: segmentItem.surf,
                                Wind: segmentItem.wind,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
  }

  if (dimensions[0] == 1 && dimensions[1] == 2) {
    if (data)
      return (
        <div style={{ height: "100%" }}>
          <WidgetTimeUpdate route={ROUTE}   />
          {Object.keys(data)?.length > 0 && (
            <div
              className="scrollbar-hidden"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 10,
                // height: "90%",
                height: "calc(100% - 40px)",
                overflow: "auto",
              }}
            >
              {/* Content */}
              <div
                style={{
                  padding: 10,
                  flex: 1,
                  marginTop: 5,
                  backgroundColor: theme.primary,
                }}
              >
                {Object.keys(data).map((day, i) => {
                  const dayItem = data[day];
                  const date = new Date(day);

                  const segmentStr = dayItem["Morning"]
                    ? "Morning"
                    : dayItem["Afternoon"]
                    ? "Afternoon"
                    : dayItem["Evening"]
                    ? "Evening"
                    : "Night";

                  return (
                    <div
                    key = {i}
                      style={{
                        marginTop: i > 0 ? 50 : 0,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          textJustify: "middle",
                          fontWeight: "bold",
                          fontSize: 25,
                        }}
                      >
                        {dayItem[segmentStr].weekday +
                          " " +
                          date.toLocaleDateString()}
                      </div>
                      <div>
                        {Object.keys(dayItem).map((segment, j) => {
                          const segmentItem = data[day][segment];
                          const dateStr = DateToString(date);
                          return (
                            <ForecastSpread
                              key={i + "-" + j}
                              style={{ marginTop: j > 0 ? 20 : 0 }}
                              header={`${segment}`}
                              headerStyle={{
                                fontWeight: "normal",
                                fontSize: 15,
                              }}
                              data={{
                                Wave: segmentItem.wave,
                                Alert: segmentItem.surf,
                                Wind: segmentItem.wind,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
  }
}
