import React, { useContext, useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuditLogs from "pages/Admin/AuditLogs";
import MembersTab from "pages/Admin/Components/MembersTab";
import { usePerms } from "hooks/usePerms";
import * as api from "../../apis/FusionAPI";
import { FaPencil } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { ColorContext } from "App";
import { getVisibleUsers } from "utils/roleUtils";
export default function Organization() {
  const [params, setParams] = useSearchParams();
  const perms = usePerms();

  const [tabs, setTabs] = useState([0]);
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [orgName, setOrgName] = useState("");
  const [editOrgName, setEditOrgName] = useState(false);

  useEffect(() => {
    setTabs(params.get("tab") ? [parseInt(params.get("tab"))] : [0]);

    (async () => {
      await api.getAllRoles().then((res) => {
        setRoleList([...res.data.result]);
      });

      await api.getOrgName().then((res) => {
        setOrgName(res.data.result.work_place_name);
      });

      await api
      .getWorkplaceMembers(getVisibleUsers(perms))

        .then((res) => {
          setMemberList([...res.data.result]);
        });
    })();
  }, []);

  //components go in here to display data
  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return (
          <MembersTab
            list={memberList}
            setMemberList={setMemberList}
            mousePosition={mousePosition}
            perms={perms}
            setMousePosition={setMousePosition}
            roleList={roleList}
            listOnly
            editableOrgName
          />
        );
      case 1:
        return <AuditLogs />;
      default:
        return <div />;
    }
  };
  const theme = useContext(ColorContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // background: "yellow",
        height: "100%",
        width: "100%",
        // overflow: "auto",
      }}
    >
      {/* header */}
      <div
        style={{
          width: "100%",
          // height: "100%"
        }}
      >
        <TabsHeader
          onTabChange={(newTabArr) => setTabs([...newTabArr])}
          currentTabs={tabs}
          options={[
            //1st depth
            { option: "Users" },
            { option: "Audit Logs" },
          ]}
          styles={[{}, { backgroundColor: "#436c98" }]}
        />
      </div>

      {/* content */}

      

      <div
        style={{
          // background: theme.base,
          // height: "100%",
          // height: "500px",
          height: "calc(100% - 44px)",
          // calc("100% - 40px"),
        }}
      >
        {RenderTab()}
      </div>
    </div>
  );
}
