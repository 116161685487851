
export const abbreviatedCategoryToFull = (category) => {
  switch(category) {
    case 'G':
      return 'Good';
    case 'M':
      return 'Moderate';
    case 'S':
      return 'Unhealthy for Sensitive Groups';
    case 'U':
      return 'Unhealthy';
    case 'V':
      return 'Very Unhealthy';
    case 'H':
      return 'Hazardous';
    case '*':
      return '?';
    default:
      return 'Unknown';
  }
}