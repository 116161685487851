import { ColorContext } from "App";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import React, { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import MANUAL_ROAD_CLOSURE from "assets/manual_road_closure.png";
import TECHNOLOGY_BRIDGE from "assets/rescalerticons/RescAlert Devices Technology Bridge - Status Closed (with camera).png";
import L from "leaflet";
import TextToggle from "components/UI/TextToggle";
import * as api from "apis/FusionAPI";
import { ConnectionsContext } from "../Connections";
import { getDynamicDeviceIcon, getRescAlertStatus } from "utils/iconUtils";
import FullScreenMap from "components/FullScreenMap";

export default function RescAlertDeviceSettings({
  nodeList,
  selectedDevice,
  setShowDeviceSettings,
  showDeviceSettings,
  setNodeList,
  widget = false,
  showHistory,
  setShowHistory,
  type
}) {
  const theme = useContext(ColorContext);
  const device = nodeList[selectedDevice];
  const [alias, setAlias] = useState(device.alias);
  const [comment, setComment] = useState(device.comments);
  const [roadStatus, setRoadStatus] = useState(
    parseInt(device.data[0]?.status)
  );
  const [message, setMessage] = useState(
    "*You have unsaved changes, Discard or Save to continue*"
  );
  const [mapCenter, setMapCenter] = useState(device.location);
  const connectionsContext = useContext(ConnectionsContext);
  const icons = {
    MANUAL_ROAD_CLOSURE: L.icon({
      iconUrl: MANUAL_ROAD_CLOSURE,
      iconSize: [35, 35],
    }),
    TECHNOLOGY_BRIDGE: L.icon({
      iconUrl: null, // TECHNOLOGY_BRIDGE,
      iconSize: [35, 35],
    }),
  };
  useEffect(() => {
    setComment(device.comments);
    setRoadStatus(parseInt(device.data[0]?.status));
  }, [selectedDevice]);

  if (
    !(
      device.device_type == "Manual Road Closure" ||
      device.device_type == "Technology Bridge"
    )
  )
    return null;
  const lines = device.coordinates;

  const hasDataChanged = () => {
    return comment !== device.comments ||
      roadStatus !== parseInt(device.data[0]?.status)
      ? true
      : false;
  };

  const submitDeviceSettings = async () => {
    // api call to update device settings
    try {
      const res = await api.updateConnectedDevice(
        {
          ...device,
          alias,
          comments: comment,
          status: roadStatus,
        },
        true
      );
      if (res.status === 200) {
        const res2 = await connectionsContext.getDeviceData();
        setShowDeviceSettings(false);
      } else {
        setMessage("Error updating device settings. Please try again.");
      }
    } catch {
      setMessage("Error updating device settings. Please try again.");
    }
  };

  if (!widget)
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: theme.base,
            padding: 30,
            maxHeight: "calc(100% - 60px)",
            maxWidth: "80%",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h1>{device.alias} Device Settings</h1>
            </div>
            <div
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={() => setShowDeviceSettings(false)}
            >
              <h2>X</h2>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div>
              <div style={{ height: "300px", width: window.innerWidth / 3 }}>
                <FullScreenMap
                  key={"fullScreenMap"}
                  mapCenter={mapCenter}
                  zoom={13}
                  style={{
                    borderRadius: 10,
                    height: "100%",
                    pointerEvents: "auto",
                  }}
                >
                  <LocationSetter center={mapCenter} zoom={12} />

                  <>
                    {lines.map((line, lineIndex) => (
                      <>
                        {line.points.length > 1 && (
                          <Polyline
                            key={lineIndex + "" + Date.now()}
                            positions={line.points}
                            color={
                              getRescAlertStatus(device) === "Open"
                                ? "lime"
                                : "red"
                            }
                          />
                        )}
                        {line.points.map((point, pointIndex) => {
                          return (
                            <Marker
                              key={
                                lineIndex + "" + pointIndex + "" + Date.now()
                              }
                              position={point}
                              draggable={false}
                              // popup
                              // eventHandlers={{
                              //   popupopen: () => setPointPopupOpen(true),
                              //   popupclose: () => setPointPopupOpen(false),
                              //   dragend: (e) => {
                              //     const newPosition = e.target.getLatLng();

                              //     if (
                              //       !newPosition ||
                              //       typeof newPosition.lat ===
                              //         "undefined" ||
                              //       typeof newPosition.lng === "undefined"
                              //     )
                              //       return;

                              //     const newLines = [...lines];
                              //     newLines[lineIndex].points[pointIndex] =
                              //       newPosition;
                              //     updateLines(() => newLines);
                              //   },
                              // }}
                              icon={
                                pointIndex == 0 ||
                                pointIndex == line.points.length - 1
                                  ? getDynamicDeviceIcon(device, "icon")
                                  : new L.DivIcon({
                                      className: "custom-x-icon",
                                      html: `<div>
                <div style="color: black; font-size: 30px; position: absolute; top: -4px; left: -2px;">X</div>
                <div style="color: ${
                  getRescAlertStatus(device) === "Open" ? "lime" : "red"
                }; font-size: 24px; position: absolute; top: 0px; left: 0px;">X</div>
                </div>`,
                                      iconSize: [20, 20],
                                      iconAnchor: [10, 16],
                                    })
                              }
                              interactive
                            ></Marker>
                          );
                        })}
                      </>
                    ))}
                  </>
                </FullScreenMap>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: window.innerWidth / 3,
                  padding: 10,
                  gap: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Device Alias:
                  </div>
                  <div
                    style={{
                      width: "75%",
                      // padding: 5,
                    }}
                  >
                    {device.alias}
                  </div>
                  {/* <input
                  type="text"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  style={{
                    border: "1px solid white",
                    width: "75%",
                    padding: 5,
                  }}
                /> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Status:
                  </div>
                  <div
                    style={{
                      width: "75%",
                    }}
                  >
                    <TextToggle
                      value={roadStatus}
                      setValue={setRoadStatus}
                      options={["Open", "Closed"]}
                      optionColorOn={!roadStatus ? "white" : "#C73B1D"}
                      optionTextColorOn={!roadStatus ? "black" : "white"}
                      optionBorder1="2px solid #01b574"
                      optionColorOff="red"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "center",
                    // justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    Comments:
                  </div>
                  <textarea
                    rows={4}
                    type="text"
                    value={comment}
                    style={{
                      border: "1px solid white",
                      width: "75%",
                      padding: 5,
                      resize: "none",
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="(Optional)"
                  />
                </div>
                {hasDataChanged() && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {message}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                {/* <div
                style={{
                  backgroundColor: theme.primary,
                  padding: 10,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
                onClick={() => setShowDeviceSettings(false)}
              >
                View History
              </div> */}
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                  }}
                >
                  {hasDataChanged() && (
                    <div
                      style={{
                        border: "1px solid white",
                        padding: 10,
                        borderRadius: 5,
                        cursor: "pointer",
                        textAlign: "center",

                        width: 80,
                      }}
                      onClick={() => {
                        setShowDeviceSettings(false);
                        setComment(device.comments);
                        setRoadStatus(parseInt(device.data[0]?.status));
                      }}
                    >
                      Discard
                    </div>
                  )}
                  <div
                    style={{
                      backgroundColor: theme.primary,
                      padding: 10,
                      borderRadius: 5,
                      cursor: "pointer",
                      textAlign: "center",
                      width: 80,
                    }}
                    onClick={submitDeviceSettings}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: window.innerHeight / 3 + 50,
              maxHeight: "50%",
              overflowY: "auto",
              background: theme.primaryShadow,
              marginTop: 20,
              // width: window.innerWidth / 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              padding: 10,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h2>Device History</h2>
                <div>{connectionsContext.devices[selectedDevice].alias}</div>
              </div>
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1.5fr 1fr 3fr",
                    textAlign: "left",
                  }}
                >
                  <div>Date/Time</div>
                  <div>Status</div>
                  <div>Comment</div>
                </div>
                {connectionsContext.devices[selectedDevice].data.length > 0
                  ? connectionsContext.devices[selectedDevice].data.map(
                      (data, index) => {
                        return (
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1.5fr 1fr 3fr",
                              borderBottom: "1px solid white",
                              padding: 5,
                              textAlign: "left",
                              gap: 10,
                            }}
                          >
                            <div>{new Date(data.ts).toLocaleString()}</div>
                            <div>{data.status ? "Closed" : "Open"}</div>
                            <div>
                              {data.comments || (
                                <div style={{ color: "grey" }}>No info</div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )
                  : "No Data Available"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  if (widget) {
    if (!showHistory)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: window.innerWidth / 3,
              // padding: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Device Alias:
              </div>
              <div
                style={{
                  width: "70%",
                  // padding: 5,
                }}
              >
                {device.alias}
              </div>
              {/* <input
                  type="text"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  style={{
                    border: "1px solid white",
                    width: "75%",
                    padding: 5,
                  }}
                /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Status:
              </div>
              <div
                style={{
                  width: "70%",
                }}
              >
                <TextToggle
                  value={roadStatus}
                  setValue={setRoadStatus}
                  options={["Open", "Closed"]}
                  optionColorOn={!roadStatus ? "white" : "#C73B1D"}
                  optionTextColorOn={!roadStatus ? "black" : "white"}
                  optionBorder1="2px solid #01b574"
                  optionColorOff="red"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignContent: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                }}
              >
                Comments:
              </div>
              <textarea
                rows={4}
                type="text"
                value={comment}
                style={{
                  border: "1px solid white",
                  width: "70%",
                  padding: 5,
                  resize: "none",
                }}
                onChange={(e) => setComment(e.target.value)}
                placeholder="(Optional)"
              />
            </div>
            {/* {hasDataChanged() && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {message}
            </div>
          )} */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div
              style={{
                backgroundColor: theme.primary,
                padding: 10,
                borderRadius: 5,
                cursor: "pointer",
              }}
              onClick={() => setShowHistory(!showHistory)}
            >
              History
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              {hasDataChanged() && (
                <div
                  style={{
                    border: "1px solid white",
                    padding: 10,
                    borderRadius: 5,
                    cursor: "pointer",
                    textAlign: "center",

                    width: 80,
                  }}
                  onClick={() => {
                    setShowDeviceSettings(false);
                    setComment(device.comments);
                    setRoadStatus(parseInt(device.data[0]?.status));
                  }}
                >
                  Discard
                </div>
              )}
              <div
                style={{
                  backgroundColor: theme.primary,
                  padding: 10,
                  borderRadius: 5,
                  cursor: "pointer",
                  textAlign: "center",
                  width: 80,
                }}
                onClick={submitDeviceSettings}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      );
    if (showHistory)
      return <div onClick={() => setShowHistory(false)}>hide</div>;
  }
}
