import useTextToSpeech from "hooks/useTextToSpeech";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import * as api from "../apis/FusionAPI";
import useAlarms from "hooks/useAlarms";

// Mute tab behavior? Infinite loop?

//need auto silence on message end if needs silence
// --- Issue is if an update comes in before the list finished,
//          it will be silenced even if it didn't PLAY

let eventSource

export default function AlarmListener({isAuthenticated}) {
  const [receivedData, setReceivedData] = useState([]);
  const [oldLength, setOldLength] = useState(0);
  const [gotData, setGotData] = useState(false);
  const tts = useTextToSpeech();
  const alarmList = useAlarms();

  const updateData = (data) => {
    const parsedData = JSON.parse(data);
    
    setReceivedData(parsedData);
  };

  useEffect(() => {
    // Setup code or other effects can go here
  
    return () => {
      // Cleanup code runs on component unmount
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []); // Ensures this effect is only applied on mount and unmount

  // useEffect(() => {
  //   if (eventSource && !isAuthenticated) {
  //     eventSource.close();
  //   }
  // }, [isAuthenticated]);

  const runAlarms = () => {
    if (receivedData.length === 0 && gotData) {
      
      tts.cancel()
      return;
    }

    if (receivedData.length === oldLength && (tts.isSpeaking() || tts.isPending())) return;
    if (receivedData.length === oldLength) { return }
    else {
      
    }

    let readAlarmsList = [
      {
        text: "Pending Alarms",
        id: -1,
        index: -1,
        silenced: false,
      },
    ];

    const alarms = alarmList.get();

    //build alarms list
    for (let i = receivedData.length - 1; i >= 0; i--) {
      let alarm = receivedData[i];
      if ((alarm.source == "Overwatch AI" || alarm.source == "Fusion User") && !alarmList.isSilenced(alarm.id)) {
        alarmList.toggleSilence(alarm.id);
        
      }
      readAlarmsList.push({
        text: (JSON.parse(alarm.info).type || ""),
        id: alarm.id,
        index: i,
        silenced: alarmList.isSilenced(alarm.id),
        heard: alarmList.isHeard(alarm.id),
      });
    }

    const listOfStrs = [];
    readAlarmsList.forEach((item) => {
      if (!item.silenced) {
        listOfStrs.push({text: item.text, repeat: true});
        
      }
      else if (item.silenced && !item.heard && (item.source == "Overwatch AI" || item.source == "Fusion User")) { 
        
        listOfStrs.push({text: item.text, repeat: false}); 
        alarmList.setHeard(item.id);
      }
      else {
        
      }
    });

    alarmList.set(readAlarmsList);

    if (listOfStrs.length > 1) {
      
      tts.beginNewQueue(listOfStrs, true);
    }
    if (listOfStrs.length < 2) {
      
      tts.cancel();
    }

    setOldLength(receivedData.length);
  };

  useEffect(() => {
    runAlarms();
  }, [receivedData]);

  useEffect(() => {
    //wait 10 seconds
    setTimeout(() => {

      
      eventSource = api.connectToLiveAlarms();

      eventSource.onopen = (e) => {
        
      };
      eventSource.onerror = (e) => {
        
      };
      eventSource.onmessage = (e) => {
        updateData(e.data);
        setGotData(true);
      };

      return () => {
        
        eventSource.close();
      };
      
    }, 10000);
  }, []);
  

  return null
}
