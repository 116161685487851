import React, { useContext, useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import NWSAlerts from "components/StormCenter/NWSAlerts";
import AdvisoriesMap from "components/StormCenter/AdvisoriesMap";
import HurricanesTropicalStorms from "components/StormCenter/HurricanesTropicalStorms";
import * as api from "../../apis/FusionAPI";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import useDataRefresh from "hooks/useDataRefresh";
import { UniqueWidgetRefreshContext } from "App";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";

export default function StormCenter() {
  const [params, setParams] = useSearchParams();
  const [tabs, setTabs] = useState([0]);
  const [alerts, setAlerts] = useState([]);
  const [localAlerts, setLocalAlerts] = useState([]);

  const perms = usePerms();
  const navigate = useNavigate();
  const dataRefresh = useDataRefresh();

  useEffect(() => {
    (async () => {
      if (!(await perms.validatePermissions(["StormCenterEnabled"]))) {
        navigate("/404");
        return;
      }
    })();
    const initTab = [params.get("tab") ? parseInt(params.get("tab")) : 0];
    setTabs([...initTab]);
  }, []);

  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "NWSandMyForecastAlerts";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
        //get 15 day forecast

        api.getAllActiveAlerts().then((res) => {
          const cwAlertsOnly = res.data.alerts.filter((alert) => {
            return alert.provider === "Custom Weather";
          });
          setAlerts(cwAlertsOnly);
        });
        api.getCWActiveAlerts().then((res) => {
          
          const alertsSortedByTime = res.data.result.sort((a, b) => {
            return new Date(b.sentTime) - new Date(a.sentTime);
          });
          setLocalAlerts(alertsSortedByTime);
          // dataRefresh.setRefresh(new Date().toDateString());
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY], dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  //components go in here to display data
  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return (
          <div
            className="scrollbar-hidden"
            style={{ height: "100%", overflow: "auto" }}
          >
            <WidgetTimeUpdate route={ROUTE} realtime dataUpdateOnly />
            <NWSAlerts alerts={alerts} title="Current NWS Alerts" />
          </div>
        );
      case 1:
        return (
          <div
            className="scrollbar-hidden"
            style={{ height: "100%", overflow: "auto" }}
          >
            <WidgetTimeUpdate route={ROUTE} realtime dataUpdateOnly />
            <NWSAlerts alerts={localAlerts} title="My Forecast Advisories" />
          </div>
        );
      case 2:
        return <AdvisoriesMap />;
      case 3:
        return <HurricanesTropicalStorms />;
      default:
        return <div />;
    }
  };

  return (
    <div
      className="page-root"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => setTabs([...newTabArr])}
        currentTabs={tabs}
        options={[
          //1st depth
          { option: "NWS Alerts" },
          { option: "My Forecast Advisories" },
          { option: "Advisories Map" },
          { option: "Hurricanes and Tropical Storms" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {RenderTab()}
    </div>
  );
}
