import { ColorContext } from "App";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearCookies } from "utils/cookieUtils";

export const PrivacyPopup = ({
  declineAction = () => {},
  acceptAction = () => {},
  showFull,
  setShowFull,
  isAuthenticated
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setShowFull(true);
  }, []);

  const declineHandler = () => {
    declineAction();
    clearCookies();
    navigate("/");
  };
  const acceptHandler = () => {
    acceptAction();
    setShowFull(false);
  };

  const theme = useContext(ColorContext);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: 0,
        left: 0,
        background: showFull && "rgba(0,0,0,0.3)",
        // background: "red",
        zIndex: 1000,
        pointerEvents: showFull ? "auto" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: showFull ? 15 : 0,
          left: 15,
          width: isAuthenticated ? (showFull ? 400 : "") : (showFull ? "calc(100% - 70px)" : ""),
          borderRadius: showFull ? 0 : "20px 20px 0 0",
          // height: 350,
          display: "flex",
          flexDirection: "column",
          background: theme.primaryHighlight,
          color: theme.fontColor,
          alignContent: "center",
          justifyContent: "center",
          padding: 20,
          gap: 20,
          fontSize: 16,
          zIndex: 1000,
          pointerEvents: "auto",
        }}
        onClick={() => setShowFull(true)}
      >
        {showFull ? (
          <h2 style={{ fontWeight: "bold" }}>Privacy & Cookie Policy</h2>
        ) : (
          <p style={{ fontWeight: "bold" }}>Privacy & Cookie Policy</p>
        )}
        {showFull && (
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                lineHeight: 1.2,
              }}
            >
              This website uses cookies to ensure you get the best experience on
              our website.{" "}
              <p style={{ fontWeight: "bold" }}>
                By accessing or using the service, you indicate consent to the
                following policies:
              </p>
            </div>
            <div>
              <a
                href="/terms"
                target="_blank"
                style={{ color: theme.fontColor }}
              >
                Terms of Use
              </a>
            </div>
            <div>
              <a
                href="/policy"
                target="_blank"
                style={{ color: theme.fontColor }}
              >
                Privacy & Cookie Policy
              </a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  backgroundColor: theme.background,
                  border: "1px solid white",
                  color: theme.fontColor,
                  padding: 10,
                  margin: 10,
                  marginLeft: 0,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  declineHandler();
                }}
              >
                Decline
              </button>
              <button
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #0b1826",
                  color: theme.fontColor,
                  padding: 10,
                  margin: 10,
                  cursor: "pointer",
                  color: theme.background,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  acceptHandler();
                }}
              >
                Accept & Allow Cookies
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
