import React, { useContext, useEffect, useState } from "react";
import { FireDangerDay } from "./components/FireDangerDay";
import * as api from "apis/FusionAPI";
import { DetailedFireDangerDay } from "./components/DetailedFireDangerDay";
import OvalDisplay from "components/OvalDisplay";
import moment from "moment";
import { capitalizeWord } from "utils/stringUtils";
import { FaFireFlameSimple } from "react-icons/fa6";
import { FaFireAlt } from "react-icons/fa";
import {
  BsChevronBarLeft,
  BsChevronLeft,
  BsChevronRight,
  BsFire,
} from "react-icons/bs";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import DropdownSelector from "components/DropdownSelector";
import { Range } from "utils/mathUtils";
import { ColorContext, LayoutSizeContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache = { detailedFireDanger: null, extendedFireDanger: null };

export const FireDanger = ({
  widget,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) => {
  const [fetching, setFetching] = useState(true);
  const [fireDangerData, setFireDangerData] = useState([]);
  const [detailedFireDangerData, setDetailedFireDangerData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [error, setError] = useState({ state: false, message: "" });
  const [selected, setSelected] = useState(0);
  const [options, setOptions] = useState();
  const [detailedDates, setDetailedDates] = useState([]);

  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getExtendedFireDanger";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet("getExtendedFireDanger").then((res) => {
          
          dataCache.extendedFireDanger = res.data.result;

          const fdOptions = [];
          for (let i = 0; i < dataCache.extendedFireDanger.length; i++) {
            if (!fdOptions.includes(dataCache.extendedFireDanger[i].city_id))
              fdOptions.push(dataCache.extendedFireDanger[i].city_id);
          }
          setOptions(fdOptions);

          setFireDangerData(dataCache.extendedFireDanger);
          FusionData.forceGet("getDetailedFireDanger").then((res) => {
            
            const timeOfDay = ["Morning", "Afternoon", "Evening", "Night"];
            let days = { 0: [], 1: [] };
            let max = 8;
            for (let i = 0; i < max; i++) {
              let thisDay = [timeOfDay[i % 4]];
              if (i < 4) {
                days["0"].push({ ...res.data.result[i], timeOfDay: thisDay });
              } else {
                days["1"].push({ ...res.data.result[i], timeOfDay: thisDay });
              }
            }
            dataCache.detailedFireDanger = days;
            setDetailedFireDangerData(dataCache.detailedFireDanger);
            // setDetailedFireDangerData(res.data.result)
            setFetching(false);
          });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.get("getExtendedFireDanger").then((res) => {
          
          dataCache.extendedFireDanger = res.data.result;

          const fdOptions = [];
          for (let i = 0; i < dataCache.extendedFireDanger.length; i++) {
            if (!fdOptions.includes(dataCache.extendedFireDanger[i].city_id))
              fdOptions.push(dataCache.extendedFireDanger[i].city_id);
          }
          setOptions(fdOptions);

          setFireDangerData(dataCache.extendedFireDanger);
          FusionData.get("getDetailedFireDanger").then((res) => {
            
            const timeOfDay = ["Morning", "Afternoon", "Evening", "Night"];
            let days = { 0: [], 1: [] };
            let max = 8;
            for (let i = 0; i < max; i++) {
              let thisDay = [timeOfDay[i % 4]];
              if (i < 4) {
                days["0"].push({ ...res.data.result[i], timeOfDay: thisDay });
              } else {
                days["1"].push({ ...res.data.result[i], timeOfDay: thisDay });
              }
            }
            dataCache.detailedFireDanger = days;
            setDetailedFireDangerData(dataCache.detailedFireDanger);
            // setDetailedFireDangerData(res.data.result)
            setFetching(false);
          });
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  const layout = useContext(LayoutSizeContext);

  const fireIcons = {
    0: <FaFireFlameSimple size={32 * layout.scaleMult} />,
    1: <FaFireAlt size={32 * layout.scaleMult} />,
    2: <BsFire size={32 * layout.scaleMult} />,
    3: <BsFire size={32 * layout.scaleMult} />,
    4: <BsFire size={32 * layout.scaleMult} />,
  };

  useEffect(() => {
    dataCache = { detailedFireDanger: null, extendedFireDanger: null };
    setFetching(true);
  }, [widgetRefresh]);

  const trimLevel = (level) => {
    if (level.toLowerCase().includes("low")) return "Low";
    if (level.toLowerCase().includes("moderate")) return "Mod";
    if (level.toLowerCase().includes("high")) return "High";
    if (level.toLowerCase().includes("extreme")) return "Extr";
    return "???";
  };

  
  const theme = useContext(ColorContext);

  const adjustDay = (direction) => {
    if (direction === "down") {
      if (selectedDay > 0) {
        setSelectedDay(selectedDay - 1);
      }
    } else {
      if (selectedDay < 7 - 1) {
        setSelectedDay(selectedDay + 1);
      }
    }
  };

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  // if (true && widget)
  if (fetching && widget)
    return (
      <WidgetLoadingAnimation
        widgetName={"FireDanger"}
        dimensions={dimensions}
      />
    );

  if (fetching) return <WidgetLoadingAnimation />;

  if (fireDangerData && fireDangerData.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  const LeftArrowComponent = () => {
    return (
      <div onClick={() => adjustDay("down")} style={{ cursor: "pointer" }}>
        <BsChevronLeft size={40} />
      </div>
    );
  };

  const RightArrowComponent = () => {
    return (
      <div onClick={() => adjustDay("up")} style={{ cursor: "pointer" }}>
        <BsChevronRight size={40} />
      </div>
    );
  };

  if (!widget)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} />
        <div
          style={{
            display: "flex",
            padding: 15,
            borderBottom: "solid grey 1px",
            fontSize: 30,
          }}
        >
          Fire Danger
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div style={{ flex: 1, height: "100%" }}>
            {Object.keys(detailedFireDangerData).map((key, index) => {
              return (
                <DetailedFireDangerDay
                  data={detailedFireDangerData[index]}
                  index={index}
                />
              );
            })}
          </div>
          <div style={{ flex: 1, height: "100%" }}>
            {Object.keys(fireDangerData)
              .filter((item) => {
                if (
                  detailedDates[1] >=
                  fireDangerData[item].utc_time.split(" ")[0]
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((key, index) => {
                // if (index > 1)
                return (
                  <FireDangerDay data={fireDangerData[key]} index={index} />
                );
              })}
          </div>
        </div>
      </div>
    );

  if (
    widget &&
    dimensions[0] == 2 &&
    dimensions[1] == 1 &&
    fireDangerData.length
  )
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} />
        <DropdownSelector
          options={options}
          selectedOption={selected}
          setSelected={(index) => setSelected(index)}
          triggerStyle={{ width: "100%", padding: 5 }}
          contentStyle={{ border: "solid black 2px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
            width: "100%",
            height: `calc(100% - ${120 * layout.scaleMult}px)`,
            padding: 10,
            boxSizing: "border-box",
            alignItems: "center",
            // background:'green'
          }}
        >
          <LeftArrowComponent />
          {Object.keys(fireDangerData)
            .filter((filter) => {
              return fireDangerData[filter].city_id === options[selected];
            })
            .map((key, index) => {
              const length = Object.keys(fireDangerData).filter((filter) => {
                return fireDangerData[filter].city_id === options[selected];
              }).length;
              const numDisplayed = 7;
              const halfNum = numDisplayed / 2;

              if (
                !Range(
                  Math.max(Math.min(selectedDay, length - halfNum), halfNum) -
                    halfNum,
                  Math.min(Math.max(selectedDay, halfNum), length - halfNum) +
                    halfNum,
                  index
                )
              )
                return;

              return (
                <div
                  key={index}
                  onClick={() => setSelectedDay(index)}
                  style={{
                    cursor: "pointer",
                    color: theme.fontColor,
                    height: "90%",
                  }}
                >
                  <OvalDisplay
                    time={moment(fireDangerData[key]["utc_time"]).format(
                      "MM/DD"
                    )}
                    icon={fireIcons[fireDangerData[key]["danger_level"]]}
                    temp={trimLevel(fireDangerData[key]["level"])}
                    selected={selectedDay === index}
                  />
                </div>
              );
            })}

          <RightArrowComponent />
        </div>
        <FireDangerDay
          widgetInfo
          data={fireDangerData[selectedDay]}
          index={selectedDay}
        />
      </div>
    );

  if (
    widget &&
    dimensions[0] == 1 &&
    dimensions[1] == 1 &&
    fireDangerData.length
  )
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} />
        <DropdownSelector
          options={options}
          selectedOption={selected}
          setSelected={(index) => setSelected(index)}
          triggerStyle={{ width: "100%", padding: 5 }}
          contentStyle={{ border: "solid black 2px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
            width: "100%",
            height: `calc(100% - ${100 * layout.scaleMult}px)`,
            padding: 10,
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <LeftArrowComponent />
          {Object.keys(fireDangerData)
            .filter((filter) => {
              return fireDangerData[filter].city_id === options[selected];
            })
            .map((key, index) => {
              const length = Object.keys(fireDangerData).filter((filter) => {
                return fireDangerData[filter].city_id === options[selected];
              }).length;
              const numDisplayed = 3;
              const halfNum = numDisplayed / 2;

              if (
                !Range(
                  Math.max(Math.min(selectedDay, length - halfNum), halfNum) -
                    halfNum,
                  Math.min(Math.max(selectedDay, halfNum), length - halfNum) +
                    halfNum,
                  index
                )
              )
                return;

              return (
                <div
                  key={index}
                  onClick={() => setSelectedDay(index)}
                  style={{ cursor: "pointer", color: theme.fontColor, height: "90%" }}
                >
                  <OvalDisplay
                    time={moment(fireDangerData[key]["utc_time"]).format(
                      "MM/DD"
                    )}
                    icon={fireIcons[fireDangerData[key]["danger_level"]]}
                    temp={trimLevel(fireDangerData[key]["level"])}
                    selected={selectedDay === index}
                  />
                </div>
              );
            })}

          <RightArrowComponent />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "95%",
            // background:"red",
            marginLeft: "5%",
          }}
        >
          <FireDangerDay
            widgetInfo
            data={fireDangerData[selectedDay]}
            index={selectedDay}
          />
        </div>
      </div>
    );

  if (
    widget &&
    dimensions[0] == 1 &&
    dimensions[1] == 2 &&
    fireDangerData.length
  )
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} />
        <DropdownSelector
          options={options}
          selectedOption={selected}
          setSelected={(index) => setSelected(index)}
          triggerStyle={{ width: "100%", padding: 5 }}
          contentStyle={{ border: "solid black 2px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: `calc(100% - ${160 * layout.scaleMult}px)`,
            // padding: 40,
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <LeftArrowComponent />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              height: "90%",
              rowGap: 15,
              columnGap: 15,
            }}
          >
            {Object.keys(fireDangerData)
              .filter((filter) => {
                return fireDangerData[filter].city_id === options[selected];
              })
              .map((key, index) => {
                const length = Object.keys(fireDangerData).filter((filter) => {
                  return fireDangerData[filter].city_id === options[selected];
                }).length;
                const numDisplayed = 6;
                const halfNum = numDisplayed / 2;

                if (
                  !Range(
                    Math.max(Math.min(selectedDay, length - halfNum), halfNum) -
                      halfNum,
                    Math.min(Math.max(selectedDay, halfNum), length - halfNum) +
                      halfNum,
                    index
                  )
                )
                  return;

                return (
                  <div
                    key={index}
                    onClick={() => setSelectedDay(index)}
                    style={{
                      cursor: "pointer",
                      color: theme.fontColor,
                      height: "90%",
                    }}
                  >
                    <OvalDisplay
                      time={moment(fireDangerData[key]["utc_time"]).format(
                        "MM/DD"
                      )}
                      icon={fireIcons[fireDangerData[key]["danger_level"]]}
                      temp={trimLevel(fireDangerData[key]["level"])}
                      selected={selectedDay === index}
                    />
                  </div>
                );
              })}
          </div>

          <RightArrowComponent />
        </div>
        <div
          style={
            {
              // marginLeft: 100
            }
          }
        >
          <FireDangerDay
            widgetInfo
            data={fireDangerData[selectedDay]}
            index={selectedDay}
            vertical
            spacing={5}
          />
        </div>
      </div>
    );
};
