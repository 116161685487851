import { useEffect, useState } from "react";
import {
  stringIsCoordinates,
  parseCoordinateString,
  distanceBetweenPoints,
} from "utils/geometryUtils";
import LocationSearchInput from "./LocationSearchInput";
import LocationSearchResults from "./LocationSearchResults"; // Deprecated, using autocomplete for similar functionality now
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import useDebounce from "hooks/useDebounce";
import * as api from "apis/FusionAPI";

export default function LocationSearch({
  alertData,
  setMapBounds,
  styles,
  setMapCenter,
  handleChange,
  getMapCenter,
  setAlertData,
  widget,
}) {
  //set state and city based on results from autocomplete
  // const [locations, setLocations] = useState([])
  // const [locationIndex, setLocationIndex] = useState(-1) //deprecated with autocomplete
  const [userLocation, setUserLocation] = useState({});
  const [relativeLocation, setRelativeLocation] = useState({ lat: 0, lng: 0 });
  // const [searchValid, setSearchValid] = useState(true)
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [query, setQuery] = useState("");
  const [fetching, setFetching] = useState(false);

  const debouncedSearchTerm = useDebounce(query, 1000);

  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        //if query matches an autocomplete result, do not search
        if (autoCompleteData.length > 0) {
          for (let i = 0; i < autoCompleteData.length; i++) {
            if (autoCompleteData[i].description === query) {
              return;
            }
          }
        }
        searchAutoFill(debouncedSearchTerm);
      })();
    }
  }, [debouncedSearchTerm]);

  const getLocationFromCoordinates = async (lat, lng) => {
    const res = await api.getCityStateFromCoordinates(lat, lng);
    setAlertData({
      ...alertData,
      state: res.data.result.state_name,
      city: res.data.result.city_name,
      foundLocation:
        res.data.result.city_name + ", " + res.data.result.state_name,
    });
  };

  useEffect(() => {
    // Try to find specific location when point is added
    if (alertData.lastInserted && alertData.lastInserted.length > 0) {
      let index = alertData.lastInserted.length - 1;
      if (alertData.lastInserted.length > 0 && !alertData.foundLocation)
        getLocationFromCoordinates(
          alertData.lastInserted[index].lat,
          alertData.lastInserted[index].lng
        );
      if (alertData.lastInserted.length === 0) {
        setAlertData({ ...alertData, foundLocation: null });
      }
    }
  }, [alertData.lastInserted]);

  useEffect(() => {
    if (fetching) {
      setAutoCompleteData([
        {
          description: "Loading...",
        },
      ]);
    }
  }, [fetching]);

  useEffect(() => {
    if (
      alertData.state === "" ||
      alertData.city === "" ||
      alertData.state === undefined ||
      alertData.city === undefined
    ) {
      setAlertData({ ...alertData, foundLocation: null });
    }
  }, [alertData.state, alertData.city]);

  useEffect(() => {
    //had to change this to '!' rather than '=== undefined'
    if (!alertData.searchLocation) return;
    setMapCenter({
      lat: alertData.searchLocation?.latitude,
      lng: alertData.searchLocation?.longitude,
    });
    setRelativeLocation({
      lat: alertData.searchLocation?.latitude,
      lng: alertData.searchLocation?.longitude,
    });
    if (!alertData.foundLocation || alertData.foundLocation === "") {
      setAlertData({
        ...alertData,
        state: alertData.searchLocation?.state_long,
        city: alertData.searchLocation?.city,
        foundLocation:
          alertData.searchLocation?.city +
          ", " +
          alertData.searchLocation?.state_long,
      });
    }
  }, [alertData.searchLocation]);

  useEffect(() => {
    setRelativeLocation({ lat: getMapCenter.lat, lng: getMapCenter.lng });
  }, [getMapCenter]);

  //Get navigator geolocation and set user location
  useEffect(() => {
    setUserLocation({
      lat: Cookies.get("latitude"),
      lng: Cookies.get("longitude"),
    });
    setRelativeLocation({
      lat: Cookies.get("latitude"),
      lng: Cookies.get("longitude"),
    });
  }, []);

  const searchAutoFill = async (query) => {
    let searchLocation = query.trim();
    if (searchLocation === "") {
      setAutoCompleteData([]);
      return;
    }
    setFetching(true);
    setAutoCompleteData([]);

    try {
      let results = await api.autoCompleteSearch(
        relativeLocation.lat,
        relativeLocation.lng,
        searchLocation
      );
      results = results.data.result;

      if (results) {
        setAutoCompleteData(results);
        setFetching(false);
      } else {
        setFetching(false);
        setAutoCompleteData([]);
      }
    } catch (error) {
      console.error("Error fetching autocomplete data:", error);
      setFetching(false);
      setAutoCompleteData([]);
    }
  };

  if (!widget)
    return (
      <div
        style={{ ...styles.input, justifyContent: "left" }}
        className="locationSearch"
      >
        <LocationSearchInput
          // searchLocation = {searchLocation}
          styles={styles}
          alertData={alertData}
          handleChange={handleChange}
          // searchValid = {searchValid}
          userLocation={userLocation}
          setMapCenter={setMapCenter}
          searchAutoFill={searchAutoFill}
          autoCompleteData={autoCompleteData}
          setAlertData={setAlertData}
          setAutoCompleteData={setAutoCompleteData}
          query={query}
          setQuery={setQuery}
        />
      </div>
    );
  else
    return (
      <LocationSearchInput
        // searchLocation = {searchLocation}
        styles={styles}
        alertData={alertData}
        handleChange={handleChange}
        // searchValid = {searchValid}
        userLocation={userLocation}
        setMapCenter={setMapCenter}
        searchAutoFill={searchAutoFill}
        autoCompleteData={autoCompleteData}
        setAlertData={setAlertData}
        setAutoCompleteData={setAutoCompleteData}
        query={query}
        setQuery={setQuery}
        widget={widget}
      />
    );
}
