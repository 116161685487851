import React, { useContext, useEffect, useState } from "react";

import * as api from "apis/FusionAPI";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import { ErrorMessage } from "components/Widgets/ErrorMessage";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import useDataRefresh from "hooks/useDataRefresh";

let dataCache = { skiReports: null };

export const SkiReports = ({
  widget,
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
}) => {
  const [fetching, setFetching] = useState(true);
  const theme = useContext(ColorContext);

  const [skiReports, setSkiReports] = useState([]);
  const [opened, setOpened] = useState([]);
  const [error, setError] = useState({ state: false, message: "" });
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });

  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getSkiReports";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    const fetchData = async () => {
      try {
        FusionData.forceGet("getSkiReports").then((res) => {
          
          dataCache.skiReports = res.data.result;
          setSkiReports(dataCache.skiReports);

          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    dataCache = { skiReports: null };
    setFetching(true);
  }, [dataRefresh.widgetRefresh]);
  useEffect(() => {
    (async () => {
      //get skiReports data
      if (!dataCache.skiReports) {
        FusionData.get("getSkiReports")
          .then((res) => {
            
            dataCache.skiReports = res.data.result;
            setSkiReports(dataCache.skiReports);

            setFetching(false);
          })
          .catch((err) => {
            
            setError({ state: true, message: err.message });
          });
      } else {
        setSkiReports(dataCache.skiReports);
        setFetching(false);
      }
    })();
  }, [dataRefresh.widgetRefresh]);

  if (error.state && !fetching) {
    return <ErrorMessage message={error.message} />;
  }

  if (fetching && widget) {
    // if (true && widget) {
    return (
      <WidgetLoadingAnimation
        widgetName={"SkiReport"}
        dimensions={dimensions}
      />
    );
  }

  if (fetching) return <WidgetLoadingAnimation />

  if (skiReports && skiReports.length === 0) {
    return (
      <div>
        <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        <div style={{ padding: 15 }}>No data found.</div>
      </div>
    );
  }

  if (!dimensions || (dimensions[0] == 2 && dimensions[1] == 1))
    return (
      <div style={{ height: "100%" }}>
        <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        {skiReports.length && (
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "calc(100% - 60px)",
              padding: 10,
              overflowX: "hidden",
            }}
          >
            {skiReports.map((report, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: 10,
                    marginBottom: 20,
                    backgroundColor: theme.primary,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        const newOpened = [...opened];
                        newOpened[index] = !newOpened[index];
                        setOpened(newOpened);
                      }}
                    >
                      <h2 style={{ marginLeft: 10 }}>{report.ski_area_name}</h2>
                      {opened[index] ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                    {opened[index] && (
                      <div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Snow Details
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>New snow min: {report.new_snow_min24}</div>
                    <div>New snow max: {report.new_snow_max24}</div>
                    <div>Primary Surface: {report.primary_surface}</div>
                    <div>Base depth min: {report.base_depth_min}</div>
                    <div>Base depth max: {report.base_depth_max}</div>
                    <div>Snow making: {report.snow_making}</div> */}
                              {report.new_snow_min24 &&
                                report.new_snow_min24 !== "*" && (
                                  <div>
                                    New snow min: {report.new_snow_min24}
                                  </div>
                                )}
                              {report.new_snow_max24 &&
                                report.new_snow_max24 !== "*" && (
                                  <div>
                                    New snow max: {report.new_snow_max24}
                                  </div>
                                )}
                              {report.primary_surface &&
                                report.primary_surface !== "*" && (
                                  <div>
                                    Primary Surface: {report.primary_surface}
                                  </div>
                                )}
                              {report.base_depth_min &&
                                report.base_depth_min !== "*" && (
                                  <div>
                                    Base depth min: {report.base_depth_min}
                                  </div>
                                )}
                              {report.base_depth_max &&
                                report.base_depth_max !== "*" && (
                                  <div>
                                    Base depth max: {report.base_depth_max}
                                  </div>
                                )}
                              {report.snow_making &&
                                report.snow_making !== "*" && (
                                  <div>Snow making: {report.snow_making}</div>
                                )}
                              {!(
                                report.new_snow_min24 &&
                                report.new_snow_min24 !== "*"
                              ) &&
                                !(
                                  report.new_snow_max24 &&
                                  report.new_snow_max24 !== "*"
                                ) &&
                                !(
                                  report.primary_surface &&
                                  report.primary_surface !== "*"
                                ) &&
                                !(
                                  report.base_depth_min &&
                                  report.base_depth_min !== "*"
                                ) &&
                                !(
                                  report.base_depth_max &&
                                  report.base_depth_max !== "*"
                                ) &&
                                !(
                                  report.snow_making &&
                                  report.snow_making !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Historic
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>Seasonal Snowfall: {report.seasonal_snowfall}</div>
                    <div>Last Snowfall Date: {report.last_snowfall_date}</div>
                    <div>Last Snowfall Amount: {report.last_snowfall_amount}</div>
                    <div>Previous Snowfall Date: {report.previous_snowfall_date}</div>
                    <div>Previous Snowfall Amount: {report.previous_snowfall_amount}</div> */}
                              {report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*" && (
                                  <div>
                                    Seasonal Snowfall:{" "}
                                    {report.seasonal_snowfall} in.
                                  </div>
                                )}
                              {report.last_snowfall_date &&
                                report.last_snowfall_date !== "*" && (
                                  <div>
                                    Last Snowfall Date:{" "}
                                    {report.last_snowfall_date}
                                  </div>
                                )}
                              {report.last_snowfall_amount &&
                                report.last_snowfall_amount !== "*" && (
                                  <div>
                                    Last Snowfall Amount:{" "}
                                    {report.last_snowfall_amount} in.
                                  </div>
                                )}
                              {report.previous_snowfall_date &&
                                report.previous_snowfall_date !== "*" && (
                                  <div>
                                    Previous Snowfall Date:{" "}
                                    {report.previous_snowfall_date}
                                  </div>
                                )}
                              {report.previous_snowfall_amount &&
                                report.previous_snowfall_amount !== "*" && (
                                  <div>
                                    Previous Snowfall Amount:{" "}
                                    {report.previous_snowfall_amount} in.
                                  </div>
                                )}
                              {!(
                                report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*"
                              ) &&
                                !(
                                  report.last_snowfall_date &&
                                  report.last_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.last_snowfall_amount &&
                                  report.last_snowfall_amount !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_date &&
                                  report.previous_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_amount &&
                                  report.previous_snowfall_amount !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: 20, alignItems: "flex-start" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            Operation
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                              maxHeight: 200,
                              flexWrap: "wrap",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            {/* <div>Operation Hours: {report.operation_hours}</div>
                    <div>Operation status: {report.operation_status}</div>
                    <div>Resort Status: {report.resort_status}</div>
                    <div>Projected Operation: {report.projected_operations}</div>
                    <div>Snow Comments: {report.snow_comments24}</div>
                    <div>General Comments: {report.general_comments}</div>
                    <div>Night skiing: {report.night_skiing}</div>
                    <div>Lift Open: {report.num_lift_open}</div>
                    <div>Trails Open: {report.num_trails_open}</div>
                    <div>Miles Open: {report.num_miles_open}</div>
                    <div>Acres Open: {report.num_acres_open}</div>
                    <div>% Runs Open: {report.percent_runs_open}%</div>
                    <div>Crosscountry: {report.cross_country}</div>
                    <div>Snowboarding: {report.snow_boarding}</div>
                    <div>Total Lifts: {report.total_lifts}</div>
                    <div>Total Trails: {report.total_trails}</div> */}
                            {report.operation_hours &&
                              report.operation_hours !== "*" && (
                                <div>
                                  Operation Hours: {report.operation_hours}
                                </div>
                              )}
                            {report.operation_status &&
                              report.operation_status !== "*" && (
                                <div>
                                  Operation status: {report.operation_status}
                                </div>
                              )}
                            {report.resort_status &&
                              report.resort_status !== "*" && (
                                <div>Resort Status: {report.resort_status}</div>
                              )}
                            {report.projected_operations &&
                              report.projected_operations !== "*" && (
                                <div>
                                  Projected Operation:{" "}
                                  {report.projected_operations}
                                </div>
                              )}
                            {report.snow_comments24 &&
                              report.snow_comments24 !== "*" && (
                                <div>
                                  Snow Comments: {report.snow_comments24}
                                </div>
                              )}
                            {report.general_comments &&
                              report.general_comments !== "*" && (
                                <div>
                                  General Comments: {report.general_comments}
                                </div>
                              )}
                            {report.night_skiing &&
                              report.night_skiing !== "*" && (
                                <div>Night skiing: {report.night_skiing}</div>
                              )}
                            {report.num_lift_open &&
                              report.num_lift_open !== "*" && (
                                <div>Lift Open: {report.num_lift_open}</div>
                              )}
                            {report.num_trails_open &&
                              report.num_trails_open !== "*" && (
                                <div>Trails Open: {report.num_trails_open}</div>
                              )}
                            {report.num_miles_open &&
                              report.num_miles_open !== "*" && (
                                <div>Miles Open: {report.num_miles_open}</div>
                              )}
                            {report.num_acres_open &&
                              report.num_acres_open !== "*" && (
                                <div>Acres Open: {report.num_acres_open}</div>
                              )}
                            {report.percent_runs_open &&
                              report.percent_runs_open !== "*" && (
                                <div>
                                  % Runs Open: {report.percent_runs_open}%
                                </div>
                              )}
                            {report.cross_country &&
                              report.cross_country !== "*" && (
                                <div>Crosscountry: {report.cross_country}</div>
                              )}
                            {report.snow_boarding &&
                              report.snow_boarding !== "*" && (
                                <div>Snowboarding: {report.snow_boarding}</div>
                              )}
                            {report.total_lifts &&
                              report.total_lifts !== "*" && (
                                <div>Total Lifts: {report.total_lifts}</div>
                              )}
                            {report.total_trails &&
                              report.total_trails !== "*" && (
                                <div>Total Trails: {report.total_trails}</div>
                              )}
                            {!(
                              report.operation_hours &&
                              report.operation_hours !== "*"
                            ) &&
                              !(
                                report.operation_status &&
                                report.operation_status !== "*"
                              ) &&
                              !(
                                report.resort_status &&
                                report.resort_status !== "*"
                              ) &&
                              !(
                                report.projected_operations &&
                                report.projected_operations !== "*"
                              ) &&
                              !(
                                report.snow_comments24 &&
                                report.snow_comments24 !== "*"
                              ) &&
                              !(
                                report.general_comments &&
                                report.general_comments !== "*"
                              ) &&
                              !(
                                report.night_skiing &&
                                report.night_skiing !== "*"
                              ) &&
                              !(
                                report.num_lift_open &&
                                report.num_lift_open !== "*"
                              ) &&
                              !(
                                report.num_trails_open &&
                                report.num_trails_open !== "*"
                              ) &&
                              !(
                                report.num_miles_open &&
                                report.num_miles_open !== "*"
                              ) &&
                              !(
                                report.num_acres_open &&
                                report.num_acres_open !== "*"
                              ) &&
                              !(
                                report.percent_runs_open &&
                                report.percent_runs_open !== "*"
                              ) &&
                              !(
                                report.cross_country &&
                                report.cross_country !== "*"
                              ) &&
                              !(
                                report.snow_boarding &&
                                report.snow_boarding !== "*"
                              ) &&
                              !(
                                report.total_lifts && report.total_lifts !== "*"
                              ) &&
                              !(
                                report.total_trails &&
                                report.total_trails !== "*"
                              ) && <div>No data.</div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  if (dimensions[0] == 1 && dimensions[1] == 1)
    return (
      <div style={{ height: "100%" }}>
        {widget && (
          <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        )}
        {skiReports.length && (
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "calc(100% - 60px)",
              padding: 10,
              overflowX: "hidden",
            }}
          >
            {skiReports.map((report, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: 10,
                    marginBottom: 20,
                    backgroundColor: theme.primary,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        const newOpened = [...opened];
                        newOpened[index] = !newOpened[index];
                        setOpened(newOpened);
                      }}
                    >
                      <h2 style={{ marginLeft: 10 }}>{report.ski_area_name}</h2>
                      {opened[index] ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                    {opened[index] && (
                      <div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Snow Details
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>New snow min: {report.new_snow_min24}</div>
                    <div>New snow max: {report.new_snow_max24}</div>
                    <div>Primary Surface: {report.primary_surface}</div>
                    <div>Base depth min: {report.base_depth_min}</div>
                    <div>Base depth max: {report.base_depth_max}</div>
                    <div>Snow making: {report.snow_making}</div> */}
                              {report.new_snow_min24 &&
                                report.new_snow_min24 !== "*" && (
                                  <div>
                                    New snow min: {report.new_snow_min24}
                                  </div>
                                )}
                              {report.new_snow_max24 &&
                                report.new_snow_max24 !== "*" && (
                                  <div>
                                    New snow max: {report.new_snow_max24}
                                  </div>
                                )}
                              {report.primary_surface &&
                                report.primary_surface !== "*" && (
                                  <div>
                                    Primary Surface: {report.primary_surface}
                                  </div>
                                )}
                              {report.base_depth_min &&
                                report.base_depth_min !== "*" && (
                                  <div>
                                    Base depth min: {report.base_depth_min}
                                  </div>
                                )}
                              {report.base_depth_max &&
                                report.base_depth_max !== "*" && (
                                  <div>
                                    Base depth max: {report.base_depth_max}
                                  </div>
                                )}
                              {report.snow_making &&
                                report.snow_making !== "*" && (
                                  <div>Snow making: {report.snow_making}</div>
                                )}
                              {!(
                                report.new_snow_min24 &&
                                report.new_snow_min24 !== "*"
                              ) &&
                                !(
                                  report.new_snow_max24 &&
                                  report.new_snow_max24 !== "*"
                                ) &&
                                !(
                                  report.primary_surface &&
                                  report.primary_surface !== "*"
                                ) &&
                                !(
                                  report.base_depth_min &&
                                  report.base_depth_min !== "*"
                                ) &&
                                !(
                                  report.base_depth_max &&
                                  report.base_depth_max !== "*"
                                ) &&
                                !(
                                  report.snow_making &&
                                  report.snow_making !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Historic
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>Seasonal Snowfall: {report.seasonal_snowfall}</div>
                    <div>Last Snowfall Date: {report.last_snowfall_date}</div>
                    <div>Last Snowfall Amount: {report.last_snowfall_amount}</div>
                    <div>Previous Snowfall Date: {report.previous_snowfall_date}</div>
                    <div>Previous Snowfall Amount: {report.previous_snowfall_amount}</div> */}
                              {report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*" && (
                                  <div>
                                    Seasonal Snowfall:{" "}
                                    {report.seasonal_snowfall} in.
                                  </div>
                                )}
                              {report.last_snowfall_date &&
                                report.last_snowfall_date !== "*" && (
                                  <div>
                                    Last Snowfall Date:{" "}
                                    {report.last_snowfall_date}
                                  </div>
                                )}
                              {report.last_snowfall_amount &&
                                report.last_snowfall_amount !== "*" && (
                                  <div>
                                    Last Snowfall Amount:{" "}
                                    {report.last_snowfall_amount} in.
                                  </div>
                                )}
                              {report.previous_snowfall_date &&
                                report.previous_snowfall_date !== "*" && (
                                  <div>
                                    Previous Snowfall Date:{" "}
                                    {report.previous_snowfall_date}
                                  </div>
                                )}
                              {report.previous_snowfall_amount &&
                                report.previous_snowfall_amount !== "*" && (
                                  <div>
                                    Previous Snowfall Amount:{" "}
                                    {report.previous_snowfall_amount} in.
                                  </div>
                                )}
                              {!(
                                report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*"
                              ) &&
                                !(
                                  report.last_snowfall_date &&
                                  report.last_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.last_snowfall_amount &&
                                  report.last_snowfall_amount !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_date &&
                                  report.previous_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_amount &&
                                  report.previous_snowfall_amount !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: 20, alignItems: "flex-start" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            Operation
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                              maxHeight: 200,
                              flexWrap: "wrap",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            {/* <div>Operation Hours: {report.operation_hours}</div>
                    <div>Operation status: {report.operation_status}</div>
                    <div>Resort Status: {report.resort_status}</div>
                    <div>Projected Operation: {report.projected_operations}</div>
                    <div>Snow Comments: {report.snow_comments24}</div>
                    <div>General Comments: {report.general_comments}</div>
                    <div>Night skiing: {report.night_skiing}</div>
                    <div>Lift Open: {report.num_lift_open}</div>
                    <div>Trails Open: {report.num_trails_open}</div>
                    <div>Miles Open: {report.num_miles_open}</div>
                    <div>Acres Open: {report.num_acres_open}</div>
                    <div>% Runs Open: {report.percent_runs_open}%</div>
                    <div>Crosscountry: {report.cross_country}</div>
                    <div>Snowboarding: {report.snow_boarding}</div>
                    <div>Total Lifts: {report.total_lifts}</div>
                    <div>Total Trails: {report.total_trails}</div> */}
                            {report.operation_hours &&
                              report.operation_hours !== "*" && (
                                <div>
                                  Operation Hours: {report.operation_hours}
                                </div>
                              )}
                            {report.operation_status &&
                              report.operation_status !== "*" && (
                                <div>
                                  Operation status: {report.operation_status}
                                </div>
                              )}
                            {report.resort_status &&
                              report.resort_status !== "*" && (
                                <div>Resort Status: {report.resort_status}</div>
                              )}
                            {report.projected_operations &&
                              report.projected_operations !== "*" && (
                                <div>
                                  Projected Operation:{" "}
                                  {report.projected_operations}
                                </div>
                              )}
                            {report.snow_comments24 &&
                              report.snow_comments24 !== "*" && (
                                <div>
                                  Snow Comments: {report.snow_comments24}
                                </div>
                              )}
                            {report.general_comments &&
                              report.general_comments !== "*" && (
                                <div>
                                  General Comments: {report.general_comments}
                                </div>
                              )}
                            {report.night_skiing &&
                              report.night_skiing !== "*" && (
                                <div>Night skiing: {report.night_skiing}</div>
                              )}
                            {report.num_lift_open &&
                              report.num_lift_open !== "*" && (
                                <div>Lift Open: {report.num_lift_open}</div>
                              )}
                            {report.num_trails_open &&
                              report.num_trails_open !== "*" && (
                                <div>Trails Open: {report.num_trails_open}</div>
                              )}
                            {report.num_miles_open &&
                              report.num_miles_open !== "*" && (
                                <div>Miles Open: {report.num_miles_open}</div>
                              )}
                            {report.num_acres_open &&
                              report.num_acres_open !== "*" && (
                                <div>Acres Open: {report.num_acres_open}</div>
                              )}
                            {report.percent_runs_open &&
                              report.percent_runs_open !== "*" && (
                                <div>
                                  % Runs Open: {report.percent_runs_open}%
                                </div>
                              )}
                            {report.cross_country &&
                              report.cross_country !== "*" && (
                                <div>Crosscountry: {report.cross_country}</div>
                              )}
                            {report.snow_boarding &&
                              report.snow_boarding !== "*" && (
                                <div>Snowboarding: {report.snow_boarding}</div>
                              )}
                            {report.total_lifts &&
                              report.total_lifts !== "*" && (
                                <div>Total Lifts: {report.total_lifts}</div>
                              )}
                            {report.total_trails &&
                              report.total_trails !== "*" && (
                                <div>Total Trails: {report.total_trails}</div>
                              )}
                            {!(
                              report.operation_hours &&
                              report.operation_hours !== "*"
                            ) &&
                              !(
                                report.operation_status &&
                                report.operation_status !== "*"
                              ) &&
                              !(
                                report.resort_status &&
                                report.resort_status !== "*"
                              ) &&
                              !(
                                report.projected_operations &&
                                report.projected_operations !== "*"
                              ) &&
                              !(
                                report.snow_comments24 &&
                                report.snow_comments24 !== "*"
                              ) &&
                              !(
                                report.general_comments &&
                                report.general_comments !== "*"
                              ) &&
                              !(
                                report.night_skiing &&
                                report.night_skiing !== "*"
                              ) &&
                              !(
                                report.num_lift_open &&
                                report.num_lift_open !== "*"
                              ) &&
                              !(
                                report.num_trails_open &&
                                report.num_trails_open !== "*"
                              ) &&
                              !(
                                report.num_miles_open &&
                                report.num_miles_open !== "*"
                              ) &&
                              !(
                                report.num_acres_open &&
                                report.num_acres_open !== "*"
                              ) &&
                              !(
                                report.percent_runs_open &&
                                report.percent_runs_open !== "*"
                              ) &&
                              !(
                                report.cross_country &&
                                report.cross_country !== "*"
                              ) &&
                              !(
                                report.snow_boarding &&
                                report.snow_boarding !== "*"
                              ) &&
                              !(
                                report.total_lifts && report.total_lifts !== "*"
                              ) &&
                              !(
                                report.total_trails &&
                                report.total_trails !== "*"
                              ) && <div>No data.</div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );

  if (dimensions[0] == 1 && dimensions[1] == 2)
    return (
      <div style={{ height: "100%" }}>
        {widget && (
          <WidgetTimeUpdate route={ROUTE} updateIntervalMinutes={60} />
        )}
        {skiReports.length && (
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "calc(100% - 60px)",
              padding: 10,
              overflowX: "hidden",
            }}
          >
            {skiReports.map((report, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: 10,
                    marginBottom: 20,
                    backgroundColor: theme.primary,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        const newOpened = [...opened];
                        newOpened[index] = !newOpened[index];
                        setOpened(newOpened);
                      }}
                    >
                      <h2 style={{ marginLeft: 10 }}>{report.ski_area_name}</h2>
                      {opened[index] ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                    {opened[index] && (
                      <div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Snow Details
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>New snow min: {report.new_snow_min24}</div>
                    <div>New snow max: {report.new_snow_max24}</div>
                    <div>Primary Surface: {report.primary_surface}</div>
                    <div>Base depth min: {report.base_depth_min}</div>
                    <div>Base depth max: {report.base_depth_max}</div>
                    <div>Snow making: {report.snow_making}</div> */}
                              {report.new_snow_min24 &&
                                report.new_snow_min24 !== "*" && (
                                  <div>
                                    New snow min: {report.new_snow_min24}
                                  </div>
                                )}
                              {report.new_snow_max24 &&
                                report.new_snow_max24 !== "*" && (
                                  <div>
                                    New snow max: {report.new_snow_max24}
                                  </div>
                                )}
                              {report.primary_surface &&
                                report.primary_surface !== "*" && (
                                  <div>
                                    Primary Surface: {report.primary_surface}
                                  </div>
                                )}
                              {report.base_depth_min &&
                                report.base_depth_min !== "*" && (
                                  <div>
                                    Base depth min: {report.base_depth_min}
                                  </div>
                                )}
                              {report.base_depth_max &&
                                report.base_depth_max !== "*" && (
                                  <div>
                                    Base depth max: {report.base_depth_max}
                                  </div>
                                )}
                              {report.snow_making &&
                                report.snow_making !== "*" && (
                                  <div>Snow making: {report.snow_making}</div>
                                )}
                              {!(
                                report.new_snow_min24 &&
                                report.new_snow_min24 !== "*"
                              ) &&
                                !(
                                  report.new_snow_max24 &&
                                  report.new_snow_max24 !== "*"
                                ) &&
                                !(
                                  report.primary_surface &&
                                  report.primary_surface !== "*"
                                ) &&
                                !(
                                  report.base_depth_min &&
                                  report.base_depth_min !== "*"
                                ) &&
                                !(
                                  report.base_depth_max &&
                                  report.base_depth_max !== "*"
                                ) &&
                                !(
                                  report.snow_making &&
                                  report.snow_making !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 20,
                              marginLeft: 20,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            >
                              Historic
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                                maxHeight: 200,
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <div>Seasonal Snowfall: {report.seasonal_snowfall}</div>
                    <div>Last Snowfall Date: {report.last_snowfall_date}</div>
                    <div>Last Snowfall Amount: {report.last_snowfall_amount}</div>
                    <div>Previous Snowfall Date: {report.previous_snowfall_date}</div>
                    <div>Previous Snowfall Amount: {report.previous_snowfall_amount}</div> */}
                              {report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*" && (
                                  <div>
                                    Seasonal Snowfall:{" "}
                                    {report.seasonal_snowfall} in.
                                  </div>
                                )}
                              {report.last_snowfall_date &&
                                report.last_snowfall_date !== "*" && (
                                  <div>
                                    Last Snowfall Date:{" "}
                                    {report.last_snowfall_date}
                                  </div>
                                )}
                              {report.last_snowfall_amount &&
                                report.last_snowfall_amount !== "*" && (
                                  <div>
                                    Last Snowfall Amount:{" "}
                                    {report.last_snowfall_amount} in.
                                  </div>
                                )}
                              {report.previous_snowfall_date &&
                                report.previous_snowfall_date !== "*" && (
                                  <div>
                                    Previous Snowfall Date:{" "}
                                    {report.previous_snowfall_date}
                                  </div>
                                )}
                              {report.previous_snowfall_amount &&
                                report.previous_snowfall_amount !== "*" && (
                                  <div>
                                    Previous Snowfall Amount:{" "}
                                    {report.previous_snowfall_amount} in.
                                  </div>
                                )}
                              {!(
                                report.seasonal_snowfall &&
                                report.seasonal_snowfall !== "*"
                              ) &&
                                !(
                                  report.last_snowfall_date &&
                                  report.last_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.last_snowfall_amount &&
                                  report.last_snowfall_amount !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_date &&
                                  report.previous_snowfall_date !== "*"
                                ) &&
                                !(
                                  report.previous_snowfall_amount &&
                                  report.previous_snowfall_amount !== "*"
                                ) && <div>No data.</div>}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: 20, alignItems: "flex-start" }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            Operation
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                              maxHeight: 200,
                              flexWrap: "wrap",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            {/* <div>Operation Hours: {report.operation_hours}</div>
                    <div>Operation status: {report.operation_status}</div>
                    <div>Resort Status: {report.resort_status}</div>
                    <div>Projected Operation: {report.projected_operations}</div>
                    <div>Snow Comments: {report.snow_comments24}</div>
                    <div>General Comments: {report.general_comments}</div>
                    <div>Night skiing: {report.night_skiing}</div>
                    <div>Lift Open: {report.num_lift_open}</div>
                    <div>Trails Open: {report.num_trails_open}</div>
                    <div>Miles Open: {report.num_miles_open}</div>
                    <div>Acres Open: {report.num_acres_open}</div>
                    <div>% Runs Open: {report.percent_runs_open}%</div>
                    <div>Crosscountry: {report.cross_country}</div>
                    <div>Snowboarding: {report.snow_boarding}</div>
                    <div>Total Lifts: {report.total_lifts}</div>
                    <div>Total Trails: {report.total_trails}</div> */}
                            {report.operation_hours &&
                              report.operation_hours !== "*" && (
                                <div>
                                  Operation Hours: {report.operation_hours}
                                </div>
                              )}
                            {report.operation_status &&
                              report.operation_status !== "*" && (
                                <div>
                                  Operation status: {report.operation_status}
                                </div>
                              )}
                            {report.resort_status &&
                              report.resort_status !== "*" && (
                                <div>Resort Status: {report.resort_status}</div>
                              )}
                            {report.projected_operations &&
                              report.projected_operations !== "*" && (
                                <div>
                                  Projected Operation:{" "}
                                  {report.projected_operations}
                                </div>
                              )}
                            {report.snow_comments24 &&
                              report.snow_comments24 !== "*" && (
                                <div>
                                  Snow Comments: {report.snow_comments24}
                                </div>
                              )}
                            {report.general_comments &&
                              report.general_comments !== "*" && (
                                <div>
                                  General Comments: {report.general_comments}
                                </div>
                              )}
                            {report.night_skiing &&
                              report.night_skiing !== "*" && (
                                <div>Night skiing: {report.night_skiing}</div>
                              )}
                            {report.num_lift_open &&
                              report.num_lift_open !== "*" && (
                                <div>Lift Open: {report.num_lift_open}</div>
                              )}
                            {report.num_trails_open &&
                              report.num_trails_open !== "*" && (
                                <div>Trails Open: {report.num_trails_open}</div>
                              )}
                            {report.num_miles_open &&
                              report.num_miles_open !== "*" && (
                                <div>Miles Open: {report.num_miles_open}</div>
                              )}
                            {report.num_acres_open &&
                              report.num_acres_open !== "*" && (
                                <div>Acres Open: {report.num_acres_open}</div>
                              )}
                            {report.percent_runs_open &&
                              report.percent_runs_open !== "*" && (
                                <div>
                                  % Runs Open: {report.percent_runs_open}%
                                </div>
                              )}
                            {report.cross_country &&
                              report.cross_country !== "*" && (
                                <div>Crosscountry: {report.cross_country}</div>
                              )}
                            {report.snow_boarding &&
                              report.snow_boarding !== "*" && (
                                <div>Snowboarding: {report.snow_boarding}</div>
                              )}
                            {report.total_lifts &&
                              report.total_lifts !== "*" && (
                                <div>Total Lifts: {report.total_lifts}</div>
                              )}
                            {report.total_trails &&
                              report.total_trails !== "*" && (
                                <div>Total Trails: {report.total_trails}</div>
                              )}
                            {!(
                              report.operation_hours &&
                              report.operation_hours !== "*"
                            ) &&
                              !(
                                report.operation_status &&
                                report.operation_status !== "*"
                              ) &&
                              !(
                                report.resort_status &&
                                report.resort_status !== "*"
                              ) &&
                              !(
                                report.projected_operations &&
                                report.projected_operations !== "*"
                              ) &&
                              !(
                                report.snow_comments24 &&
                                report.snow_comments24 !== "*"
                              ) &&
                              !(
                                report.general_comments &&
                                report.general_comments !== "*"
                              ) &&
                              !(
                                report.night_skiing &&
                                report.night_skiing !== "*"
                              ) &&
                              !(
                                report.num_lift_open &&
                                report.num_lift_open !== "*"
                              ) &&
                              !(
                                report.num_trails_open &&
                                report.num_trails_open !== "*"
                              ) &&
                              !(
                                report.num_miles_open &&
                                report.num_miles_open !== "*"
                              ) &&
                              !(
                                report.num_acres_open &&
                                report.num_acres_open !== "*"
                              ) &&
                              !(
                                report.percent_runs_open &&
                                report.percent_runs_open !== "*"
                              ) &&
                              !(
                                report.cross_country &&
                                report.cross_country !== "*"
                              ) &&
                              !(
                                report.snow_boarding &&
                                report.snow_boarding !== "*"
                              ) &&
                              !(
                                report.total_lifts && report.total_lifts !== "*"
                              ) &&
                              !(
                                report.total_trails &&
                                report.total_trails !== "*"
                              ) && <div>No data.</div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
};
