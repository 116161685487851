import { ColorContext } from "App";
import React, { useContext, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({ callback, value, height, width }) => {
  const [reveal, setReveal] = useState(false);
  const theme = useContext(ColorContext);
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 10,
        backgroundColor: theme.primary,
        color: theme.fontColor,
        width: width,
        height: height,
      }}
    >
      <input
        onChange={(e) => callback(e.target.value)}
        value={value}
        type={reveal ? "text" : "password"}
        style={{
          padding: 10,
          width: "100%",
        }}
      ></input>
      <div
        onClick={() => setReveal(!reveal)}
        style={{
          color: theme.fontColor,
          cursor: "pointer",
          marginTop: 2,
          marginRight: 15,
        }}
      >
        {reveal ? <FaEyeSlash size={25} /> : <FaEye size={25} />}
      </div>
    </div>
  );
};

export default PasswordInput;
