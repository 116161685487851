import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UpdatePassword } from "./UpdatePassword";
import { UserInfo } from "./UserInfo";
import Cookies from "js-cookie";

export default function AccountSettings() {
  return (
        <UserInfo />
  );
}
