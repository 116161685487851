import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import CustomIcon from "./CustomIcon";
import MapEvents from "./MapEvents";
import L, { DivIcon } from "leaflet";
import "./weather-tooltip.css";
import { temperatureColorScale } from "utils/colorUtils";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";

export default function MapStuff({
  data,
  center,
  zoom,
  fullScreen,
  setZoom,
  satelliteToggleStyle,
  preview,
  sidebarOpen,
}) {
  const [clusterKey, setClusterKey] = useState(0);

  useEffect(() => {
    setClusterKey((prevKey) => prevKey + 1);
  }, [data]);

  if (fullScreen)
    return (
      <FullScreenMap
        mapCenter={center}
        zoom={zoom}
        satelliteToggleStyle={satelliteToggleStyle}
      >
        <MapEvents setZoom={setZoom} />
        <MarkerClusterGroup
          maxClusterRadius={160}
          iconCreateFunction={(cluster) => {
            const markers = cluster.getAllChildMarkers();
            let totalTemp = 0;
            markers.forEach((marker) => {
              totalTemp += parseFloat(marker.options.title);
            });
            const avgTemp = totalTemp / markers.length;
            const numZones = cluster.getChildCount();
            return L.divIcon({
              iconSize: [0, 0],
              iconAnchor: [0, 0],
              className: "divIconEmpty",
              html: `<div
                class="divIconText"
                style="width: 120px; text-align: center; color: ${temperatureColorScale(
                  avgTemp
                )};text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;"
              >
                <p style="font-size: 24px; font-weight:bold; white-space:nowrap;">
                  ${avgTemp.toFixed(2)}°F
                </p>
                
                <p style="color:white; font-size: 18px; font-weight:bold; white-space:nowrap;">
                  Avg: ${numZones} zones
                </p>
          
              </div>`,
            });
          }}
        >
          {data.map((item, index) => {
            return (
              <Marker
                key={index}
                position={[
                  parseFloat(item.latitude),
                  parseFloat(item.longitude),
                ]}
                title={item.temperature}
                icon={new DivIcon({ className: "divIconEmpty" })}
              >
                <Tooltip
                  permanent
                  className="weather-tooltip"
                  direction="center"
                >
                  <CustomIcon
                    city_name={item.city_name}
                    temperature={item.temperature}
                    icon={item.icon}
                    icon_name={item.icon_name}
                  />
                </Tooltip>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </FullScreenMap>
    );
  else
    return (
      <WidgetMap
        sidebarOpen={sidebarOpen}
        mapCenter={center}
        zoom={zoom}
        satelliteToggleStyle={satelliteToggleStyle}
        preview={preview}
      >
        <MapEvents setZoom={setZoom} />
        <MarkerClusterGroup
          key={clusterKey}
          maxClusterRadius={160}
          iconCreateFunction={(cluster) => {
            const markers = cluster.getAllChildMarkers();
            let totalTemp = 0;
            markers.forEach((marker) => {
              totalTemp += parseFloat(marker.options.title);
            });
            const avgTemp = totalTemp / markers.length;
            const numZones = cluster.getChildCount();
            return L.divIcon({
              iconSize: [0, 0],
              iconAnchor: [0, 0],
              className: "divIconEmpty",
              html: `<div
                    class="divIconText"
                    style="width: 120px; text-align: center; color: ${temperatureColorScale(
                      avgTemp
                    )};text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;"
                  >
                    <p style="font-size: 24px; font-weight:bold; white-space:nowrap;">
                      ${avgTemp.toFixed(2)}°F
                    </p>
                    
                    <p style="color:white; font-size: 18px; font-weight:bold; white-space:nowrap;">
                      Avg: ${numZones} zones
                    </p>
              
                  </div>`,
            });
          }}
        >
          {data.map((item, index) => {
            return (
              <Marker
                key={index}
                position={[
                  parseFloat(item.latitude),
                  parseFloat(item.longitude),
                ]}
                title={item.temperature}
                icon={new DivIcon({ className: "divIconEmpty" })}
              >
                <Tooltip
                  permanent
                  className="weather-tooltip"
                  direction="center"
                >
                  <CustomIcon
                    city_name={item.city_name}
                    temperature={item.temperature}
                    icon={item.icon}
                    icon_name={item.icon_name}
                  />
                </Tooltip>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </WidgetMap>
    );
}
