import React, { useContext, useEffect } from "react";
import RadialAlertMap from "../RadialAlertMap";
import { ColorContext } from "App";
import { Slider } from "antd";
import { capitalizeWord, GetDeviceTypeString } from "utils/stringUtils";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import AlertIcons from "components/AlertIcons";

export function VerifyRadialAlert(dataObj) {
  if (!dataObj) return false;

  return !(
    dataObj?.headline === "" ||
    dataObj?.description === "" ||
    dataObj?.type === "" ||
    dataObj?.radius === "" ||
    dataObj?.severity === ""
  );
}

export default function CreateRadialAlert({ onUpdate, deviceList, dataObj }) {
  const theme = useContext(ColorContext);

  useEffect(() => {
    if (
      !dataObj?.headline ||
      !dataObj?.description ||
      !dataObj?.type ||
      !dataObj?.radius ||
      !dataObj?.severity
    )
      onUpdate({
        headline: "",
        description: "",
        type: "",
        radius: 250,
        severity: 100,
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        borderRadius: 10,
        margin: 10,
        background: theme.primary,
        display: "flex",
        flexDirection: "row",
        gap: 20,
      }}
    >
      <div style={{ width: 400, height: 400 }}>
        <RadialAlertMap
          arrayOfDevices={deviceList}
          selectedDevice={dataObj.selectedDevice}
          setSelectedDevice={(e) => {
            const device = deviceList.find((device) => {
              return device.node_id === e;
            });

            if (device)
              onUpdate({
                node_id: e,
                selectedDevice: device,
                coordinates: [device.lat, device.lon],
              });
          }}
          radius={dataObj.radius}
        />
      </div>
      <div>
        <div style={{ padding: 5 }}>
          Device Alias:{" "}
          <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              // find device by node_id
              const device = deviceList.find((device) => {
                return device.node_id.toString() === e.target.value;
              });
              if (device)
                onUpdate({
                  node_id: e.target.value,
                  selectedDevice: device,
                  coordinates: [device.lat, device.lon],
                });
            }}
            value={dataObj.node_id}
          >
            <option value="">-Select-</option>
            {deviceList.map((device) => {
              if (GetDeviceTypeString(device) === dataObj.deviceType)
                return <option value={device.node_id}>{device.alias}</option>;
            })}
          </select>
        </div>
        <div style={{ padding: 5 }}>
          Headline:
          <input
            type="text"
            name={"headline"}
            style={{
              padding: 5,
              borderRadius: 5,
              border: "1px solid grey",
              marginLeft: 10,
            }}
            onChange={(e) => {
              onUpdate({ headline: e.target.value });
            }}
            value={dataObj["headline"]}
          />
        </div>
        <div
          style={{
            padding: 5,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          Description:
          <textarea
            type="text"
            name={"description"}
            style={{
              padding: 5,
              borderRadius: 5,
              border: "1px solid grey",
              marginLeft: 10,
            }}
            onChange={(e) => {
              onUpdate({ description: e.target.value });
            }}
            value={dataObj["description"]}
          />
        </div>
        <div
          style={{
            padding: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          Type:{" "}
          <div style={{ display: "flex" }}>
            {" "}
            <div style={{ marginRight: 5, marginLeft: 10 }}>
              <AlertIcons type={dataObj.type} />
            </div>
            <select
              style={{
                background: theme.base,
                padding: 5,
              }}
              onChange={(e) => {
                onUpdate({ type: e.target.value });
              }}
              value={dataObj["type"]}
            >
              <option value="">-Select-</option>
              {eventTypes.map((type) => {
                return <option value={type}>{type}</option>;
              })}
            </select>
          </div>
        </div>
        <div style={{ padding: 5 }}>
          Radius:
          <input
            type="number"
            name={"radius"}
            style={{
              padding: 5,
              borderRadius: 5,
              border: "1px solid grey",
              marginLeft: 10,
              width: 75,
            }}
            onChange={(e) => {
              onUpdate({ radius: e.target.value });
            }}
            value={dataObj["radius"]}
          />{" "}
          ft
        </div>
        <div style={{ padding: 5 }}>
          <div
            style={{
              width: "max-content",
              padding: 5,
              borderRadius: 5,
              background: alertColorScale(dataObj["severity"]),
            }}
          >
            Severity: {getSeverityLevel(dataObj["severity"])}
          </div>
          <Slider
            style={{ width: "100%" }}
            name="severity"
            min={0}
            max={100}
            step={1}
            value={100 - dataObj["severity"]}
            onChange={(e) => {
              onUpdate({ severity: 100 - e });
            }}
          />
        </div>
      </div>
    </div>
  );
}

const eventTypes = [
  "911 Telephone Outage Emergency",
  "Administrative Message",
  "Air Quality Alert",
  "Air Stagnation Advisory",
  "Arroyo And Small Stream Flood Advisory",
  "Ashfall Advisory",
  "Ashfall Warning",
  "Avalanche Advisory",
  "Avalanche Warning",
  "Avalanche Watch",
  "Beach Hazards Statement",
  "Blizzard Warning",
  "Blizzard Watch",
  "Blowing Dust Advisory",
  "Blowing Dust Warning",
  "Brisk Wind Advisory",
  "Child Abduction Emergency",
  "Civil Danger Warning",
  "Civil Emergency Message",
  "Coastal Flood Advisory",
  "Coastal Flood Statement",
  "Coastal Flood Warning",
  "Coastal Flood Watch",
  "Dense Fog Advisory",
  "Dense Smoke Advisory",
  "Dust Advisory",
  "Dust Storm Warning",
  "Earthquake Warning",
  "Evacuation - Immediate",
  "Excessive Heat Warning",
  "Excessive Heat Watch",
  "Extreme Cold Warning",
  "Extreme Cold Watch",
  "Extreme Fire Danger",
  "Extreme Wind Warning",
  "Fire Warning",
  "Fire Weather Watch",
  "Flash Flood Statement",
  "Flash Flood Warning",
  "Flash Flood Watch",
  "Flood Advisory",
  "Flood Statement",
  "Flood Warning",
  "Flood Watch",
  "Freeze Warning",
  "Freeze Watch",
  "Freezing Fog Advisory",
  "Freezing Rain Advisory",
  "Freezing Spray Advisory",
  "Frost Advisory",
  "Gale Warning",
  "Gale Watch",
  "Hard Freeze Warning",
  "Hard Freeze Watch",
  "Hazardous Materials Warning",
  "Hazardous Seas Warning",
  "Hazardous Seas Watch",
  "Hazardous Weather Outlook",
  "Heat Advisory",
  "Heavy Freezing Spray Warning",
  "Heavy Freezing Spray Watch",
  "High Surf Advisory",
  "High Surf Warning",
  "High Wind Warning",
  "High Wind Watch",
  "Hurricane Force Wind Warning",
  "Hurricane Force Wind Watch",
  "Hurricane Local Statement",
  "Hurricane Warning",
  "Hurricane Watch",
  "Hydrologic Advisory",
  "Hydrologic Outlook",
  "Ice Storm Warning",
  "Lake Effect Snow Advisory",
  "Lake Effect Snow Warning",
  "Lake Effect Snow Watch",
  "Lake Wind Advisory",
  "Lakeshore Flood Advisory",
  "Lakeshore Flood Statement",
  "Lakeshore Flood Warning",
  "Lakeshore Flood Watch",
  "Law Enforcement Warning",
  "Local Area Emergency",
  "Low Water Advisory",
  "Marine Weather Statement",
  "Nuclear Power Plant Warning",
  "Radiological Hazard Warning",
  "Red Flag Warning",
  "Rip Current Statement",
  "Severe Thunderstorm Warning",
  "Severe Thunderstorm Watch",
  "Severe Weather Statement",
  "Shelter In Place Warning",
  "Short Term Forecast",
  "Small Craft Advisory",
  "Small Craft Advisory For Hazardous Seas",
  "Small Craft Advisory For Rough Bar",
  "Small Craft Advisory For Winds",
  "Small Stream Flood Advisory",
  "Snow Squall Warning",
  "Special Marine Warning",
  "Special Weather Statement",
  "Storm Surge Warning",
  "Storm Surge Watch",
  "Storm Warning",
  "Storm Watch",
  "Test",
  "Tornado Warning",
  "Tornado Watch",
  "Tropical Depression Local Statement",
  "Tropical Storm Local Statement",
  "Tropical Storm Warning",
  "Tropical Storm Watch",
  "Tsunami Advisory",
  "Tsunami Warning",
  "Tsunami Watch",
  "Typhoon Local Statement",
  "Typhoon Warning",
  "Typhoon Watch",
  "Urban And Small Stream Flood Advisory",
  "Volcano Warning",
  "Wind Advisory",
  "Wind Chill Advisory",
  "Wind Chill Warning",
  "Wind Chill Watch",
  "Winter Storm Warning",
  "Winter Storm Watch",
  "Winter Weather Advisory",
];
