import React, { useContext } from "react";
import MapForecast from "components/Maps/MapForecast/MapForecast";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { SetUniqueWidgetRefreshContext, UniqueWidgetRefreshContext } from "App";

import CurrentConditions1x1 from "assets/WidgetPNGS/Weather/Maps/CurrentConditions1x1.png";

export default function MapForecastWidget({
  widgetRefresh,
  setWidgetRefresh,
  dimensions,
  preview = false,
  sidebarOpen,
}) {
  return (
    <div style={{ height: "100%" }}>
     
      <MapForecast
        sidebarOpen={sidebarOpen}
        // fullscreen
        widgetRefresh={widgetRefresh}
        setWidgetRefresh={setWidgetRefresh}
        dimensions={dimensions}
        preview={preview}
      />
    </div>
  );
}
