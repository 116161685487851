import React from "react";

function ContentRow({ items, heightOverride, styleOverride }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        marginRight: 30,
        height: heightOverride ? heightOverride : 71,
        fontWeight: "bold",
        fontSize: 19,
        borderBottom: "solid grey 2px",
        ...styleOverride,
      }}
    >
      {items.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              width: "25%",
              height: 71,
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
}

export default ContentRow;
