import * as api from "apis/FusionAPI";
import Cookies from "js-cookie";

const data = {}; //store data for each unique key
const lastUpdated = {};

//LOCATION INQUIRY
let locationInquiry = ""; //store the current location inquiry state
let locationInquiryErrorCallback; //store the error callback for location inquiry
let eventSource; //store the event source for location inquiry

//STATUS CODES
const STATUS_READY = "ready";
const STATUS_FETCHING = "fetching";
const STATUS_ERROR = "error";
const STATUS_SUCCESS = "success";

export const useAPIData = ({
  successCallback = () => {}, //callback for successful request
  errorCallback = () => {}, //callback for failed request
  fetchingCallback = () => {}, //callback while fetching
} = {}) => {
  //helper function to update the data object for a given unique key
  const updateEntry = (uniqueKey, updateInfo) => {
    if (data[uniqueKey] === undefined) {
      data[uniqueKey] = {};
    }
    //spread the current data and update with new info
    data[uniqueKey] = {
      ...data[uniqueKey],
      ...updateInfo,
    };
  };

  const updateLast = (uniqueKey) => {
    // if (lastUpdated[uniqueKey] === undefined) {
    //   lastUpdated[uniqueKey] = {};
    // }
    lastUpdated[uniqueKey] = new Date().getTime();
  };

  //function to make a get request to the server for a given route
  const get = (route, params = {}) => {
    //assemble the unique key for this request
    const location = `${Cookies.get("city")}-${Cookies.get("state")}`;
    const uniqueKey = `${location}-${route}`;

    return new Promise(async (resolve, reject) => {
      if (locationInquiry !== "clear") {
        // we are currently checking if data is ready for this location

        //Every second, check if the location inquiry is ready
        const CheckAgain = setInterval(() => {
          // if the location inquiry is ready, stop checking
          if (locationInquiry === "clear") {
            clearInterval(CheckAgain);
          }
          // if the location inquiry failed, stop checking and reject the promise
          if (locationInquiry === "failed") {
            clearInterval(CheckAgain);
            errorCallback();
          }
        }, 1000);

        //After 10 minutes, if the location inquiry is still not ready, reject the promise
        setTimeout(() => {
          if (locationInquiry !== "clear" && locationInquiry !== "failed") {
            locationInquiry = "failed";
            clearInterval(CheckAgain);
            errorCallback();
            if (locationInquiryErrorCallback)
              locationInquiryErrorCallback("Request timed out.");
            if (eventSource) eventSource.close();
            eventSource = null;
          }
        }, 600 * 1000);
      } else {
        //if the data for this unique key is not already fetched
        if (data[uniqueKey] === undefined || data[uniqueKey].state === "") {
          updateEntry(uniqueKey, { state: STATUS_FETCHING });
          updateLast(uniqueKey);
          try {
            const response = await api[route](params);
            const responseData = response.data;

            updateEntry(uniqueKey, {
              state: STATUS_SUCCESS,
              data: responseData,
            });
            successCallback();
            resolve({ ...data[uniqueKey] });
          } catch (error) {
            updateEntry(uniqueKey, { state: STATUS_ERROR, error: error });
            errorCallback();
            reject(error);
          }
        }
        //if the data for this unique key is already being fetched by another instance
        else if (data[uniqueKey].state === STATUS_FETCHING) {
          //tell the user that the data is still being fetched
          fetchingCallback();
          //Every second, check if the data is ready
          const CheckAgain = setInterval(() => {
            if (data[uniqueKey].state !== STATUS_FETCHING) {
              clearInterval(CheckAgain);
              resolve({ ...data[uniqueKey] });
            }
          }, 1000);

          //After 30 seconds, if the data is still not ready, reject the promise
          setTimeout(() => {
            if (data[uniqueKey].state === STATUS_FETCHING) {
              clearInterval(CheckAgain);

              errorCallback();
            }
          }, 30 * 1000);
        } else if (data[uniqueKey].state === STATUS_ERROR) {
          errorCallback();
        } else if (data[uniqueKey].state === STATUS_SUCCESS) {
          fetchingCallback();
          setTimeout(() => {
            successCallback();
            resolve({ ...data[uniqueKey] });
          }, 500);
        }
      }
    });
  };

  const isLocationReady = ({
    location,
    fetchCallback,
    errorCallback,
    successCallback,
  }) => {
    try {
      locationInquiryErrorCallback = errorCallback;
      locationInquiry = `${location.city}-${location.state_long}`;

      //check cache first
      const uniqueKey = `${location.city}-${location.state_long}-exists`;
      if (data[uniqueKey]) {
        Cookies.set("city", location.city);
        Cookies.set("state", location.state_long);
        Cookies.set("latitude", location.latitude);
        Cookies.set("longitude", location.longitude);

        successCallback("Data is already cached for location");
        locationInquiry = "clear";
        return;
      } else {
        // data[uniqueKey] = {
        //   state: STATUS_FETCHING,
        // };
      }

      eventSource = api.connectToIsDataReadyForLocation(location);

      eventSource.onopen = (e) => {};
      eventSource.onerror = (e) => {
        locationInquiry = "failed";
        errorCallback("Server error");
        eventSource.close();
      };
      eventSource.onmessage = (e) => {
        let data = JSON.parse(e.data);
        const { message, status } = data;
        if (status === STATUS_READY) {
          Cookies.set("city", location.city);
          Cookies.set("state", location.state_long);
          Cookies.set("latitude", location.latitude);
          Cookies.set("longitude", location.longitude);

          updateEntry(uniqueKey, {
            state: STATUS_SUCCESS,
          });

          successCallback(message);
          locationInquiry = "clear";
          eventSource.close();
        } else if (status === STATUS_FETCHING) {
          fetchCallback(message);
        } else if (status === STATUS_ERROR) {
          errorCallback(message);
          eventSource.close();
        }
      };
      eventSource.onclose = (e) => {
        eventSource = null;
      };
      return {
        eventSource,
      };
    } catch (error) {
      errorCallback(error);
    }
  };

  const cancelLocationInquiry = () => {
    locationInquiry = "failed";
    if (eventSource) eventSource.close();
  };

  const forceGet = (route, params = {}) => {
    //

    const location = `${Cookies.get("city")}-${Cookies.get("state")}`;
    const uniqueKey = `${location}-${route}`;

    if (
      lastUpdated[uniqueKey] &&
      new Date().getTime() - lastUpdated[uniqueKey] < 1000 * 60 * 3 &&
      data[uniqueKey].state === STATUS_SUCCESS
    ) {
      return new Promise((resolve, reject) => {
        resolve({ ...data[uniqueKey] });
      });
    } else {
    }
    // return
    updateEntry(uniqueKey, null);
    // return get(route, params);
    return new Promise(async (resolve, reject) => {
      try {
        if (data[uniqueKey].state !== STATUS_FETCHING) {
          updateLast(uniqueKey);
          updateEntry(uniqueKey, {
            state: STATUS_FETCHING,
            data: null,
          });

          const response = await api[route](params);
          const responseData = response.data;
          updateEntry(uniqueKey, {
            state: STATUS_SUCCESS,
            data: responseData,
          });

          successCallback();
          resolve({ ...data[uniqueKey] });
        } else {
          //tell the user that the data is still being fetched
          fetchingCallback();
          //Every second, check if the data is ready
          const CheckAgain = setInterval(() => {
            if (data[uniqueKey].state !== STATUS_FETCHING) {
              clearInterval(CheckAgain);

              resolve({ ...data[uniqueKey] });
            }
          }, 1000);

          //After 30 seconds, if the data is still not ready, reject the promise
          setTimeout(() => {
            if (data[uniqueKey].state === STATUS_FETCHING) {
              clearInterval(CheckAgain);

              errorCallback();
            }
          }, 30 * 1000);
        }
      } catch (error) {
        updateEntry(uniqueKey, { state: STATUS_ERROR, error: error });
        errorCallback();
        reject(error);
      }
    });
  };

  return {
    get, //attempt a request to the server for the given route
    isLocationReady, //check if data is ready for a location
    cancelLocationInquiry, //cancel the location inquiry
    forceGet, //force a request to the server for the given
  };
};
