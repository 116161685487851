import { ColorContext } from "App";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import React, { useContext, useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const AuditLogDisplay = ({
  list,
  selectedIndex,
  setSelectedIndex,
  sortKey,
  setSortKey,
  sortOrder,
  setSortOrder,
}) => {
  const changeSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 1 ? -1 : 1);
    } else {
      setSortKey(key);
      setSortOrder(1);
    }
  };

  const titles = ["Source", "User", "Device", "Activity"];

  const theme = useContext(ColorContext);
  if (!list) return <WidgetLoadingAnimation/>;
  return (
    <div style={{
      height: "100%",
    }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: titles.map(() => "1fr").join(" "),
          fontSize: 18,
          textAlign: "center",
          marginBottom: 5,
        }}
      >
        {titles.map((title, index) => (
          <CategoryTitle
            key={index}
            title={title}
            changeSort={changeSort}
            sortKey={sortKey}
            sortOrder={sortOrder}
          />
        ))}
      </div>
      <div
        style={{
          // maxHeight: 850,
          // overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {list &&
          list.map((item, index) => (
            <AuditLogItem
              key={index}
              item={item}
              index={index}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              columns={titles}
            />
          ))}
          {list.length === 0 && <WidgetLoadingAnimation/>}
      </div>
    </div>
  );
};

const CategoryTitle = ({ title, changeSort, sortKey, sortOrder }) => {
  const theme = useContext(ColorContext);
  const fixTitle = (title) => {
    if (title === "Activity") return "timestamp";
    if (title === "Id") return "log_id";
    if (title === "Source") return "action_type";
    if (title === "User") return "name";
    if (title === "Device") return "log_id";
    else return title.toLowerCase();
  };
  return (
    <div
      onClick={() => {
        changeSort(fixTitle(title));
      }}
      style={{
        cursor: "pointer",
      }}
    >
      {title}{" "}
      {sortKey === fixTitle(title) &&
        (sortOrder === 1 ? <FaSortDown /> : <FaSortUp />)}
    </div>
  );
};

const AuditLogItem = ({
  item,
  index,
  selectedIndex,
  setSelectedIndex,
  columns,
}) => {
  const theme = useContext(ColorContext);
  const titleToFields = (title) => {
    if (title === "Activity") return new Date(item.timestamp).toLocaleString();
    if (title === "Id") return item.log_id;
    if (title === "Source") return item.action_type;
    if (title === "User") return item.user_name;
    if (title === "Device")
      return item.additional_info.device || " ";
    else return item[title.toLowerCase()];
  };
  return (
    <div
      style={{
        padding: "20px",
        background:
          selectedIndex === index
            ? theme.primaryHighlight
            : index % 2 === 0
            ? theme.base
            : "#2c373b",
        borderTop: "1px solid #494c66",
        textAlign: "center",
      }}
      onClick={() => {
        if (selectedIndex === index) setSelectedIndex(-1);
        else setSelectedIndex(index);
      }}
    >
      <div
        key={item.id}
        style={{
          display: "grid",
          gridTemplateColumns: columns.map(() => "1fr").join(" "),
        }}
      >
        {columns.map((title, index) => (
          <div key={index}>{titleToFields(title)}</div>
        ))}
      </div>

      {selectedIndex === index && (
        <div>
          <div
            style={{
              textAlign: "left",
              padding: 10,
              marginTop: 10,
              borderTop: "1px solid #444",
            }}
          >
            {item.description}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditLogDisplay;
