import React, { useContext } from "react";
import * as d3 from "d3";

// Component that returns which icon to use depending on the type of alert
import IconSelector from "./AlertIcons";
import { ColorContext } from "App";

// Main function
export default function RenderAlerts({ arrayOfData, setSelectedAlert }) {
  function convertDate(dateStr) {
    const parts = dateStr.split(/[\s/:]/);
    const formattedDateStr = `${parts[2]}-${parts[0]}-${parts[1]}T${parts[3]}:${parts[4]}:00.000Z`;
    return formattedDateStr;
  }

  const colorScale = d3
    .scaleLinear()
    .domain([0, 66, 100]) // input
    .range(["#CD2C2E", "#EE8918", "#34A100"]);

    
  const theme = useContext(ColorContext);

  if (!arrayOfData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        Loading...
      </div>
    );
  }

  if (arrayOfData.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        No alerts to show, you're safe!
      </div>
    );
  }

  return (
    <div style={{ maxHeight: "calc(90% - 3em)", overflowY: "auto" }}>
      {arrayOfData.map((event, index) => {
        return (
          <div
            key={index}
            style={{
              borderRadius: "5px",
              marginBottom: "5px",
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => setSelectedAlert(event)}
          >
            <p
              style={{
                textAlign: "right",
                marginBottom: "5px",
                fontStyle: "italic",
              }}
            >
              from {event.sender},{" at "}
              {new Date(convertDate(event.startTime)).toLocaleString([], {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  borderRadius: "5px",
                  backgroundColor: colorScale(event.severity),
                  marginRight: "6px",
                  padding: "5px",
                  height: "45px",
                  width: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {<IconSelector type={event.type} />}
              </div>
              <p
                style={{
                  color: theme.fontColor,
                  fontSize: "18px",
                  borderRadius: "5px",
                  overflow: "hidden",
                  backgroundColor: colorScale(event.severity),
                  marginRight: "6px",
                  padding: "5px",
                  flexShrink: 1,
                  width: "100%",
                }}
              >
                {event.headline}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
