import React, { useContext, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import ForecastSpread from "./ForecastSpread";
import { ColorContext } from "App";

export default function DropDownSpread({
  style,
  title,
  data,
  content,
  dataIcons,
}) {
  const [opened, setOpened] = useState(false);

  const theme = useContext(ColorContext);
  return (
    <button
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.base,
        width: "100%",
        marginTop: 15,
        padding: 15,
        backgroundColor: "#436c98",
        borderRadius: 5,
        ...style,
      }}
      onClick={() => setOpened(!opened)}
    >
      {/* Closed */}
      {!opened && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            textAlign: "left",
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              width: "100%",
              marginRight: 10,
            }}
          >
            {title}
          </div>
          <FaChevronDown />
        </div>
      )}

      {/* Opened */}
      {opened && !content && (
        <ForecastSpread
          style={{ width: "100%" }}
          header={title}
          data={data}
          dataIcons={dataIcons}
        />
      )}

      {opened && content && <div style={{ width: "100%" }}>{content}</div>}
    </button>
  );
}
